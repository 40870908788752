import 'assets/css/StyleFonte.css';
import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    SimpleGrid,
    Box,
    Wrap,
    Image,
    Checkbox,
    Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';
import { inserirInformacoesUaAndSafra, verificarExisteNotificacoes } from '../../../util/ValidarAutenticacao';
import Loader from 'components/loading/Loader';
import { UtilContext } from 'contexts/UtilContext';
import { LIST_UFS } from 'model/MockData';
import { BiSolidEditAlt } from "react-icons/bi";

const CHECK01 = `${ActionTypes.LINK_GOOGLE_STORAGE}/check_01.png`;
const CHECK02 = `${ActionTypes.LINK_GOOGLE_STORAGE}/check_02.png`;

export default function EscolherUa() {

    const { isAtualisarNotificacao } = useContext(UtilContext);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listaUa, setListaUa] = useState([]);
    const [listaUaCompleta, setListaUaCompleta] = useState([]);
    const [idSafraAtivaNoSistema, setIdSafraAtivaNoSistema] = useState(0);
    const [isSomenteAtivas, setIsSomenteAtivas] = useState(false);

    const textColor = useColorModeValue("navy.700", "white");
    const gradientColorBlack = 'linear-gradient(gray.500, gray.600, gray.900)';
    const gradientSafra = 'linear-gradient(gray.200, gray.50, gray.200)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const cardShadow = ('0px 3px 10px 1px rgba(0, 0, 0, 0.8)');

    useEffect(() => {
        async function loaderScreen() {
            await carregaScreen();
        }
        loaderScreen();
    }, [])

    const carregaScreen = async () => {
        try {
            const idSafra = localStorage.getItem('@HE-idSafra');
            setIdSafraAtivaNoSistema(idSafra);
            setLoading(true);

            const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
            const url = `api/usuario/retornaUaAndSafrasAssociadas?idUsuario=${idUsuarioLogado}`;
            const response = await Api.getRequest(url);

            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                setListaUaCompleta(res.listaUaItem);
                filtrarSafrasAtivas(isSomenteAtivas, res.listaUaItem);

                const existeNotificacoes = await verificarExisteNotificacoes();
                if (existeNotificacoes) {
                    isAtualisarNotificacao(true);
                } else {
                    isAtualisarNotificacao(false);
                }
                setTelaCarregada(true);
            } else {
                setTelaCarregada(true)
            }
        } catch (error) {
            setLoading(false);
            setTelaCarregada(true);
            console.log('Erro na classe EscolherUa metodo carregaScreen', error);
        }
    }

    const changeSafrasAtivas = (e) => {
        const val = !isSomenteAtivas;
        setIsSomenteAtivas(val);

        filtrarSafrasAtivas(val, listaUaCompleta);
    }

    const filtrarSafrasAtivas = (isAtiva = false, array) => {
        if (array.length > 0) {
            if (isAtiva) {
                const arrayFiltrado = array.map(item => ({
                    idUa: item.idUa,
                    idUsuario: item.idUsuario,
                    nomeUsuarioProdutor: item.nomeUsuarioProdutor,
                    idUsuarioAa: item.idUsuarioAa,
                    idUsuarioTabelaUa: item.idUsuarioTabelaUa,
                    nomeFazenda: item.nomeFazenda,
                    idUf: item.idUf,
                    nomeUf: item.nomeUf,
                    nomeMunicipio: item.nomeMunicipio,
                    listaSafraItem: item.listaSafraItem.filter(safra => safra.sangriaAtiva === true)
                })).filter(item => item.listaSafraItem.length > 0);

                setListaUa(arrayFiltrado);
            } else {
                setListaUa(array);
            }
        }
    }

    const trocarDeUaAndAtualizarTodoAppComNovosDadosDaUa = async (ua, safra) => {
        try {
            const uf = LIST_UFS.filter(x => x.value == ua.idUf);
            const nomeUfP = uf[0].length > 0 ? uf[0].label : '';

            const json = {
                //rowNum: 33,
                idUsuarioAa: ua.idUsuarioAa,
                idUsuario: ua.idUsuario,
                idUa: ua.idUa,
                idSafra: safra.idSafra,
                nomeSafra: safra.nomeSafra,
                descricao: ua.nomeFazenda,
                nomeFazenda: ua.nomeFazenda,
                idUf: ua.idUf,
                nomeUf: nomeUfP,
                nomeMunicipio: ua.nomeMunicipio,
                dtInicio: safra.dtInicio,
                dtFim: safra.dtFim,
                dtInicioSangria: safra.dtInicioSangria,
                vigente: safra.vigente,
                sangriaAtiva: safra.sangriaAtiva,
                idUsuarioTabelaUa: ua.idUsuarioTabelaUa,
                qtdPessoaCadastrada: safra.qtdPessoaCadastrada,
                qtdPessoaDesativadas: safra.qtdPessoaDesativadas
            }

            await inserirInformacoesUaAndSafra(json);
            await carregaScreen();
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe EscolherUa metodo trocarDeUaAndAtualizarTodoAppComNovosDadosDaUa', error);
        }
    }

    const editarSafra = (ua, safra) => {
        const jsonParam = {
            idUa: ua.idUa,
            nomeFazenda: ua.nomeFazenda,
            idSafra: safra.idSafra,
            idUsuarioProdutor: ua.idUsuarioTabelaUa,
            nomeProdutor: ua.nomeUsuarioProdutor,
            insertOuUpdate: ActionTypes.OPERACAO_UPDATE,
        }
        localStorage.setItem('@HE-propriedadeParaEdicao', JSON.stringify(jsonParam));
        history.push("/admin/cadastrar-safra");
    }

    function renderCheckbox() {
        return (
            <Box px={'20px'} mb={'10px'}>
                <Checkbox
                    size='lg'
                    colorScheme='green'
                    isChecked={isSomenteAtivas}
                    onChange={changeSafrasAtivas}
                    borderColor={'green'}
                >
                    <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Exibir somente safras vigentes</Text>
                </Checkbox>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    function renderSafra() {
        return (
            <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap='20px' mb='20px' mt={'10px'} px={'10px'}>
                {listaUa.map((rr, yy) => {
                    return (
                        <Box key={yy}>
                            <Box px={'10px'} mb={'5px'} pb={'10px'} boxShadow={cardShadow} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.700'}>
                                <Flex px={'10px'} mb={'-5px'} mt={'10px'} justify='start' align='end'>
                                    <Text color={'gray.500'} fontSize='15px' as='b' lineHeight={'100%'}>{'Propriedade'}</Text>
                                    <Text color={textColor} fontSize='18px' as='b' lineHeight={'100%'} ml={'10px'}>{rr.nomeFazenda}</Text>
                                </Flex>
                                {rr.listaSafraItem.length > 0 ?
                                    <Box>
                                        {rr.listaSafraItem.map((item, index) => {
                                            let dtIni = item.dtInicio != null ? moment(item.dtInicio).format('DD/MM/YYYY') : '';
                                            let dtFim = item.dtFim != null ? moment(item.dtFim).format('DD/MM/YYYY') : '';
                                            let dtInicioSangria = item.dtInicioSangria != null ? moment(item.dtInicioSangria).format('DD/MM/YYYY') : '';
                                            let dtFimSangria = item.dtFimSangria != null ? moment(item.dtFimSangria).format('DD/MM/YYYY') : '---';

                                            let labelStatus = item.vigente == true ? 'VIGENTE' : 'FINALIZADA';
                                            let bgGradientP = gradientSafra;
                                            let colorTextb = textColor;
                                            let colorStatus = item.vigente == true ? 'green.500' : 'red.500';

                                            const qtdPessoaSemProgramacao = Number(item.qtdPessoaSemProgramacao);
                                            const qtdPessoaIncluida = Number(item.qtdPessoaIncluida);

                                            if (item.idSafra == idSafraAtivaNoSistema) {
                                                bgGradientP = gradientColorBlack;
                                                colorTextb = 'rgba(255,255,255,1)';
                                                colorStatus = item.vigente == true ? 'lime' : 'red.500';
                                            }
                                            return (
                                                <Box key={index} position={'relative'}>
                                                    <Box px={'5px'} py={'5px'} mt={'15px'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'gray'} bgGradient={bgGradientP}>
                                                        <Flex justify='start' align='start'>
                                                            <Text color={colorTextb} fontSize='14px' as='b' lineHeight={'100%'}>{`${item.nomeSafra}`}</Text>
                                                        </Flex>
                                                        <Flex px='2px' justify='flex-start' align='center'>
                                                            <Text w={'85px'} color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'}>Início Safra</Text>
                                                            <Text color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{dtIni}</Text>
                                                            <Text color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'} ml={'15px'}>Fim Safra</Text>
                                                            <Text color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{dtFim}</Text>
                                                        </Flex>
                                                        <Flex px='2px' justify='flex-start' align='center'>
                                                            <Text w={'85px'} color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'}>Início Sangria</Text>
                                                            <Text color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{dtInicioSangria}</Text>
                                                            <Text color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'} ml={'15px'}>Fim Sangria</Text>
                                                            <Text color={colorTextb} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{dtFimSangria}</Text>
                                                        </Flex>
                                                        <Flex align={'end'} justify={'space-between'}>
                                                            <Text color={colorStatus} fontSize='16px' fontWeight={'light'} lineHeight={'100%'} className='bowlby-one-regular'>{labelStatus}</Text>
                                                            <Flex h={'100%'} flexDirection={'column'} justify='end' align='center'>
                                                                <Flex justify='start' align='center'>
                                                                    <Text color={colorTextb} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'}>{'Seringueiros:'}</Text>
                                                                    <Text color={colorTextb} fontSize='12px' fontWeight={'bold'} ml={'5px'} lineHeight={'100%'}>{item.qtdPessoaCadastrada}</Text>
                                                                </Flex>
                                                                <Flex justify='center' align='center'>
                                                                    <Text color={colorTextb} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'}>{'Desativados:'}</Text>
                                                                    <Text color={'red'} fontSize='12px' fontWeight={'bold'} ml={'5px'} lineHeight={'100%'}>{item.qtdPessoaDesativadas}</Text>
                                                                </Flex>
                                                            </Flex>
                                                            <Flex h={'100%'} flexDirection={'column'} justify='end' align='center'>
                                                                <Flex justify='start' align='center'>
                                                                    <Text color={colorTextb} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'}>{'S. Progra:'}</Text>
                                                                    <Text color={colorTextb} fontSize='12px' fontWeight={'bold'} ml={'5px'} lineHeight={'100%'}>{item.qtdPessoaSemProgramacao}</Text>
                                                                </Flex>
                                                                <Flex justify='center' align='center'>
                                                                    <Text color={colorTextb} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'}>{'incluidos:'}</Text>
                                                                    <Text color={colorTextb} fontSize='12px' fontWeight={'bold'} ml={'5px'} lineHeight={'100%'}>{item.qtdPessoaIncluida}</Text>
                                                                </Flex>
                                                            </Flex>
                                                            <Box>
                                                                {item.idSafra == idSafraAtivaNoSistema ?
                                                                    <Image h='35px' w='40px' ml={'0'} src={CHECK01} borderRadius='10px' me='0px' mr={'3'} />
                                                                    :
                                                                    <Box h={'35px'}>
                                                                        <Button
                                                                            bg="transparent"
                                                                            onClick={() => trocarDeUaAndAtualizarTodoAppComNovosDadosDaUa(rr, item)}
                                                                            borderColor={'blackAlpha.900'}>
                                                                            <Image h='30px' w='35px' ml={'0'} src={CHECK02} borderRadius='10px' me='0px' />
                                                                        </Button>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                    <Box position={'absolute'} top={'5px'} right={'24px'}>
                                                        <Flex 
                                                            justify='center' 
                                                            align='center' 
                                                            _hover={{ bg: 'gray.300' }}
                                                            p={'2px'}
                                                            borderRadius={'10px'}
                                                            onClick={() => { editarSafra(rr, item) }} 
                                                            cursor={'pointer'}>
                                                            <Icon as={BiSolidEditAlt} width='30px' height='30px' color={'black'} />
                                                        </Flex>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    :
                                    <Box>
                                        <Box px={'5px'} py={'5px'} mt={'15px'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'gray'} bgGradient={gradientSafra}>
                                            <Flex flexDirection={'column'} justify='center' align='start'>
                                                <Text color={'red'} fontSize='14px' as='b' lineHeight={'100%'}>{'Não existe safra para essa propriedade'}</Text>
                                            </Flex>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    )
                })}
            </SimpleGrid>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "55px" }}>
            <Card direction='column' w='100%' px='0px' py='0px' bg={'transparent'}>
                <Text mb={'10px'} color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%' align={'center'}>Clique em uma safra para carregar os dados no sistema.</Text>
                {renderCheckbox()}
                <Card direction='column' w='100%' px='0px' py='0px' bg={'transparent'}>
                    {renderSafra()}
                </Card>
                <Wrap spacing='30px' mx={'30px'} my={'30px'} justify='flex-start'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                </Wrap>
                <Loader isOpen={loading} />
            </Card>
        </Box>
    );
};
