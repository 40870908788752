
export async function retornaHtmlListaQualidadeSangria(objItens) {
    let result = '';
    try {
        const nomeMesCorrente = objItens.nomeMesCorrente;
        const nomeUa = objItens.nomeUa;
        const nomeMunicipio = objItens.nomeMunicipio;
        const idUf = objItens.idUf;
        const anoCorrente = objItens.anoCorrente;
        const avaliacaoGeral = objItens.avaliacaoGeral;
        const corNotaGeral = objItens.corNotaGeral;
        const notaGeral = objItens.notaGeral;
        const mediaGeralDePontos = objItens.mediaGeralDePontos;
        const arrayQualidadeCompleto = objItens.arrayQualidadeCompleto;
        const listUrlArquivoRelatorio = objItens.listUrlArquivoRelatorio;

        const resSeringueiros = await retornaSeringueirosHtml(arrayQualidadeCompleto);
        const resSeringueirosEsporadicos = await retornaSeringueirosEsporadicoHtml(listUrlArquivoRelatorio);
        let mediaG = `${mediaGeralDePontos}`;
        mediaG = mediaG.length == 1 ? `${mediaG}.0` : mediaG;
        mediaG = mediaG.replace('.', ',');

        let notaG = `${notaGeral}`;
        notaG = notaG.length == 1 ? `${notaG}.0` : notaG;
        notaG = notaG.replace('.', ',');

        result = `
            <!DOCTYPE html>
            <html>
            <style>
                * {
                    print-color-adjust: exact !important;
                }
                @page {
                    margin: 2px;
                }
                .center {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 50%;
                }
                .headerTitulo {
                    text-align: center; 
                    font-size: 15px; 
                    font-weight: bold; 
                    margin-top: 0px; 
                    margin-bottom: 0px; 
                    color: gray;
                }
                .cards {
                    width: 30%;
                    border: 3px solid gray;
                    border-radius: 20px;
                }
                .evidencia {
                    width: 150px;
                    height: 60px;
                    margin-left: 10px;
                    margin-top: 2px;
                }
                .page-break {
                    page-break-before: always;
                }
            </style>
            <head>
                <meta name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
            </head>
            <body style="text-align: center; height: auto;">
                <div style="margin: 10px;">
                    <div  style="position: relative;">
                        <div style="justify-content: center; align-items: center; margin-top: 50px;">
                            <h1 style="text-align: center; font-size: 25px; font-weight: bold; margin-bottom: 0px; color: rgb(175, 116, 6);">Qualidade de Sangria ${anoCorrente}</h1>
                            <h1 style="text-align: center; font-size: 15px; font-weight: bold; margin-top: 0px; margin-bottom: 0px; color: gray;">${nomeMesCorrente}</h1>
                            <h1 style="text-align: center; font-size: 15px; font-weight: bold; margin-top: 0px; margin-bottom: 0px; color: gray;">${nomeUa}</h1>
                            <h1 style="text-align: center; font-size: 15px; font-weight: bold; margin-top: 0px; margin-bottom: 0px; color: gray;">${nomeMunicipio}/${idUf}</h1>
                        </div>
                        <div style="position: absolute; left: 20px; bottom: 50px;">
                            <div style="justify-content: flex-start; align-items: flex-start; width: 100%;">
                                <img src="https://storage.googleapis.com/hevea_bucket/system_images/logo_pdf_novo.jpg" alt="Logo Hevea" class="evidencia" />
                            </div>    
                        </div>        
                    </div>
                    <br>
                    <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; margin-top: 0px;">
                        <div class="cards">
                            <h1 style="font-size: 15px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">Total de pontos</h1>
                            <h1 style="font-size: 20px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">${mediaG}</h1>
                        </div>
                        <div class="cards">
                            <h1 style="font-size: 15px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">Nota do seringueiro</h1>
                            <h1 style="font-size: 20px; font-weight: bold; text-align:center; color: ${corNotaGeral}; margin-top: 5px; margin-bottom: 5px;">${notaG}</h1>
                        </div>
                        <div class="cards">
                            <h1 style="font-size: 15px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">Avaliação da sangria</h1>
                            <h1 style="font-size: 20px; font-weight: bold; text-align:center; color: ${corNotaGeral}; margin-top: 5px; margin-bottom: 5px;">${avaliacaoGeral}</h1>
                        </div>
                    </div>
                    ${resSeringueiros}
                    <div style="width: 100%; margin-top: 25px;">
                        <h1 style="font-size: 20px; font-weight: bold; text-align: center;">Qualidade Sangria Esporádica</h1>
                    </div>
                    ${resSeringueirosEsporadicos}
                    <br>
                </div>
            </body>
            </html>`;

    } catch (error) {
        console.log('Erro na classe GerarHtmlLancamentoProducao metodo retornaHtmlLancamentoProducao', error);
    }
    return result;
}

async function retornaSeringueirosHtml(arryParam) {
    var valConsumo = '';
    var count = 3;
    await new Promise(resolve => {
        resolve(
            arryParam.map(function (item, index) {
                count = count + 1;

                let pontosS = `${item.totalPontos}`;
                pontosS = pontosS.length == 1 ? `${pontosS}.0` : pontosS;
                pontosS = pontosS.replace('.', ',');

                let notaS = `${item.notaSeringueiro}`;
                notaS = notaS.length == 1 ? `${notaS}.0` : notaS;
                notaS = notaS.replace('.', ',');

                const idAvaliacao = item.idAvaliacao != null && item.idAvaliacao != '' ? Number(item.idAvaliacao) : 0;
                let corN = 'black';
                if (idAvaliacao == 1) {
                    corN = 'black';
                } else if (idAvaliacao == 2) {
                    corN = 'green';
                } else if (idAvaliacao == 3) {
                    corN = 'orange';
                } else if (idAvaliacao == 4) {
                    corN = 'red';
                }


                const deletado = item.deletado != null ? item.deletado : false;
                let deletadoHtml = '';
                if (deletado) {
                    deletadoHtml = `
                        <div style="display: flex; justify-content: end; align-items: center; padding-right: 20px;">
                            <div style="width: 120px; background-color: red; margin-left: 20px; border-radius: 8px; padding-bottom: 2px; padding-top: 2px; margin-top: 5px;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align: center; color: white; margin-top: 1px; margin-bottom: 1px;"> Desativado </h1>
                            </div>
                        </div>
                    `;
                }
                let xx = `
                <div style="width: 100%; height: 80px; display: flex; justify-content: center; align-items: center; margin-top: 25px; border: 3px solid #808080; border-radius: 20px;">
                    <div style="width: 100%;">
                        ${deletadoHtml}
                        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Nome</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">${item.nome}</h1>
                            </div>
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Total de Pontos</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">${pontosS}</h1>
                            </div>
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Nota do seringueiro</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; color: ${corN}; margin-top: 5px; margin-bottom: 5px;">${notaS}</h1>
                            </div>
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Avaliação</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; color: ${corN}; margin-top: 5px; margin-bottom: 5px;">${item.avaliacaoSangria}</h1>
                            </div>
                        </div>
                    </div>
                </div>`;

                if (count > 7 && index < arryParam.length - 1) {
                    count = 0;
                    const quebraDePagina = `
                        <div class="page-break">
                            <h1 style="text-align: center; font-size: 15px; color: transparent;">2</h1>
                        </div>
                    `;
                    xx = xx + quebraDePagina;
                }
                valConsumo = valConsumo + xx;
            })
        )
    });
    return valConsumo;
}

async function retornaSeringueirosEsporadicoHtml(arryParam) {
    var valConsumo = '';
    var count = 3;
    await new Promise(resolve => {
        resolve(
            arryParam.map(function (item, index) {
                count = count + 1;

                const idAvaliacao = item.idAvaliacao != null && item.idAvaliacao != '' ? Number(item.idAvaliacao) : 0;
                let corN = 'black';
                if (idAvaliacao == 1) {
                    corN = 'gray';
                } else if (idAvaliacao == 2) {
                    corN = 'green';
                } else if (idAvaliacao == 3) {
                    corN = 'orange';
                } else if (idAvaliacao == 4) {
                    corN = 'red';
                }

                let xx = `
                <div style="width: 100%; height: 80px; display: flex; justify-content: center; align-items: center; margin-top: 5px; border: 3px solid #808080; border-radius: 20px; background-color: rgb(248, 236, 236);">
                    <div style="width: 100%;">

                        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Nome</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">${item.nomePessoa}</h1>
                            </div>
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Total de Pontos</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; margin-top: 5px; margin-bottom: 5px;">${item.totalPontos}</h1>
                            </div>
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Nota do seringueiro</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; color: ${corN}; margin-top: 5px; margin-bottom: 5px;">${item.notaPessoa}</h1>
                            </div>
                            <div style="width: 25%;">
                                <h1 style="font-size: 15px; font-weight: bold; text-align:center; color: gray; margin-top: 0px; margin-bottom: 0px;">Avaliação</h1>
                                <h1 style="font-size: 18px; font-weight: bold; text-align:center; color: ${corN}; margin-top: 5px; margin-bottom: 5px;">${item.nomeAvaliacao}</h1>
                            </div>
                        </div>
                    </div>
                </div>`;

                if (count > 7 && index < arryParam.length - 1) {
                    count = 0;
                    const quebraDePagina = `
                        <div class="page-break">
                            <h1 style="text-align: center; font-size: 15px; color: transparent;">2</h1>
                        </div>
                    `;
                    xx = xx + quebraDePagina;
                }
                valConsumo = valConsumo + xx;
            })
        )
    });
    return valConsumo;
}
