import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    Select,
    SimpleGrid,
    Box,
    Wrap,
    Center,
    Radio,
    RadioGroup,
    Stack,
    Textarea,
} from "@chakra-ui/react";
import { ASSUNTO } from 'model/MockData'
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";

export function AddAssuntoPersonalizado() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [isValidAssunto, setIsValidAssunto] = useState(false);
    const [assuntoSelecionado, setAssuntoSelecionado] = useState(null);
    const [nomeAssunto, setNomeAssunto] = useState('');
    const [sugestoesTecnicas, setSugestoesTecnicas] = useState('');
    const [isValidSugestoesTecnicas, setIsValidSugestoesTecnicas] = useState(false);
    const [local, setLocal] = useState('');
    const [isValidLocal, setIsValidLocal] = useState(false);
    const [prazo, setPrazo] = useState('');
    const [isValidPrazo, setIsValidPrazo] = useState(false);
    const [responsavel, setResponsavel] = useState('');
    const [isValidResponsavel, setIsValidResponsavel] = useState(false);
    const [idRelatorioPersonalizado, setIdRelatorioPersonalizado] = useState(0);
    const [nomeRequest, setNomeRequest] = useState('Adicionar');
    const [inputAssunto, setInputAssunto] = useState('');
    const [isValidInputAssunto, setIsValidInputAssunto] = useState(false);
    const [tipoAssunto, setTipoAssunto] = useState('1');

    const textColor = useColorModeValue("secondaryGray.900", "white");

    useEffect(() => {
        async function loaderScreen() {

            const dadosProdutor = localStorage.getItem('@HE-dados-tela-assunto');
            if (dadosProdutor !== null) {
                const dadosAssunto = JSON.parse(dadosProdutor);

                if (dadosAssunto.tipoAssunto != '') {

                    if (dadosAssunto.tipoAssunto == '1') {
                        setTipoAssunto('1');
                        setAssuntoSelecionado(`${dadosAssunto.idAssunto}`);
                    } else {
                        setTipoAssunto('2');
                        setInputAssunto(dadosAssunto.nomeAssunto);
                    }
                    setIdRelatorioPersonalizado(dadosAssunto.idRelatorioPersonalizado);
                    setSugestoesTecnicas(dadosAssunto.sugestoesTecnicas);
                    setLocal(dadosAssunto.local);
                    setPrazo(dadosAssunto.prazo);
                    setResponsavel(dadosAssunto.responsavel);

                    setNomeRequest('Alterar');
                } else {
                    setNomeRequest('Adicionar');
                }

                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const adicionarAssunto = async () => {
        try {
            let textoDoAssunto = '';
            let valid = false;

            if (tipoAssunto == '2') {
                if (inputAssunto.trim().length < 2) {
                    valid = true;
                    setIsValidInputAssunto(true);
                } else {
                    textoDoAssunto = inputAssunto;
                    setIsValidInputAssunto(false);
                }
            } else {
                if (assuntoSelecionado == null) {
                    valid = true;
                    setIsValidAssunto(true);
                } else {
                    textoDoAssunto = nomeAssunto;
                    setIsValidAssunto(false);
                }
            }
            if (sugestoesTecnicas.length < 2) {
                setIsValidSugestoesTecnicas(true);
                valid = true;
            } else {
                setIsValidSugestoesTecnicas(false);
            }
            if (local.length < 2) {
                setIsValidLocal(true);
                valid = true;
            } else {
                setIsValidLocal(false);
            }
            if (prazo.length < 2) {
                setIsValidPrazo(true);
                valid = true;
            } else {
                setIsValidPrazo(false);
            }
            if (responsavel.length < 2) {
                setIsValidResponsavel(true);
                valid = true;
            } else {
                setIsValidResponsavel(false);
            }

            if (valid) return;

            let idRelatorioPersonalizadoP = 1;
            const arr = localStorage.getItem('@HE-array-assunto');

            if (arr != null) {

                const arr1 = JSON.parse(arr);
                arr1.sort(function (a, b) { return b.idRelatorioPersonalizado - a.idRelatorioPersonalizado });

                if (nomeRequest == 'Adicionar') {

                    if (arr1.length > 0) {
                        const id = arr1[0].idRelatorioPersonalizado;
                        idRelatorioPersonalizadoP = Number(id) + 1;
                    }

                    const jsonAdd = [{
                        idRelatorioPersonalizado: idRelatorioPersonalizadoP,
                        idAssunto: assuntoSelecionado,
                        nomeAssunto: textoDoAssunto,
                        sugestoesTecnicas: sugestoesTecnicas,
                        local: local,
                        prazo: prazo,
                        responsavel: responsavel,
                        tipoAssunto: tipoAssunto
                    }]
                    const novoArray = [...jsonAdd, ...arr1];
                    localStorage.setItem('@HE-array-assunto', JSON.stringify(novoArray));
                    history.goBack();
                } else {

                    const arrUpdate = arr1.filter(x => x.idRelatorioPersonalizado != idRelatorioPersonalizado);

                    const jsonUpdate = [{
                        idRelatorioPersonalizado: idRelatorioPersonalizado,
                        idAssunto: assuntoSelecionado,
                        nomeAssunto: textoDoAssunto,
                        sugestoesTecnicas: sugestoesTecnicas,
                        local: local,
                        prazo: prazo,
                        responsavel: responsavel,
                        tipoAssunto: tipoAssunto
                    }]
                    const novoArray = [...jsonUpdate, ...arrUpdate];
                    localStorage.setItem('@HE-array-assunto', JSON.stringify(novoArray));
                    history.goBack();
                }
            } else {
                const jsonNew = [{
                    idRelatorioPersonalizado: idRelatorioPersonalizadoP,
                    idAssunto: assuntoSelecionado,
                    nomeAssunto: textoDoAssunto,
                    sugestoesTecnicas: sugestoesTecnicas,
                    local: local,
                    prazo: prazo,
                    responsavel: responsavel,
                    tipoAssunto: tipoAssunto
                }]
                localStorage.setItem('@HE-array-assunto', JSON.stringify(jsonNew));
                history.goBack();
            }

        } catch (error) {
            console.log('Erro na classe FormProdutores metodo salvarProdutor', error);
        }
    }

    const goBackPage = () => {
        history.goBack();
    }

    const changeAssunto = (e) => {
        const val = e.target.value;
        setAssuntoSelecionado(val);
        if (val != null) {
            const fil = ASSUNTO.filter(x => x.value == val);
            const assuntoText = fil[0].label;
            setNomeAssunto(assuntoText);
        }
    }

    function renderRadioButton() {
        return (
            <Box p={4} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between">
                <RadioGroup onChange={setTipoAssunto} value={tipoAssunto} defaultValue='1'>
                    <Stack spacing={5} direction='row'>
                        <Radio size='lg' name='1' colorScheme='blue' value='1'>
                            Selecionar
                        </Radio>
                        <Radio size='lg' name='1' colorScheme='blue' value='2'>
                            Digitar
                        </Radio>
                    </Stack>
                </RadioGroup>
            </Box>
        )
    }

    function renderDigitarAssunto() {
        return (
            <Box px={'20px'}>
                <Center justifyContent={'center'}>
                    <Textarea
                        value={inputAssunto}
                        onChange={(e) => setInputAssunto(e.target.value)}
                        isInvalid={isValidInputAssunto}
                        placeholder='Digite o assunto'
                        size='sm'
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        justifyContent={'center'}
                        alignItems={'center'}
                    />
                </Center>
            </Box>
        )
    }

    function renderSelecionarAssunto() {
        return (
            <Box w={'98%'}>
                <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='0px'>
                    <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                        <Select
                            value={assuntoSelecionado}
                            isInvalid={isValidAssunto}
                            errorBorderColor='crimson'
                            placeholder="selecione"
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            onChange={changeAssunto}
                        >
                            {ASSUNTO.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                    <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                    </Box>
                </SimpleGrid>
            </Box>
        )
    }

    function renderInputs() {
        return (
            <Card direction='column' w='99%' mx='5px' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }} borderWidth={0} >
                <Box w={'98%'}>
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Sugestões Técnicas *</Text>
                            <Input
                                value={sugestoesTecnicas}
                                onChange={(e) => setSugestoesTecnicas(e.target.value)}
                                isInvalid={isValidSugestoesTecnicas}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Local *</Text>
                            <Input
                                value={local}
                                onChange={(e) => setLocal(e.target.value)}
                                isInvalid={isValidLocal}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="text"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box w={'98%'}>
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Prazo *</Text>
                            <Input
                                value={prazo}
                                onChange={(e) => setPrazo(e.target.value)}
                                isInvalid={isValidPrazo}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="text"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Responsável *</Text>
                            <Input
                                value={responsavel}
                                onChange={(e) => setResponsavel(e.target.value)}
                                isInvalid={isValidResponsavel}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="text"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
            </Card>
        )
    }
    if (!telaCarregada) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Card direction='column' w='99%' mx='5px' px='0px' pt='0px' overflowX={{ sm: "scroll", lg: "hidden" }} borderWidth={4} >
                    {renderRadioButton()}
                    {tipoAssunto == '1' ? renderSelecionarAssunto() : renderDigitarAssunto()}
                </Card>
                {renderInputs()}
                <Wrap spacing='30px' mx={'10px'} mt={'20px'} justify='space-between' >
                    <Button variant="brand" onClick={goBackPage}>Voltar</Button>
                    <Button variant="brand" onClick={adicionarAssunto}>Salvar</Button>
                </Wrap>
            </Card>
        </Box>
    );
}

export default AddAssuntoPersonalizado;
