import React, { useEffect, useState } from "react";
import {
    useColorModeValue,
    Flex,
    Box,
    Text,
    useDisclosure,
    SimpleGrid,
    Wrap,
    WrapItem,
    Button,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Icon,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO } from '../../../util/FuncoesUteis';
import { MdBrightness1 } from "react-icons/md";
import { useHistory } from "react-router-dom";
import LoaderBlack from 'components/loading/LoaderBlack';

export default function ScreenDefault() {

    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listCalendarioMesRequest, setListCalendarioMesRequest] = useState([]);
    const [inputMilimetros, setInputMilimetros] = useState('');
    const [inputTempMax, setInputTempMax] = useState('');
    const [inputTempMin, setInputTempMin] = useState('');
    const [inputTempMediaDiaria, setInputTempMediaDiaria] = useState('');
    const [inputVento, setInputVento] = useState('');
    const [inputUmidade, setInputUmidade] = useState('');
    const [isValidInputMilimetros, setIsValidInputMilimetros] = useState(false);

    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [arrayMesFim, setArrayMesFim] = useState([]);
    const [mesInicioSelecionado, setMesInicioSelecionado] = useState('');
    const [mesFimSelecionado, setMesFimSelecionado] = useState('');

    const [idClimaTempo, setIdClimaTempo] = useState(null);
    const [insertOrUpdate, setInsertOrUpdate] = useState('');
    const [dtCadastro, setDtCadastro] = useState('');
    const [nomeDiaSemana, setNomeDiaSemana] = useState('');

    const [idUa, setIdUa] = useState(null);
    const [idUf, setIdUf] = useState(null);
    const [idSafra, setIdSafra] = useState(null);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loadScreen() {
            window.scrollTo(0, 0);

            const idUaP = localStorage.getItem('@HE-idUa');
            const idSafraP = localStorage.getItem('@HE-idSafra');
            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');

            setIdUa(idUaP);
            setIdSafra(idSafraP);
            setIdUf(idUfP);

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');
            const arrayDatas = await ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(ini, fim);

            setArrayMesFim(arrayDatas);
            setArrayMesInicio(arrayDatas);

            const dtInicio = moment(ini).startOf('month').format('YYYY-MM-DD');
            const dtFim = moment().startOf('month').format('YYYY-MM-DD');

            setMesInicioSelecionado(dtInicio);
            setMesFimSelecionado(dtFim);

            await pesquisar(idUaP, idSafraP, idUfP, dtInicio, dtFim);
            setTelaCarregada(true);
        }
        loadScreen();
    }, [])

    const pesquisar = async (idUaP, idSafraP, idUfP, dtInicio, dtFim) => {
        try {
            setLoading(true);

            const url = `climaTempo/pesquisarVariosMesesClimaTempo?idUa=${idUaP}&idSafra=${idSafraP}&idUf=${idUfP}&dtInicio=${dtInicio}&dtFim=${dtFim}`;

            const response = await Api.getRequest(url);
            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                setListCalendarioMesRequest(res);
            } else {
                console.log('');

            }
        } catch (error) {
            setLoading(false);
            setTelaCarregada(true);
            console.log('Erro na classe ChuvasDiarias metodo pesquisar', error);
        }
    }

    const changeChuvaMm = async (item) => {
        setDtCadastro(item.dtCadastro);
        setNomeDiaSemana(item.nomeDiaSemana);

        if (item.idClimaTempo != null && item.idClimaTempo != '') {
            setIdClimaTempo(item.idClimaTempo);
            setInputMilimetros(item.chuvaMm);
            setInputTempMax(item.temperaturaMaxima);
            setInputTempMin(item.temperaturaMinima);
            setInputTempMediaDiaria(item.temperaturaMediaDiaria);
            setInputVento(item.ventoMediaDiaria);
            setInputUmidade(item.umidadeDiaria);

            setInsertOrUpdate('update');
        } else {
            setIdClimaTempo(null);
            setInputMilimetros('');
            setInputTempMax('');
            setInputTempMin('');
            setInputTempMediaDiaria('');
            setInputVento('');
            setInputUmidade('');

            setInsertOrUpdate('insert');
        }
        onOpen();
    }

    const validarAndSalvar = async () => {
        let isValid = false;

        if (inputMilimetros.length < 2) {
            setIsValidInputMilimetros(true);
            isValid = true;
        } else {
            setIsValidInputMilimetros(false);
        }

        if (isValid) return;
        onClose();
        setLoading(true);

        const json = {
            idClimaTempo: idClimaTempo,
            idUa: idUa,
            idSafra: idSafra,
            idUf: idUf,
            chuvaMm: inputMilimetros,
            temperaturaMediaDiaria: inputTempMediaDiaria != '' ? inputTempMediaDiaria : null,
            temperaturaMaxima: inputTempMax != '' ? inputTempMax : null,
            temperaturaMinima: inputTempMin != '' ? inputTempMin : null,
            umidadeDiaria: inputUmidade != '' ? inputUmidade : null,
            ventoMediaDiaria: inputVento != '' ? inputVento : null,
            dtCadastro: dtCadastro,
        }

        if (insertOrUpdate == 'update') {
            const response = await Api.putRequest('climaTempo/updateClimaTempo', json);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                await pesquisar(idUa, idSafra, idUf, mesInicioSelecionado, mesFimSelecionado);
            }

            setLoading(false);
        } else {
            const response = await Api.postRequest('climaTempo/insertClimaTempo', json);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                await pesquisar(idUa, idSafra, idUf, mesInicioSelecionado, mesFimSelecionado);
            }
            setLoading(false);
        }
    }

    function renderTitulo() {
        return (
            <Box w={'100%'}>
                <Text color={'orange.600'} fontSize='22px' fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Lançamentos de chuva'}</Text>
                <Box>
                    <Flex justify='start' align='center'>
                        <Icon as={MdBrightness1} width='18px' height='18px' color='blue.600' />
                        <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Lançamento de chuva realizado'}</Text>
                    </Flex>
                    <Flex py={'10px'} justify='start' align='center'>
                        <Icon as={MdBrightness1} width='18px' height='18px' color='aqua' />
                        <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Quantidade chuva em milímetros'}</Text>
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderMeses() {
        return (
            <Wrap spacing='10px' mt={'20px'} mb={'15px'} justify='flex-start'>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Início</Text>
                        <Select
                            value={mesInicioSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesInicioSelecionado(e.target.value)}
                            w={'200px'}
                            h={'35px'}
                        >
                            {arrayMesInicio.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' >
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Fim</Text>
                        <Select
                            value={mesFimSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesFimSelecionado(e.target.value)}
                            w={'200px'}
                            h={'35px'}
                        >
                            {arrayMesFim.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' h={'100%'}>
                        <Center alignItems={'flex-end'} h={'100%'}>
                            <Button variant="brand" onClick={() => { pesquisar(idUa, idSafra, idUf, mesInicioSelecionado, mesFimSelecionado) }}>Pesquisar</Button>
                        </Center>
                    </Box>
                </WrapItem>
            </Wrap>
        )
    }

    function renderCardsDias() {
        return (
            <Box mt={'20px'}>
                <Text color={textColor} fontSize='18px' fontWeight='bold' align={'center'} >{'Clique na data para editar'}</Text>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 3 }} gap='20px' mb='0px'>
                    {listCalendarioMesRequest.map((itemP, indexP) => {
                        const mesP = itemP.mesDto.nomeMes != null ? itemP.mesDto.nomeMes : '';
                        const anoP = itemP.mesDto.dtMes != null ? moment(itemP.mesDto.dtMes).format('YYYY') : '';
                        const descMes = `${mesP}-${anoP}`
                        return (
                            <Box key={indexP} w={'100%'} mt={'10px'} py={'10px'} borderWidth={'1px'} borderColor={'black'} borderRadius={'10px'}>
                                <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight={'100%'} align={'center'}>{descMes}</Text>
                                <Box px={'0px'} mt={'10px'}>
                                    <Flex justify={'space-between'} align={'center'}>
                                        <Flex w={'14%'} justify={'center'} align={'center'}>
                                            <Text color={'black'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Seg'}</Text>
                                        </Flex>
                                        <Flex w={'14%'} justify={'center'} align={'center'}>
                                            <Text color={'black'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Ter'}</Text>
                                        </Flex>
                                        <Flex w={'14%'} justify={'center'} align={'center'}>
                                            <Text color={'black'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Qua'}</Text>
                                        </Flex>
                                        <Flex w={'14%'} justify={'center'} align={'center'}>
                                            <Text color={'black'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Qui'}</Text>
                                        </Flex>
                                        <Flex w={'14%'} justify={'center'} align={'center'}>
                                            <Text color={'black'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Sex'}</Text>
                                        </Flex>
                                        <Flex w={'14%'} justify={'center'} align={'center'}>
                                            <Text color={'black'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Sab'}</Text>
                                        </Flex>
                                        <Flex w={'14%'} justify={'center'} align={'center'}>
                                            <Text color={'black'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Dom'}</Text>
                                        </Flex>
                                    </Flex>
                                </Box>
                                {itemP.mesDto.listSemana.map((item, index) => {
                                    return (
                                        <Box key={index} w={'100%'}>
                                            <Flex justify={'space-between'} align={'center'}>
                                                {item.listClimaTempo.map((x, y) => {
                                                    let chuvaMmP = '';
                                                    let colorP = 'white';
                                                    let colorText = 'black';
                                                    let dia = x.dtCadastro != null ? moment(x.dtCadastro).format('DD') : '';
                                                    if (x.isDisabled) {
                                                        colorP = 'gray.300';
                                                    } else if (x.chuvaMm != null && x.chuvaMm != '') {
                                                        chuvaMmP = x.chuvaMm;
                                                        colorText = 'white';
                                                        colorP = '#003366'; // BLUE
                                                    } else {
                                                        colorP = 'white';
                                                    }
                                                    return (
                                                        <Flex key={y} flexDirection={'column'} w={'100%'} justify={'center'} align={'center'}>
                                                            <Flex
                                                                pointerEvents={x.isDisabled ? 'none' : 'auto'}
                                                                opacity={x.isDisabled ? '0.4' : '1'}
                                                                cursor={'pointer'}
                                                                w={'40px'}
                                                                h={'50px'}
                                                                mt={'8px'}
                                                                bg={colorP}
                                                                borderWidth={'1px'}
                                                                borderColor={'black'}
                                                                borderRadius={'10px'}
                                                                flexDirection={'column'}
                                                                justify={'center'}
                                                                onClick={() => { changeChuvaMm(x) }}
                                                            >
                                                                <Flex w={'100%'} h={'25px'} justify={'center'} align={'center'}>
                                                                    <Text color={colorText} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{dia}</Text>
                                                                </Flex>
                                                                <Flex p={'3px'} w={'100%'} h={'25px'} justify={'center'} align={'center'}>

                                                                    <Flex w={'100%'} h={'100%'} justify={'center'} align={'center'} borderRadius={'5px'} bg={chuvaMmP != '' ? '#99ffff' : 'transparent'}>
                                                                        <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{chuvaMmP}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    )
                                                })}
                                            </Flex>
                                        </Box>
                                    )
                                })}
                            </Box>
                        )
                    })}
                </SimpleGrid>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mt={'20px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    function renderModal() {
        const dtDia = moment(dtCadastro).format('DD/MM/YY');
        return (
            <Box>
                <Modal
                    isOpen={isOpen}
                    size="xs"
                    onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Text color={textColor} fontSize='18px' fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{`Clima / Tempo`}</Text>
                            <Text color={'orange'} fontSize='14px' fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{`${dtDia} - ${nomeDiaSemana}`}</Text>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box mt={'0px'}>
                                <Flex flexDirection={'column'} justify='center'>
                                    <Text color={textColor} fontSize='14px' fontWeight={'bold'} ml={'10px'} align={'left'}>{'Chuva em milímetros *'}</Text>
                                    <InputGroup size='md'>
                                        <Input
                                            value={inputMilimetros}
                                            onChange={(e) => setInputMilimetros(e.target.value.trim())}
                                            isInvalid={isValidInputMilimetros}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            maxLength={4}
                                            h={'35px'}
                                        />
                                        <InputRightElement display='flex' alignItems='center'>
                                            <Text color={'gray'} fontSize={'13px'} fontWeight={'bold'} mr={'10px'}>{'(mm)'}</Text>
                                        </InputRightElement>
                                    </InputGroup>
                                    
                                    {isValidInputMilimetros && <Text color={'red'} fontSize='14px' fontWeight={'bold'} ml={'10px'} align={'left'} lineHeight={'100%'}>{'Campo obrigatório'}</Text>}
                                </Flex>
                                <Flex mt={'10px'} flexDirection={'column'} justify='center'>
                                    <Text color={textColor} fontSize='14px' fontWeight={'bold'} ml={'10px'} align={'left'}>{'Temp Max'}</Text>
                                    <InputGroup size='md'>
                                        <Input
                                            value={inputTempMax}
                                            onChange={(e) => setInputTempMax(e.target.value.trim())}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            maxLength={4}
                                            h={'35px'}
                                        />
                                        <InputRightElement display='flex' alignItems='center'>
                                            <Text color={'gray'} fontSize={'13px'} fontWeight={'bold'} mr={'0px'}>{'( º )'}</Text>
                                        </InputRightElement>
                                    </InputGroup>
                                </Flex>
                                <Flex mt={'10px'} flexDirection={'column'} justify='center'>
                                    <Text color={textColor} fontSize='14px' fontWeight={'bold'} ml={'10px'} align={'left'}>{'Temp Média diária'} </Text>
                                    <InputGroup size='md'>
                                        <Input
                                            value={inputTempMediaDiaria}
                                            onChange={(e) => setInputTempMediaDiaria(e.target.value.trim())}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            maxLength={4}
                                            h={'35px'}
                                        />
                                        <InputRightElement display='flex' alignItems='center'>
                                            <Text color={'gray'} fontSize={'13px'} fontWeight={'bold'} mr={'0px'}>{'( º )'}</Text>
                                        </InputRightElement>
                                    </InputGroup>
                                </Flex>
                                {/* <Flex mt={'10px'} flexDirection={'column'} justify='center'>
                                    <Text color={textColor} fontSize='14px' fontWeight={'bold'} ml={'10px'} align={'left'}>{'Umidade'} </Text>
                                    <InputGroup size='md'>
                                        <Input
                                            value={inputUmidade}
                                            onChange={(e) => setInputUmidade(e.target.value.trim())}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            maxLength={4}
                                            h={'35px'}
                                        />
                                        <InputRightElement display='flex' alignItems='center'>
                                            <Text color={'gray'} fontSize={'13px'} fontWeight={'bold'} mr={'1px'}>{'(%)'}</Text>
                                        </InputRightElement>
                                    </InputGroup>
                                </Flex> */}
                                {/* <Flex mt={'10px'} flexDirection={'column'} justify='center'>
                                    <Text color={textColor} fontSize='14px' fontWeight={'bold'} ml={'10px'} align={'left'}>{'Vento'} </Text>
                                    <InputGroup size='md'>
                                        <Input
                                            value={inputVento}
                                            onChange={(e) => setInputVento(e.target.value.trim())}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            maxLength={4}
                                            h={'35px'}
                                        />
                                        <InputRightElement display='flex' alignItems='center'>
                                            <Text color={'gray'} fontSize={'13px'} fontWeight={'bold'} mr={'25px'}>{'(Km/h)'}</Text>
                                        </InputRightElement>
                                    </InputGroup>
                                </Flex> */}
                            </Box>
                        </ModalBody>
                        <ModalFooter w={'100%'}>
                            <Wrap spacing='10px' mx={'10px'} mt={'10px'} justify='space-between'>
                                <Button w={'90px'} variant="brand" onClick={validarAndSalvar}>Salvar</Button>
                            </Wrap>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' mt={'50px'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "40px" }}>
            <Card px={'10px'} py={'10px'} >
                {renderTitulo()}
                {renderMeses()}
                {renderCardsDias()}
                {renderButton()}
            </Card>
            {renderModal()}
            {loading && <LoaderBlack isOpen={loading} />}
        </Box>
    );
}
