import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    Select,
    SimpleGrid,
    Table,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
    Wrap,
    WrapItem,
    RadioGroup,
    Radio,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import moment from 'moment';
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import ExcelIconDownload from 'assets/img/layout/excel_icon_download.png';
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';

const QTD_PAGE = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
];

export default function AcompanhamentoSeringueiros() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [idTipoUsuarioLogado, setIdTipoUsuarioLogado] = useState(null);
    const [listUa, setListUa] = useState([]);
    const [listUaCompleto, setListUaCompleto] = useState([]);
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [safraSelecionado, setSafraSelecionado] = useState('');
    const [listaSafraCombo, setListaSafraCombo] = useState([]);

    const [nomeUaSelecionada, setNomeUaSelecionada] = useState('');
    const [listaSeringueiros, setListaSeringueiros] = useState([]);
    const [radioSangriaAtiva, setRadioSangriaAtiva] = useState('3');
    const [radioSeringueiroDesativado, setRadioSeringueiroDesativado] = useState('3');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [listPessoa, setListPessoa] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [nomeRelatorio, setNomeRelatorio] = useState('Acompanhamento-seringueiro');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradienGray = 'linear-gradient(gray.300, gray.200, white)';

    const cardShadow = useColorModeValue('0px 0px 5px rgba(0, 0, 0, 0.5)', 'unset');
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Acompanhamento-seringueiro-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);

            await pesquisarPropriedades();
        }
        loaderScreen();
    }, [])

    const totalPages = Math.ceil(listaSeringueiros.length / itemsPerPage);
    const paginatedData = listaSeringueiros.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    const pesquisarPropriedades = async () => {

        setUaSelecionado('');
        const idTipoUserioLogado = localStorage.getItem('@HE-idTipoUsuarioLogado');
        setIdTipoUsuarioLogado(idTipoUserioLogado);

        const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
        
        const response = await Api.getRequest(`api/ua/retornaPropriedadesAndSafrasQueUsuarioTemAcesso?idUsuario=${idUsuarioLogado}`);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;
            setListUaCompleto(res);

            if (res.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = res.map(function (item) {
                            return {
                                value: `${item.idUa}`,
                                label: item.nomeFazenda
                            }
                        })
                    )
                });
                setListUa(arrayCombo);
            }
            setTelaCarregada(true);
        } else {
            setTelaCarregada(true);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const changePropriedade = async (e) => {
        const idUa = e;
        setListaSafraCombo([]);
        setListPessoa([]);
        setPessoaSelecionado('');
        setSafraSelecionado('');
        setNomeUaSelecionada('');
        setUaSelecionado(idUa);

        if (idUa != '') {
            const xx = listUa.filter(x => x.value == idUa);
            const nomeUa = xx.length > 0 ? xx[0].label : '';
            setNomeUaSelecionada(nomeUa);
            const arrUa = listUaCompleto.filter(x => x.idUa == idUa);
            const listaSafraItem = arrUa[0].listaSafraItem;

            if (listaSafraItem.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = listaSafraItem.map(function (item) {
                            return {
                                value: `${item.idSafra}`,
                                label: item.nomeSafra
                            }
                        })
                    )
                });
                setListaSafraCombo(arrayCombo);
            }
        }
    }

    const changeSafra = async (val) => {
        try {
            setListPessoa([]);
            setPessoaSelecionado('');
            setSafraSelecionado(val);

            if (val != '') {
                //setLoading(true);
                const url = `api/pessoa/listaPessoaPorSafra?idSafra=${val}`;
                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    if (res.length > 0) {
                        let arrayPess = [];
                        await new Promise(resolve => {
                            resolve(
                                res.map(function (item) {
                                    const obj = {
                                        label: item.nome,
                                        value: `${item.idPessoa}`
                                    }
                                    arrayPess.push(obj);
                                })
                            )
                        });
                        setListPessoa(arrayPess);
                    }
                } else {
                    //setLoading(false);
                }
            }
        } catch (error) {
            console.log('Error na classe TarefasSafraList metodo changeSafra', error);
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const pesquisar = async () => {
        try {
            setLoading(true);

            const obj = montarJsonPesquisa();

            const saAtiva = obj.saAtiva;
            const desativado = obj.desativado;
            const idUa = obj.idUa;
            const idSafra = obj.idSafra;
            const idPessoa = obj.idPessoa;

            const url = `safraPessoa/retonaStatusSeringueiroAndSafra?idUa=${idUa}&idSafra=${idSafra}&sangriaAtiva=${saAtiva}&idPessoa=${idPessoa}&deletado=${desativado}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setListaSeringueiros(res);
                setLoading(false);
            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe AcompanhamentoSeringueiros metodo ', pesquisar);
        }
    }

    const montarJsonPesquisa = () => {
        let saAtiva = '';
        if (radioSangriaAtiva == '1') {
            saAtiva = true;
        } else if (radioSangriaAtiva == '2') {
            saAtiva = false;
        }

        let desativado = '';
        if (radioSeringueiroDesativado == '1') {
            desativado = false;
        } else if (radioSeringueiroDesativado == '2') {
            desativado = true;
        }

        const idUa = uaSelecionado != '' ? uaSelecionado : 0;
        const idSafra = safraSelecionado != '' ? safraSelecionado : 0;
        const idPessoa = pessoaSelecionado != '' ? pessoaSelecionado : 0;

        const json = {
            idUa: idUa,
            idSafra: idSafra,
            idPessoa: idPessoa,
            desativado: desativado,
            saAtiva: saAtiva,
        }
        return json;
    }

    const changeSangria = (e) => {
        setRadioSangriaAtiva(e);
    }
    const changeSeringueiroDesativado = (e) => {
        setRadioSeringueiroDesativado(e);
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await criarRelatorioExcel();
            setLoading(false);
        }, 1000);
    }

    const criarRelatorioExcel = async () => {
        const obj = montarJsonPesquisa();

        const url = `safraPessoa/downloadExcelAcompanhamentoSeringueiro`;
        await Api.postRequestDownloadExcel(url, obj, nomeRelatorio);
    }

    function renderPropriedadeAndSangria() {
        const isDisabledSangria = listaSafraCombo.length > 0 ? false : true;
        const opacityText = isDisabledSangria ? '0.5' : '1';
        const ischeckPessoa = safraSelecionado != '' ? false : true;
        const opacityTextPessoa = safraSelecionado != '' ? '1' : '0.5';
        return (
            <Box
                position={'relative'}
                px={'20px'}
                py={'10px'}
                borderRadius={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.900'}
                bg={'white'}
                boxShadow={cardShadow}>

                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Acompanhamento Seringueiro</Text>
                <Text color={'gray.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%' align={'center'}>{'Utilize pelo menos um filtro para realizar a pesquisa'}</Text>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }} gap='10px' mt={'10px'}>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box  >
                            <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Propriedades</Text>
                            <Select
                                value={uaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={(e) => changePropriedade(e.target.value)}
                                h={'35px'}
                                w={'300px'}>
                                {listUa.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box>
                            <Text opacity={opacityText} ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Safras</Text>
                            <Select
                                value={safraSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                isDisabled={isDisabledSangria}
                                onChange={(e) => changeSafra(e.target.value)}
                                h={'35px'}
                                w={'300px'}>
                                {listaSafraCombo.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box>
                            <Text opacity={opacityTextPessoa} ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Seringueiros</Text>
                            <Select
                                value={pessoaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'15px'}
                                fontWeight={'bold'}
                                onChange={(e) => setPessoaSelecionado(e.target.value)}
                                h={'35px'}
                                w={'300px'}
                                disabled={ischeckPessoa}>
                                {listPessoa.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap='20px' mt={'20px'}>
                    <Flex px={'20px'} justify={'center'} align={'center'}>
                        <Box w={'100%'} py={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.800'} borderRadius={'10px'} bgGradient={gradienGray}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Sangria Ativa</Text>
                            <RadioGroup onChange={(e) => { changeSangria(e) }} value={radioSangriaAtiva}>
                                <Wrap spacing='2px' mt={'15px'} px={'15px'} justify={'space-around'} align={'center'}>
                                    <WrapItem w={'30%'}>
                                        <Flex justify={'start'} align={'center'}>
                                            <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'black'} fontSize='15px' fontWeight='700' lineHeight='100%'>Sim</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem w={'30%'}>
                                        <Flex justify={'start'} align={'center'}>
                                            <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'black'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem w={'30%'}>
                                        <Flex justify={'start'} align={'center'}>
                                            <Radio value='3' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'black'} fontSize='15px' fontWeight='700' lineHeight='100%'>Todos</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                </Wrap>
                            </RadioGroup>
                        </Box>
                    </Flex>
                    <Flex px={'20px'} justify={'center'} align={'center'}>
                        <Box w={'100%'} py={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.800'} borderRadius={'10px'} bgGradient={gradienGray}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Seringueiro Ativo</Text>
                            <RadioGroup onChange={(e) => { changeSeringueiroDesativado(e) }} value={radioSeringueiroDesativado}>
                                <Wrap spacing='2px' mt={'15px'} px={'15px'} justify={'space-between'} align={'center'}>
                                    <WrapItem>
                                        <Flex justify={'start'} align={'center'}>
                                            <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'black'} fontSize='15px' fontWeight='700' lineHeight='100%'>Ativo</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem>
                                        <Flex justify={'start'} align={'center'}>
                                            <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'black'} fontSize='15px' fontWeight='700' lineHeight='100%'>Desativado</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem>
                                        <Flex justify={'start'} align={'center'}>
                                            <Radio value='3' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'black'} fontSize='15px' fontWeight='700' lineHeight='100%'>Todos</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                </Wrap>
                            </RadioGroup>
                        </Box>
                    </Flex>
                </SimpleGrid>
                <Flex mt={'20px'} justify={'end'} align="center" >
                    <Button variant="brand" onClick={() => { pesquisar() }}>Pesquisar</Button>
                </Flex>
                <Box position={'absolute'} top={'10px'} right={'10px'}>
                    <Flex
                        pointerEvents={listaSeringueiros.length > 0 ? 'auto' : 'none'}
                        opacity={listaSeringueiros.length > 0 ? '1' : '0.5'}
                        justify={'flex-end'}
                        align={'center'}
                        cursor={'pointer'}>
                        <Image boxSize='35px' objectFit='contain' src={ExcelIconDownload} alt='img' onClick={() => { gerarRelatorioExcel() }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderListaSafra() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        const colorTextHeader = 'white';
        const espacoW = 1;
        const corBorder = 'blackAlpha.400';
        let corLinha = false;
        return (
            <Box py={'20px'}>
                <Flex px={'10px'} direction="column" align="center">
                    <Table variant="striped" colorScheme='blackAlpha' size="sm">
                        <Thead position="sticky" top="0" zIndex="10">
                            <Tr h={'40px'} bgGradient={gradientHeader}>
                                <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Proprietário'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'12%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Propriedade'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'12%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Safra'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'12%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Seringueiro'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'12%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Qtd Árvores'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'8%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Status'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'12%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Ínicio Sangria'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'12%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Fim Sangria'}</Text>
                                    </Flex>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {paginatedData.map((item, index) => {
                                const existeProgramacao = item.isExisteProgramacao == true ? true : false;
                                const rodarProgramacao = item.permitirRodarProgramacao == true ? true : false;
                                const sagriaAti = item.sangriaAtiva == true ? true : false;
                                corLinha = !corLinha;
                                let colorLinha = corLinha ? 'blackAlpha.200' : 'white';
                                if (existeProgramacao && !rodarProgramacao && sagriaAti) {
                                    colorLinha = 'yellow.300';
                                }

                                const isDesativado = item.deletado == true ? true : false;
                                const colorSagriaAti = item.sangriaAtiva == true ? 'green' : 'red';
                                const colorExisteProgramacao = item.isExisteProgramacao == true ? 'green' : 'orange';
                                const dtInicioSangria = item.dtInicioSangria != null ? moment(item.dtInicioSangria).format('DD/MM/YY') : '';
                                const dtFimSangria = item.dtFimSangria != null ? moment(item.dtFimSangria).format('DD/MM/YY') : '--';
                                
                                
                                let colorDesativado = 'green';
                                let textDesativado = 'Ativo';
                                if(isDesativado){
                                    const dtDeletado = item.dtDeletado != null ? moment(item.dtDeletado).format('DD/MM/YY') : '';
                                    colorDesativado = 'red';
                                    textDesativado = `Desativado ${dtDeletado}`;
                                }

                                return (
                                    <Tr key={index} bg={colorLinha}>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${item.nomeUsuario}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${item.nomeFazenda}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={colorSagriaAti} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${item.nomeSafra}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={colorExisteProgramacao} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${item.nome}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`${item.quantidadeArvores}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={colorDesativado} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${textDesativado}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'}>{dtInicioSangria}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'}>{dtFimSangria}</Text>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                    <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                        <Flex justify='center' align='center'>
                            <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                            <Box ml={'10px'}>
                                <Select
                                    value={itemsPerPage}
                                    placeholder=""
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={'gray.200'}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    textColor={textColor}
                                    size="sm"
                                    h={'35px'}
                                    onChange={changePage}>
                                    {QTD_PAGE.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </Flex>
                        <Flex justify='center' align='center'>
                            <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaSeringueiros.length}`}</Text>
                            <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Flex px='25px' justify='start' mt={'20px'} align='center' >
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card px='0px' py='0px' bg={'transparent'}>
            <Box pt={{ base: "80px", md: "80px", xl: "60px" }}>
                {isVisibleAlert && renderAlert()}
                {renderPropriedadeAndSangria()}
                {renderListaSafra()}
                {renderButton()}
            </Box>
            {loading && <Loader isOpen={loading} />}
        </Card>
    );
};
