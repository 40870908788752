import {
    Flex,
    Icon,
    Image,
    Text,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Select,
    Box,
    Center,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import moment from "moment";
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { MdPerson, MdPerson2 } from "react-icons/md";
import LoaderBlack from 'components/loading/LoaderBlack';
import { retornaHtmlLancamentoProducao } from 'components/geracaoHtml/GerarHtmlLancamentoProducao';
import ExcelIcon from 'assets/img/layout/excel_icon_download.png';
import PdfIcon from 'assets/img/layout/pdf_icon_download.png';

export default function LancamentoProducao() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [screenFullLoad, setScreenFullLoad] = useState(false);
    const [formInputsMeses, setFormInputsMeses] = useState([]);
    const [formInputsCompletoMeses, setFormInputsCompletoMeses] = useState([]);
    const [listaUaCombo, setListaCombo] = useState([]);
    const [idUaP, setIdUaP] = useState(null);
    const [idSafra, setIdSafra] = useState(null);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');

    const [totalGeralPrevisto, setTotalGeralPrevisto] = useState('0');
    const [totalGeralRealizado, setTotalGeralRealizado] = useState('0');
    const [totalGeralPercentual, setTotalGeralPercentual] = useState('0');
    const [totalPrevParcial, setTotalPrevParcial] = useState('0');
    const [totalRealParcial, setTotalRealParcial] = useState('0');
    const [totalPercentParcial, setTotalPercentParcial] = useState('0');
    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');

    const [idUf, setIdUf] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeUa, setNomeUa] = useState('');
    const [listDesMesAnoAndTotais, setListDesMesAnoAndTotais] = useState([]);
    const [larguraPrimeiraColuna, setLarguraPrimeiraColuna] = useState('70px');
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [nomeRelatorio, setNomeRelatorio] = useState('Lancamento-producao');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientNomeSeringueiro = 'linear-gradient(orange.700, orange.600, #cc6600)';
    const gradientNomeSeringueiroDeletado = 'linear-gradient(gray.700, gray.600, gray.500)';
    const gradientGreen = 'linear-gradient(green.700, green.500, lime)';
    const gradientOrange = 'linear-gradient(orange.500, orange.200, orange.100)';
    const gradientWhite = 'linear-gradient(white, white, white)';
    const gradientGray = 'linear-gradient(blackAlpha.300, blackAlpha.300, blackAlpha.300)';
    const gradientTransparent = 'linear-gradient(transparent, transparent, transparent)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
            const isAtiva = sangriaAtivaP == 'false' ? true : false;
            setSangriaAtiva(isAtiva);

            localStorage.removeItem('@HE-telaGerarPdfLancamentoProducao');
            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const nomeMunicipioP = localStorage.getItem('@HE-idNomeUfCadastradadoNaUa');
            const nomeUaP = localStorage.getItem('@HE-nomeUa');
            setIdUf(idUfP);
            setNomeMunicipio(nomeMunicipioP);
            setNomeUa(nomeUaP);

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const descData = `${nmMes}/${ano}`;
            setNomeMesCorrente(descData);
            const nmRelatorio = `Lancamento-producao-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');
            setDtInicioSafra(moment(ini).format('DD/MM/YYYY'));
            setDtFimSafra(moment(fim).format('DD/MM/YYYY'));

            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            setIdUaP(idUa);
            setIdSafra(idSafra);

            const url = `safraPessoa/listaSafraPessoaComProducaoPessoa?idUa=${idUa}&idSafra=${idSafra}&dtInicio=${ini}&dtFim=${fim}&incluirAlternanciaNaPesquisa=false`;
            console.log('+++', url);

            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta == ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                const dadosProducao = res;

                const ttAndTotais = res.listDesMesAnoAndTotais;
                setListDesMesAnoAndTotais(ttAndTotais);
                setTotalGeralPrevisto(dadosProducao.totalGeralPrevisto);
                setTotalGeralRealizado(dadosProducao.totalGeralRealizado);
                setTotalGeralPercentual(dadosProducao.totalGeralPercentual);

                setTotalPrevParcial(dadosProducao.totalPrevParcial);
                setTotalRealParcial(dadosProducao.totalRealParcial);
                setTotalPercentParcial(dadosProducao.totalPercentParcial);

                const dadosSeringueiro = res.listObjProducaoPessoa;
                if (dadosSeringueiro.length > 0) {
                    let arr = dadosSeringueiro.map((item) => {
                        return {
                            value: `${item.idPessoa}`,
                            label: item.nome,
                        }
                    })
                    setListaCombo(arr);
                }
                const ss = await carregaDadosMeses(res);
                setFormInputsMeses(ss);
                setFormInputsCompletoMeses(ss);
            }
            setScreenFullLoad(true);
        }
        loaderScreen();
    }, [])

    const carregaDadosMeses = async (res) => {
        const dadosSeringueiro = res.listObjProducaoPessoa;
        let ss = null;
        await new Promise(resolve => {
            resolve(
                ss = dadosSeringueiro.map(val => {
                    return {
                        key: `${val.idPessoa}`,
                        listMeses: val.listMeses,
                        idPessoa: `${val.idPessoa}`,
                        nome: val.nome,
                        deletado: val.deletado,
                        seringueiroIncluido: val.seringueiroIncluido,
                        nomeMesDeletado: val.nomeMesDeletado,
                        nomeMesIncluido: val.nomeMesIncluido,
                        idSafraPessoa: val.idSafraPessoa,
                        totalPrevisto: val.totalPrevisto != 0 && val.totalPrevisto != null ? `${val.totalPrevisto}` : '0',
                        totalRealizado: val.totalRealizado != 0 && val.totalRealizado != null ? `${val.totalRealizado}` : '0',
                        totalPercentual: val.totalPercentual != 0 && val.totalPercentual != null ? `${val.totalPercentual}` : '0',
                        totalParcialPrevisto: val.totalParcialPrevisto != 0 && val.totalParcialPrevisto != null ? `${val.totalParcialPrevisto}` : '0',
                        totalParcialRealizado: val.totalParcialRealizado != 0 && val.totalParcialRealizado != null ? `${val.totalParcialRealizado}` : '0',
                        totalParcialPercentual: val.totalParcialPercentual != 0 && val.totalParcialPercentual != null ? `${val.totalParcialPercentual}` : '0',
                    }
                })
            )
        });

        let qtdCar = 0;     // ESSE LANCE AQUI E DA HORA KKK... PEGO O MAIOR NOME PARA AJUSTAR A LARGURA DA PRIMEIRA COLUNA DA TABELA
        ss.map(x => x.nome.length > qtdCar ? qtdCar = x.nome.length : qtdCar = qtdCar);
        if (qtdCar > 10) {
            let xx = 0;
            xx = qtdCar < 20 ? qtdCar * 7 : 140;
            setLarguraPrimeiraColuna(`${xx}px`);
        }
        return ss;
    }

    const lancarValoresSeringueiro = (seringueiroP, dadosMes) => {
        const json = {
            idUa: idUaP,
            idSafra: idSafra,
            idPessoa: seringueiroP.idPessoa,
            idSafraPessoa: seringueiroP.idSafraPessoa,
            descMes: dadosMes.descMes,
            numMes: dadosMes.numMes,
            numAno: dadosMes.numAno,
        }

        localStorage.setItem('@HE-dadosTelaLancamentoProducao', JSON.stringify(json));
        history.push("/admin/lancar-valores-producao");
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await gerarExcel();
            setLoading(false);
        }, 1000);
    }

    const gerarExcel = async () => {
        const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
        const fim = localStorage.getItem('@HE-dtFimSafraAsync');
        const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
        const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');
        const ua = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const nomeUserLogado = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');

        const json = {
            idUa: ua,
            idSafra: idSafra,
            dtInicio: dtInicioSafra,
            dtFim: dtFimSafra,
            nomeUsuario: nomeUserLogado,
            incluirAlternanciaNaPesquisa: true,
        }
        const url = `safraPessoa/downloadRelatorioExcelLancamentoProducao`;
        await Api.postRequestDownloadExcel(url, json, 'Lancamento-Producao');
    }

    async function downloadPdf() {
        setLoading(true);
        const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
        const fim = localStorage.getItem('@HE-dtFimSafraAsync');

        const url = `safraPessoa/listaSafraPessoaComProducaoPessoa?idUa=${idUaP}&idSafra=${idSafra}&dtInicio=${ini}&dtFim=${fim}&incluirAlternanciaNaPesquisa=true`;
        const response = await Api.getRequest(url);

        if (response.numeroStatusResposta == ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;

            const formInputsMesesP = await carregaDadosMeses(res);
            const objItens = {
                tituloRelatorio: 'Lançamento Produção',
                formInputsMeses: formInputsMesesP,
                nomeMesCorrente: nomeMesCorrente,
                nomeUa: nomeUa,
                nomeMunicipio: nomeMunicipio,
                idUf: idUf,
                totalGeralPrevisto: totalGeralPrevisto,
                totalGeralRealizado: totalGeralRealizado,
                totalGeralPercentual: totalGeralPercentual,
                totalPrevParcial: totalPrevParcial,
                totalRealParcial: totalRealParcial,
                totalPercentParcial: totalPercentParcial,
            }

            const html = await retornaHtmlLancamentoProducao(objItens);
            const json = {
                htmlContent: html,
            }
            const url = `pdfRelatorio/converterHtmlParaPdf`;
            await Api.postRequestDownloadPdf(url, json, nomeRelatorio);

            setLoading(false);
        } else {
            setLoading(false);
            console.log('');
        }
    }

    const carregarDadosPessoaSelecionada = (e) => {
        const val = e.target.value;
        setPessoaSelecionado(val);
        if (val != '') {
            const num = Number(val);
            const ss = formInputsCompletoMeses.filter(x => x.idPessoa == num);
            setFormInputsMeses(ss);
        } else {
            setFormInputsMeses(formInputsCompletoMeses);
        }
    };

    function renderSelect() {
        return (
            <Box>
                <Flex px={'20px'} mb={'10px'} justify='start' align='center'>
                    <Flex justify='start' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight={'100%'}>{`Início Safra:`}</Text>
                        <Text color={'orange.600'} fontSize='14px' fontWeight='bold' lineHeight={'100%'} ml={'10px'}>{dtInicioSafra}</Text>
                    </Flex>
                    <Flex justify='start' align='center' ml={'20px'}>
                        <Text color={textColor} fontSize='15px' fontWeight='bold'>{`Fim Safra:`}</Text>
                        <Text color={'orange.600'} fontSize='14px' fontWeight='bold' ml={'15px'}>{dtFimSafra}</Text>
                    </Flex>
                </Flex>
                <Flex px='5px' justify='space-between' align='center'>
                    <Flex px='5px' justify='start' align='center'>
                        <Box h='100%'>
                            <Select
                                value={pessoaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="todos"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={carregarDadosPessoaSelecionada}
                                w={'300px'}
                                h={'35px'}
                            >
                                {listaUaCombo.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', color: 'black' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box>
                            <Center h={'100%'} p={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                <TooltipIcon
                                    label={
                                        <Box px={'0px'} py={'5px'} >
                                            <Text align={'center'}>{'Lista de Seringueiros'}</Text>
                                            <Text mt={'5px'} lineHeight='100%' align={'start'}>{'Você pode filtrar selecionando um seringueiro específico'}</Text>
                                            <Text mt={'10px'} lineHeight='100%' align={'start'}>{'ou'}</Text>
                                            <Text mt={'10px'} lineHeight='100%' align={'start'}>{'Selecione Totos, para lista completa'}</Text>
                                        </Box>
                                    }
                                    height={'23px'} />
                            </Center>
                        </Box>
                    </Flex>
                    <Flex pr={'10px'} justify='flex-end' align='center'>
                        <Image boxSize='35px' objectFit='contain' src={ExcelIcon} alt='Dan Abramov' cursor={'pointer'} onClick={gerarRelatorioExcel} />
                        <Image boxSize='35px' objectFit='contain' src={PdfIcon} alt='Dan Abramov' cursor={'pointer'} ml={'15px'} onClick={downloadPdf} />
                    </Flex>
                </Flex>
                <Box px={'10px'} mt={'10px'}>
                    <Flex justify='start' align='center'>
                        <Icon as={MdPerson2} width='20px' height='20px' color='gray.700' />
                        <Text color={'gray.700'} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Seringueiros desativados'}</Text>
                    </Flex>
                    <Flex justify='start' align='center'>
                        <Icon as={MdPerson2} width='20px' height='20px' color='green.500' />
                        <Text color={'green.500'} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Seringueiros incluidos'}</Text>
                    </Flex>
                    <Text mt={'15px'} color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{'Os campos editáveis estão entre as datas de início e fim da safra'}</Text>
                </Box>
            </Box>
        )
    }

    function renderLancamentoMeses() {
        let corLinha = false;
        const larg = '100%';
        const largCo = '110px';
        const largTr = 0;
        const altura = '40px';
        const corBorder = 'blackAlpha.600';
        return (
            <Box padding={2} position={'relative'}>
                <Box overflowY="hidden" whiteSpace={'nowrap'} overflowX={'auto'} maxWidth={'100%'}>
                    <Box opacity={sangriaAtiva ? '0.6' : '1'} pointerEvents={sangriaAtiva ? 'none' : 'auto'}>
                        <Table size="sm" variant="unstyled">
                            <Thead px={'0px'} bg={'blackAlpha.500'}>
                                <Tr bgGradient={gradientHeader}>
                                    <Th sx={{ paddingX: largTr }}>
                                        <Box>
                                            <Text color={textColorHeader} fontSize='14px' fontWeight='700' textTransform={'none'}></Text>
                                        </Box>
                                    </Th>
                                    {listDesMesAnoAndTotais.map((item, index) => {
                                        return (
                                            <Th key={index} sx={{ paddingX: largTr }}>
                                                <Box w={largCo}>
                                                    <Text color={textColorHeader} fontSize='13px' fontWeight='bold' textTransform={'none'} align={'center'}>{item.descMesAno}</Text>
                                                    <Flex justify='space-around' align='center' >
                                                        <Text color={textColorHeader} fontSize='11px' fontWeight='700' textTransform={'none'}>Prev</Text>
                                                        <Text color={textColorHeader} fontSize='11px' fontWeight='700' textTransform={'none'}>Real</Text>
                                                    </Flex>
                                                </Box>
                                            </Th>
                                        )
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {formInputsMeses.map((input, key) => {
                                    corLinha = !corLinha;
                                    const bgLinha = corLinha ? gradientGray : gradientWhite;
                                    return (
                                        <Tr key={key + 1} h={altura} bgGradient={bgLinha} borderBottomWidth={'0px'} borderColor={'blackAlpha.400'}>
                                            <Td sx={{ paddingX: largTr }}>
                                                <Box w={larguraPrimeiraColuna}>
                                                    <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight={'100%'}>{''}</Text>
                                                </Box>
                                            </Td>
                                            {input.listMeses.map((item, ww) => {
                                                const valorParcial = item.valorParcial != null ? true : false;
                                                const valorAnual = item.valorAnual != null ? true : false;
                                                const deletado = item.deletado;
                                                let valRealizado = item.valorRealizado != '0' ? item.valorRealizado : '';
                                                let desabilitarBotao = false;
                                                let bgButton = 'white';

                                                const gradientez = valorParcial || valorAnual ? gradientOrange : gradientTransparent;
                                                if (deletado || valorParcial || valorAnual) {
                                                    desabilitarBotao = true;
                                                    bgButton = 'transparent';
                                                } else {
                                                    const mesValido = item.dataMesEstaEntreInicioAndFimSafra == true ? false : true;
                                                    if (mesValido) {
                                                        desabilitarBotao = true;
                                                        bgButton = 'transparent';
                                                    } else {
                                                        desabilitarBotao = false;
                                                        bgButton = 'gray.400';
                                                    }
                                                }
                                                if (input.seringueiroIncluido) {
                                                    if (item.seringueiroIncluido == false) {                                                        
                                                        desabilitarBotao = true;
                                                        bgButton = 'transparent';
                                                    }
                                                }
                                                return (
                                                    <Td key={ww} sx={{ paddingX: largTr }} bgGradient={gradientez} borderRightWidth={'0px'} borderLeftWidth={'1px'} borderBottomWidth={'0px'} borderColor={corBorder}>
                                                        <Flex justify='space-around' align='center'>
                                                            <Box w={larg}>
                                                                <Text color={textColor} fontSize='12px' fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.valorPrevisto}`}</Text>
                                                            </Box>
                                                            <Box w={larg} display={'flex'} justifyContent={'center'}>
                                                                {desabilitarBotao == true ?
                                                                    <Text color={'black'} fontSize='12px' fontWeight='bold' align={'center'} lineHeight={'100%'}>{`${valRealizado}`}</Text>
                                                                    :
                                                                    <Button
                                                                        variant="solid"
                                                                        bg={bgButton}
                                                                        w={'45px'}
                                                                        h={'22px'}
                                                                        disabled={desabilitarBotao}
                                                                        onClick={() => { lancarValoresSeringueiro(input, item) }}>
                                                                        <Text color={'black'} fontSize='12px' fontWeight='bold' align={'center'} lineHeight={'100%'}>{`${valRealizado}`}</Text>
                                                                    </Button>
                                                                }
                                                            </Box>
                                                        </Flex>
                                                    </Td>
                                                )
                                            })}
                                        </Tr>
                                    )
                                })}
                                <Tr bgGradient={gradientHeader}>
                                    <Td sx={{ paddingX: largTr }}>
                                        <Text color={textColorHeader} fontSize='12px' fontWeight='700' align={'center'}>Total</Text>
                                    </Td>
                                    {listDesMesAnoAndTotais.map((item, index) => {
                                        return (
                                            <Td key={index} sx={{ paddingX: largTr }}>
                                                <Flex justify='center' align='center'>
                                                    <Box w={larg}>
                                                        <Text color={textColorHeader} fontSize='12px' fontWeight='700' align={'center'}>{item.totalGeralPrevistoMes}</Text>
                                                    </Box>
                                                    <Box w={larg}>
                                                        <Text color={textColorHeader} fontSize='12px' fontWeight='700' align={'center'}>{item.totalGeralRealizadoMes}</Text>
                                                    </Box>
                                                </Flex>
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Box pos="absolute" left={2} top={2} zIndex={2}>
                        <Box opacity={sangriaAtiva ? '0.6' : '1'}>
                            <Box h={'40px'} px='0px' ml={'0px'} bgGradient={gradientHeader}>
                                <Center h={'100%'}>
                                    <Icon as={MdPerson} width='20px' height='20px' color='white' />
                                </Center>
                            </Box>
                            {formInputsMeses.map((item, index) => {
                                const deletado = item.deletado;
                                const incluido = item.seringueiroIncluido;

                                let gbGradiente = gradientNomeSeringueiro;
                                if (deletado) {
                                    gbGradiente = gradientNomeSeringueiroDeletado;
                                } else if (incluido) {
                                    gbGradiente = gradientGreen;
                                } else {
                                    gbGradiente = gradientNomeSeringueiro;
                                }
                                let nomePs = item.nome;
                                nomePs = nomePs.toLowerCase();
                                return (
                                    <Flex
                                        key={index}
                                        h={altura}
                                        flexDirection={'column'}
                                        align='start'
                                        bgGradient={gbGradiente}
                                    >
                                        <Box position={'relative'} h={'37px'} w={larguraPrimeiraColuna} px='2px' >
                                            <Center h={'100%'} justifyContent={'start'} pt={'10px'} ml={'5px'}>
                                                <Text textTransform="capitalize" color={'white'} fontSize='13px' fontWeight='bold' lineHeight={'100%'} noOfLines={2}>{nomePs}</Text>
                                            </Center>
                                            {incluido &&
                                                <Box pos="absolute" left={'1px'} top={'-2px'} zIndex={10}>
                                                    <TooltipIcon
                                                        label={
                                                            <Box px={'5px'} py={'10px'} >
                                                                <Text align={'center'}>{'Incluido'}</Text>
                                                                <Text mt={'5px'} lineHeight='100%' align={'start'}>{`Seringueiro admitido ao seringal em ${item.nomeMesIncluido}`}</Text>
                                                            </Box>
                                                        }
                                                        height={'12px'} color={'lime'} />
                                                </Box>
                                            }
                                            {deletado &&
                                                <Box pos="absolute" left={'1px'} top={'-2px'} zIndex={10}>
                                                    <TooltipIcon
                                                        label={
                                                            <Box px={'5px'} py={'10px'} >
                                                                <Text align={'center'}>{'Desativado'}</Text>
                                                                <Text mt={'5px'} lineHeight='100%' align={'start'}>{`Seringueiro desativado em ${item.nomeMesDeletado}`}</Text>
                                                            </Box>
                                                        }
                                                        height={'12px'} color={'gray.200'} />
                                                </Box>
                                            }
                                        </Box>
                                    </Flex>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    if (!screenFullLoad) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
            <Card px='0px' py={'10px'} >
                {renderSelect()}
                {renderLancamentoMeses()}
                <LoaderBlack isOpen={loading} subTitulo={'Dependendo da quantidade de registros, esse processo pode levar mais de um minuto'} />
            </Card>
        </Box>
    );
};
