import React, { useState, useEffect, useContext } from 'react';
import {
    Avatar,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    AvatarBadge,
    Box,
} from '@chakra-ui/react';
//import { ItemContent } from 'components/menu/ItemContent';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import navImage from '../../assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
//import { ThemeEditor } from './ThemeEditor';
import { useHistory } from "react-router-dom";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { UtilContext } from 'contexts/UtilContext';
import * as ActionTypes from '../../constants/ActionTypes';
import { LogoutUsuario } from '../../util/ValidarAutenticacao';

const IMG = `${ActionTypes.LINK_GOOGLE_STORAGE}/book_anotation.png`;
const IMG_CHECK = `${ActionTypes.LINK_GOOGLE_STORAGE}/zero_notification.png`;

// TODO CLASSE DE CORES
export default function HeaderLinks(props) {

    const { secondary } = props;
    const { atualisarNotificacao, isUsuarioAutenticado } = useContext(UtilContext);

    const history = useHistory();
    const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');
    const [nomeCompletoUsuarioLogado, setNomeCompletoUsuarioLogado] = useState('');
    const [showBadge, setShowBadge] = useState(false);
    const [objPermissoes, setObjPermissoes] = useState({});

    const navbarIcon22 = useColorModeValue('#b3b3b3', '#FFFFFF');
    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const ethColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const ethBox = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.18)', '14px 17px 40px 4px rgba(112, 144, 176, 0.06)');
    const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
    const boxBg = useColorModeValue("white", "whiteAlpha.100");

    useEffect(() => {
        async function loadScreen() {
            setShowBadge(atualisarNotificacao);
        }
        loadScreen();
    }, [atualisarNotificacao]);

    useEffect(() => {
        async function loadScreen() {

            const nomeCompleto = localStorage.getItem('@HE-nomeCompletoUsuarioLogado');
            setNomeCompletoUsuarioLogado(nomeCompleto);
            const primeiroNomeUsuarioLogado = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');
            setNomeUsuarioLogado(primeiroNomeUsuarioLogado);

            const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
            const obj = JSON.parse(val);
            setObjPermissoes(obj);
        }
        loadScreen();
    }, []);

    const logout = () => {
        LogoutUsuario();
        isUsuarioAutenticado(false);
        history.push("/auth/sign-in");
    }

    const acessarNotificacoes = () => {
        history.push("/admin/profile");
    }

    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
            px="5px"
            borderRadius="30px"
            boxShadow={shadow}>
            {/* <SearchBar mb={secondary ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="30px" /> */}
            <Flex
                bg={ethBg}
                display={secondary ? 'flex' : 'none'}
                borderRadius="30px"
                ms="auto"
                p="6px"
                align="center"
                me="6px">
                <Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
                    <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
                </Flex>
                <Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
                    1,924
                    <Text as="span" display={{ base: 'none', md: 'unset' }}>{' '}ETH</Text>
                </Text>
            </Flex>
            <SidebarResponsive routes={routes} />

            {objPermissoes.gerente &&
                <Menu>
                    <MenuButton p="0px">
                        <Avatar bg='transparent' w="30px" h="30px" me="12px" icon={<MdNotificationsNone fontSize={'1.35em'} color={navbarIcon22} />} >
                            {showBadge && <AvatarBadge borderColor='papayawhip' bg='tomato' boxSize='0.9em' />}
                        </Avatar>
                    </MenuButton>
                    <MenuList
                        boxShadow={'dark-lg'}
                        p="20px"
                        borderRadius="20px"
                        bg={menuBg}
                        border="none"
                        mt="22px"
                        me={{ base: '30px', md: 'unset' }}
                        minW={{ base: 'unset', md: '400px', xl: '450px' }}
                        maxW={{ base: '360px', md: 'unset' }}>
                        <Flex jusitfy="space-between" w="100%" mb="20px" px={'2'} boxShadow={'md'}>
                            <Box w={'100%'}>
                                <Text fontSize="md" fontWeight="600" color={textColor}>Notificações</Text>
                            </Box>
                            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} w={'100%'} cursor={'pointer'}>
                                <Text fontSize="md" fontWeight="bold" color={textColor} ms="auto" mr={'5px'} >Ver Todas</Text>
                                <Avatar w="30px" h="30px" src={`${ActionTypes.LINK_GOOGLE_STORAGE}/arrow_notification.png`} bg={boxBg} resize={'initial'} onClick={() => { acessarNotificacoes() }} />
                            </Box>
                        </Flex>

                        {atualisarNotificacao ?

                            <Flex flexDirection="column">
                                <MenuItem onClick={() => { acessarNotificacoes() }} _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
                                    <Flex
                                        justify='center'
                                        align='center'
                                        borderRadius='16px'
                                        minH={{ base: "50px", md: "60px" }}
                                        h={{ base: "50px", md: "60px" }}
                                        minW={{ base: "50px", md: "60px" }}
                                        w={{ base: "50px", md: "60px" }}
                                        me='10px'>
                                        <Image h='60px' w='60px' ml={'2'} src={IMG} borderRadius='10px' me='20px' />
                                    </Flex>
                                    <Flex flexDirection='column'>
                                        <Text mb='5px' fontWeight='bold' color={textColor} fontSize={{ base: "md", md: "md" }}>Calendário Estimulação</Text>
                                        <Flex alignItems='center'>
                                            <Text fontSize={{ base: "sm", md: "sm" }} lineHeight='100%' color={textColor}>Realize o lançamento no calendário de estimulação!</Text>
                                        </Flex>
                                    </Flex>
                                </MenuItem>
                            </Flex>
                            :
                            <Flex flexDirection="column">
                                <MenuItem cursor={'auto'} _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
                                    <Flex
                                        justify='center'
                                        align='center'
                                        borderRadius='16px'
                                        minH={{ base: "50px", md: "60px" }}
                                        h={{ base: "50px", md: "60px" }}
                                        minW={{ base: "50px", md: "60px" }}
                                        w={{ base: "50px", md: "60px" }}
                                        me='10px'>
                                        <Image h='60px' w='60px' ml={'2'} src={IMG_CHECK} borderRadius='10px' me='20px' />
                                    </Flex>
                                    <Flex flexDirection='column'>
                                        <Text mb='5px' fontWeight='bold' color={textColor} fontSize={{ base: "md", md: "md" }}>Não há notificações</Text>
                                        <Flex alignItems='center'>
                                            <Text fontSize={{ base: "sm", md: "sm" }} lineHeight='100%' color={textColor}></Text>
                                        </Flex>
                                    </Flex>
                                </MenuItem>
                            </Flex>
                        }
                    </MenuList >
                </Menu >
            }
            {/* <ItemContent info="Calendário Estimulação" aName="Alicia" /> */}
            <Menu>
                <MenuButton p="0px">
                    <Icon mt="6px" as={MdInfoOutline} color={navbarIcon} w="20px" h="20px" me="15px" />
                </MenuButton>
                <MenuList
                    boxShadow={'dark-lg'}
                    //boxShadow={shadow}
                    p="20px"
                    me={{ base: '30px', md: 'unset' }}
                    borderRadius="20px"
                    bg={menuBg}
                    border="none"
                    mt="22px"
                    minW={{ base: 'unset' }}
                    maxW={{ base: '360px', md: 'unset' }}>
                    <Image src={navImage} borderRadius="16px" mb="28px" />
                    <Flex flexDirection="column">

                        {/* <Link w="100%" href="#">
                            <Button w="100%" h="44px" mb="10px" variant="brand">HEVEA</Button>
                        </Link> */}

                        <Link w="100%" 
                            href={ActionTypes.URL_PLAY} 
                            isExternal>
                            <Button
                                w="100%"
                                h="44px"
                                mb="10px"
                                border="1px solid"
                                bg="transparent"
                                borderColor={borderButton}>
                                <Icon as={FaGooglePlay} w='20px' h='20px' me='10px' />Acesse a Google Play
                            </Button>
                        </Link>
                        <Link w="100%" 
                            href={ActionTypes.URL_APPLE} 
                            isExternal>
                            <Button
                                w="100%"
                                h="44px"
                                mb="10px"
                                border="1px solid"
                                bg="transparent"
                                borderColor={borderButton}>
                                <Icon as={FaApple} w='20px' h='20px' me='10px' />Acesse a App Store
                            </Button>
                        </Link>

                        {/* <Link w="100%" href="#">
                            <Button w="100%" h="44px" variant="no-hover" color={textColor} bg="transparent">
                                Experimente o Sistema Gratuitamente
                            </Button>
                        </Link> */}

                    </Flex>
                </MenuList>
            </Menu>

            {/* TODO ICONE PARA EDITAR CORES */}
            {/* <ThemeEditor navbarIcon={navbarIcon} /> */}

            <Menu>
                <MenuButton p="0px">
                    <Avatar
                        _hover={{ cursor: 'pointer' }}
                        color="white"
                        name={nomeCompletoUsuarioLogado}
                        bg="#FF8C00"
                        size="sm"
                        w="30px"
                        h="30px"
                    />
                </MenuButton>
                <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
                    <Flex w="100%" mb="0px">
                        <Text
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="sm"
                            fontWeight="700"
                            color={textColor}>
                            {`Olá ${nomeUsuarioLogado}!`}
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" p="10px">
                        <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                            <Text fontSize="sm">Configurações de perfil</Text>
                        </MenuItem>
                        <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                            <Text fontSize="sm">Configurações informativas</Text>
                        </MenuItem>
                        <MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            color="red.400"
                            borderRadius="8px"
                            onClick={logout}
                            px="14px">
                            <Text fontSize="sm">Sair</Text>
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex >
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func
};
