import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Select,
    SimpleGrid,
    Box,
    Button,
    Center,
    Icon,
    Image,
    Stat,
    StatNumber,
    StatLabel,
    Link,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import LoaderBlack from 'components/loading/LoaderBlack';
import { IoArrowRedo, IoDownloadSharp } from "react-icons/io5";
import { calculoGeralRelatorio, carregarArvoresParaUmaSangria } from '../../../../util/CalculosRelatorioQualidadeSangria';
import { calculaTotalDePontosArvoreSemDivisao, retornaNomeDoMes, ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO } from '../../../../util/FuncoesUteis';
import * as ActionTypes from '../../../../constants/ActionTypes';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import PdfIconDownload from 'assets/img/layout/pdf_icon_download.png';
import { retornaHtmlListaQualidadeSangria } from 'components/geracaoHtml/GerarHtmlListaQualidadeSangria';

export default function ListQualidadeSangria() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [arrayQualidadeCompleto, setArrayQualidadeCompleto] = useState([]);

    const [mediaGeralDePontos, setMediaGeralDePontos] = useState(0);
    const [notaGeral, setNotaGeral] = useState(0);
    const [avaliacaoGeral, setAvaliacaoGeral] = useState('');
    const [corNotaGeral, setCorNotaGeral] = useState('');
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [loading, setLoading] = useState(false);
    const [nomeRelatorio, setNomeRelatorio] = useState('Qualidade-sangria');
    const [nomeUa, setNomeUa] = useState('');
    const [nomeMesSelecionado, setNomeMesSelecionado] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');
    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [listUrlArquivoRelatorio, setListUrlArquivoRelatorio] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorInfo = useColorModeValue("gray.500", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            localStorage.removeItem('@HE-propriedadeParaEdicao');

            const startDate = localStorage.getItem('@HE-dtInicioSafraAsync');
            const endDate = localStorage.getItem('@HE-dtFimSafraAsync');
            const arrayDatas = await ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(startDate, endDate);
            setArrayMesInicio(arrayDatas);

            const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Qualidade-sangria-${dia}-${nmMes}-${ano}`;
            const nmUa = localStorage.getItem('@HE-nomeUa');
            setNomeRelatorio(nmRelatorio);
            setAnoCorrente(ano);
            setNomeMesSelecionado(nmMes);
            setNomeMunicipio(nomeMunicipioP);
            setIdUf(idUfP);
            setNomeUa(nmUa);

            const primeiroDiaDoMesCorrente = moment().startOf('month').format('YYYY-MM-DD');
            setMesSelecionado(primeiroDiaDoMesCorrente);

            const dtAtual = moment().format('YYYY-MM-DD');
            await pesquisaQualidadeSan(dtAtual);
            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const pesquisaQualidadeSan = async (dataPesquisa) => {
        try {
            const dt = moment(dataPesquisa).format('YYYY-MM-DD');
            const primeiroDiaDoMesCorrente = moment(dt).startOf('month').format('YYYY-MM-DD');
            const UltimoDiaDoMesCorrente = moment(dt).endOf('month').format("YYYY-MM-DD");
            let pontosGeral = 0;
            let notaGeral = 0;
            let qtdDivisao = 0;
            let ultimoId = 0;

            setArrayQualidadeCompleto([]);

            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const url = `api/qualidadeSangria/relatorioQualidadeSangriaAndEsporadico?idUa=${ua}&idSafra=${idSafra}&dtInicio=${primeiroDiaDoMesCorrente}&dtFim=${UltimoDiaDoMesCorrente}`;
            const response = await Api.getAllSemAccessToken(url);
            
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                const listQualidadeSangriaList = res.listQualidadeSangriaList;
                const listUrlArquivoRelatorioP = res.listUrlArquivoRelatorio;
                setListUrlArquivoRelatorio(listUrlArquivoRelatorioP);

                let arrTemp = [];
                if (listQualidadeSangriaList.length > 0) {
                    /*
                    ╔══════════════════════════════════════════════════════════════════╗
                        PRECISEI ORDENAR DO MENOR PARA O MAIOR PARA TRABALHAR NA LOGINA
                        MAIS ABAIXO
                    ╚══════════════════════════════════════════════════════════════════╝ */
                    const arrayOrdenado = await listQualidadeSangriaList.sort(function (a, b) { return a.idQualidadeSangria - b.idQualidadeSangria });
                    ultimoId = arrayOrdenado[arrayOrdenado.length - 1].idQualidadeSangria;

                    await arrayOrdenado.forEach(async (res2) => {
                        const jsonDadosSeringueiro = {
                            idPessoa: res2.idPessoa,
                            nome: res2.nome,
                        }
                        /*
                        ╔══════════════════════════════════════════════════════════════════╗
                            CARREGAR AS ARVORES
                        ╚══════════════════════════════════════════════════════════════════╝ */
                        const arrayArvoresParaTela = await carregarArvoresParaUmaSangria(res2, jsonDadosSeringueiro);
                        let urlImagEvidencia = null;
                        if (res2.urlArquivo != null) {
                            const urlArq = res2.urlArquivo;
                            const imgP = urlArq.urlStorage;
                            urlImagEvidencia = imgP;
                        }

                        let totalDePontosP = 0;
                        const arr = res2.listArvore;

                        if (arr.length > 0) {
                            totalDePontosP = await calculaTotalDePontosArvoreSemDivisao(arr);
                        }
                        /*
                        ╔══════════════════════════════════════════════════════════════════╗
                            CALCULANDO VALORES E CORES DOS CARDS
                        ╚══════════════════════════════════════════════════════════════════╝ */
                        totalDePontosP = totalDePontosP.toFixed(2);
                        totalDePontosP = Number(totalDePontosP);

                        const pontosSangria = Number(res2.pontos);
                        let notaSeringueiroP = pontosSangria - totalDePontosP;
                        notaSeringueiroP = notaSeringueiroP.toFixed(2);
                        notaSeringueiroP = Number(notaSeringueiroP);

                        const idAvaliacao = res2.idAvaliacao != null && res2.idAvaliacao != undefined ? res2.idAvaliacao : 1;

                        let avalia = '';
                        let corN = 'black';     // NAO COLOCAR NUMEROS NAS CORES TIPO green.500, POR CAUSA DO HTML
                        if (idAvaliacao == 1) {
                            corN = 'gray';
                            avalia = 'Branco';
                        } else if (idAvaliacao == 2) {
                            corN = 'green';
                            avalia = 'Boa';
                        } else if (idAvaliacao == 3) {
                            corN = 'orange';
                            avalia = 'Média';
                        } else if (idAvaliacao == 4) {
                            corN = 'red';
                            avalia = 'Ruim';
                        }

                        if (totalDePontosP > 0) {
                            pontosGeral = pontosGeral + totalDePontosP;
                            notaGeral = notaGeral + notaSeringueiroP;
                            qtdDivisao = qtdDivisao + 1;
                        }

                        const ss = parseFloat(notaSeringueiroP.toFixed(2));
                        const xx = parseFloat(totalDePontosP.toFixed(2));

                        const jsonF = {
                            idQualidadeSangria: res2.idQualidadeSangria,
                            idPessoa: res2.idPessoa,
                            nome: res2.nome,
                            observacao: res2.observacao != null ? res2.observacao : '',
                            descMes: res2.descMes,
                            avaliacaoFinalizada: res2.avaliacaoFinalizada,
                            dtPeriodoDisponivel: res2.dtPeriodoDisponivel,
                            listArvore: arrayArvoresParaTela,
                            notaSeringueiro: ss,
                            totalPontos: xx,
                            avaliacaoSangria: avalia,
                            corNota: corN,
                            urlImage: urlImagEvidencia,
                            deletado: res2.deletado,
                            idAvaliacao: idAvaliacao,
                        }

                        arrTemp.push(jsonF);

                        if (ultimoId == res2.idQualidadeSangria) {
                            const resCalculo = await calculoGeralRelatorio(pontosGeral, notaGeral, qtdDivisao);
                            setMediaGeralDePontos(resCalculo.mediaGeralDePontos);
                            setNotaGeral(resCalculo.notaGeral);
                            setAvaliacaoGeral(resCalculo.avaliacaoGeral);
                            setCorNotaGeral(resCalculo.corNotaGeral);
                            
                            setArrayQualidadeCompleto(arrTemp);
                        }
                    })
                } else {
                    setMediaGeralDePontos(0);
                    setNotaGeral(0);
                    setAvaliacaoGeral('N/A');
                    setCorNotaGeral('black');
                }
            } else {
                console.log('a');
                return;
            }
        } catch (error) {
            console.log('Erro na classe ListQualidadeSangria metodo pesquisaQualidadeSan', error);
        }
    }

    const realizarPesquisaDoMesSelecionado = async (e) => {

        const dataSelecionada = e.target.value;
        const nmMes = await retornaNomeDoMes(moment(dataSelecionada).format('YYYY-MM-DD'));
        setNomeMesSelecionado(nmMes);

        const startTime = moment(dataSelecionada);
        const end = moment();
        const duration = moment.duration(startTime.diff(end));
        const dias = duration.asMonths();
        const no2 = parseFloat(dias.toFixed(0));

        if (no2 <= 0) {
            setMesSelecionado(dataSelecionada);
            const dtc = moment(dataSelecionada).format('YYYY-MM-DD');

            await pesquisaQualidadeSan(dtc);
        } else {

            return;
        }
    }

    const detalhesQualidadeSangria = async (item) => {
        localStorage.setItem('@HE-dadosParaRelatorioQualidadeSangria', JSON.stringify(item));
        history.push("/admin/relatorio-qualidade-sangria");
    }

    async function gerarPDF() {
        setLoading(true);
        const objItens = {
            nomeUa: nomeUa,
            nomeMunicipio: nomeMunicipio,
            idUf: idUf,
            anoCorrente: anoCorrente,
            avaliacaoGeral: avaliacaoGeral,
            corNotaGeral: corNotaGeral,
            notaGeral: notaGeral,
            mediaGeralDePontos: mediaGeralDePontos,
            arrayQualidadeCompleto: arrayQualidadeCompleto,
            listUrlArquivoRelatorio: listUrlArquivoRelatorio,
        }
        const html = await retornaHtmlListaQualidadeSangria(objItens);
        const json = {
            htmlContent: html,
        }
        const url = `pdfRelatorio/converterHtmlParaPdf`;
        await Api.postRequestDownloadPdf(url, json, nomeRelatorio);
        setLoading(false);
    }

    function renderSelect() {
        return (
            <Box mt={'-10px'}>
                <Flex px='20px' justify='space-between' align='center'>
                    <Box>
                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' ml={'10px'} >Meses</Text>
                        <Select
                            value={mesSelecionado}
                            errorBorderColor='crimson'
                            placeholder="selecione"
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={realizarPesquisaDoMesSelecionado}
                            w={'400px'}
                            h={'35px'}
                        >
                            {arrayMesInicio.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                    <Image boxSize='35px' objectFit='contain' src={PdfIconDownload} alt='Dan Abramov' cursor={'pointer'} onClick={gerarPDF} />
                </Flex>
            </Box>
        )
    }

    function renderCardsQualidade() {
        let mediaG = `${mediaGeralDePontos}`;
        mediaG = mediaG.length == 1 ? `${mediaG}.0` : mediaG;
        mediaG = mediaG.replace('.', ',');
        let notaG = `${notaGeral}`;
        notaG = notaG.length == 1 ? `${notaG}.0` : notaG;
        notaG = notaG.replace('.', ',');
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'} px={'3'}>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Total de pontos'}</StatLabel>
                            <StatNumber color={textColor} fontSize={{ base: "xl", }}>{mediaG}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Nota dos seringueiro'}</StatLabel>
                            <StatNumber color={corNotaGeral} fontSize={{ base: "xl", }}>{`${notaG}%`}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Avaliação da sangria'}</StatLabel>
                            <StatNumber color={corNotaGeral} fontSize={{ base: "xl", }}>{avaliacaoGeral}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
            </SimpleGrid>
        )
    }

    function renderSeringueiros() {
        return (
            <Card px='10px' py='0px'>
                {arrayQualidadeCompleto.map((item, index) => {
                    let pontosS = `${item.totalPontos}`;
                    pontosS = pontosS.length == 1 ? `${pontosS}.0` : pontosS;
                    pontosS = pontosS.replace('.', ',');
                    let notaS = `${item.notaSeringueiro}`;
                    notaS = notaS.length == 1 ? `${notaS}.0` : notaS;
                    notaS = notaS.replace('.', ',');
                    const deletado = item.deletado != null ? item.deletado : false;
                    return (
                        <Box
                            key={index}
                            position={'relative'}
                            borderWidth={'2px'}
                            borderRadius='20px'
                            p={'1px'}
                            bg={'white'}
                            mt={'6px'}
                            mb={'5px'}
                            borderColor={'blackAlpha.400'}>
                            {deletado &&
                                <Flex w={'90px'} justify='center' py={'5px'} align='center' bg={'transparent'} borderRadius={'10px'} ml={'15px'} mt={'5px'} mb={'-10px'}>
                                    <Text color={'red'} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Desativado</Text>
                                </Flex>
                            }
                            <Flex justify='space-between' py={'1px'} align='center'>
                                <Box w={'100%'} >
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nome</Text>
                                    <Text color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.nome}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Total de pontos</Text>
                                    <Text color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{pontosS}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nota do seringueiro</Text>
                                    <Text color={item.corNota} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${notaS}%`}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Avaliação</Text>
                                    <Text color={item.corNota} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.avaliacaoSangria}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Center alignItems={'center'} h={'100%'}>
                                        <Button h="70px" bg="transparent" _hover={{ cursor: "pointer" }} onClick={() => { detalhesQualidadeSangria(item) }}>
                                            <Icon as={IoArrowRedo} width='30px' height='30px' color='orange.600' />
                                        </Button>
                                    </Center>
                                </Box>
                            </Flex>
                        </Box>
                    )
                })}
            </Card>
        )
    }

    function renderSeringueirosEsporadico() {
        return (
            <Card px='10px' py='0px'>
                {listUrlArquivoRelatorio.map((item, index) => {
                    const idAvaliacao = item.idAvaliacao != null && item.idAvaliacao != '' ? Number(item.idAvaliacao) : 0;
                    let corN = 'black';
                    if (idAvaliacao == 1) {
                        corN = 'gray.500';
                    } else if (idAvaliacao == 2) {
                        corN = 'green.500';
                    } else if (idAvaliacao == 3) {
                        corN = 'orange.600';
                    } else if (idAvaliacao == 4) {
                        corN = 'red';
                    }
                    return (
                        <Box
                            key={index}
                            position={'relative'}
                            borderWidth={'2px'}
                            borderRadius='20px'
                            p={'1px'}
                            bg={'blackAlpha.200'}
                            mt={'6px'}
                            mb={'5px'}
                            borderColor={'blackAlpha.400'}>
                            <Flex justify='space-between' py={'1px'} align='center'>
                                <Box w={'100%'} >
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nome</Text>
                                    <Text color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.nomePessoa}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Total de pontos</Text>
                                    <Text color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.totalPontos}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nota do seringueiro</Text>
                                    <Text color={corN} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${item.notaPessoa}%`}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Avaliação</Text>
                                    <Text color={corN} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.nomeAvaliacao}</Text>
                                </Box>
                                <Flex justify={'center'} align={'center'} w={'100%'} h="70px">
                                    <Link color='teal.500' cursor={'pointer'} _hover={{ bg: 'gray.300' }} href={`${item.urlStorage}`}>
                                        <Flex flexDirection={'column'} justify={'center'} align={'center'} w={'100%'}>
                                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' align={'center'} >Baixar Relatório</Text>
                                            <Icon as={IoDownloadSharp} width='30px' height='30px' color='orange.600' />
                                        </Flex>
                                    </Link>
                                </Flex>
                            </Flex>
                        </Box>
                    )
                })}
            </Card>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }



    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card w='100%' px='5px'>
                {renderSelect()}
                <Box w='100%' mt={'10px'}>
                    <Box px={'5'}>
                        <Box position={'relative'}>
                            <Flex flexDirection={'column'} justify='center' align='center' mb={'20px'}>
                                <Text color={'orange.600'} fontSize='20px' fontWeight={'bold'}>{`Qualidade de Sangria ${anoCorrente}`}</Text>
                                <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesSelecionado}</Text>
                                <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                                <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                            </Flex>
                            <Box position={'absolute'} top={'1px'} left={'10px'}>
                                <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                            </Box>
                        </Box>
                    </Box>
                    {renderCardsQualidade()}
                    {arrayQualidadeCompleto.length > 0 ?
                        renderSeringueiros()
                        :
                        <Text color={'gray.400'} fontSize='20px' fontWeight={'bold'} align={'center'}>{`Sem Resultados`}</Text>
                    }
                </Box>
            </Card>
            <Box>
                <Card mt={'20px'} py={'10px'} px='5px'>
                    <Flex flexDirection={'column'} justify='center' align='center'>
                        <Text color={textColor} fontSize='20px' fontWeight={'bold'}>{`Qualidade de Sangria Esporádica`}</Text>
                    </Flex>
                    {listUrlArquivoRelatorio.length > 0 ?
                        <Box>
                            {renderSeringueirosEsporadico()}
                        </Box>
                        :
                        <Text color={'gray.400'} fontSize='20px' fontWeight={'bold'} align={'center'}>{`Sem Resultados`}</Text>
                    }
                </Card>
            </Box>
            <LoaderBlack isOpen={loading} />
        </Box>
    );
};
