import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import ArvoreIcon from 'assets/img/layout/arvore_icon2.png';
import Calendario from 'assets/img/layout/calendario.png';
import QualidadeSangria from 'assets/img/layout/qualidade_sangria.png';
import IndicadorSangria from 'assets/img/layout/indicador_sangria.png';
import RelatEmitidos from 'assets/img/layout/relat_emitidos.png';

export default function ListaOpcoesRelatorios() {

    const history = useHistory();
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
            const isAtiva = sangriaAtivaP == 'false' ? true : false;
            setSangriaAtiva(isAtiva);
        }
        loaderScreen();
    }, []);

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Opções de Relatório</Text>
                <SimpleGrid columns={{ base: 1, md: 5, lg: 5, "2xl": 5 }} gap='20px' py={'20px'}>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/status-diario") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Status Diário'}</Text>
                                <Image h={'60px'} w={'60px'} src={Calendario} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/indicador-sangria") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Indicador'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'de Sangria'}</Text>
                                <Image h={'60px'} w={'60px'} src={IndicadorSangria} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/lista-qualidade-sangria") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Qualidade'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Sangria'}</Text>
                                <Image h={'60px'} w={'60px'} src={QualidadeSangria} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/relatorio-emitido-personalizado-esporadido") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Relatórios'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Emitidos'}</Text>
                                <Image h={'60px'} w={'60px'} src={RelatEmitidos} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/producao-por-sangria") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Produção'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'por Sangria'}</Text>
                                <Image h={'60px'} w={'60px'} src={ArvoreIcon} />
                            </Flex>
                        </Flex>
                    </Box>
                </SimpleGrid>
            </Card>
        </Box>
    );
}
