
/*
╔═══════════════════════════════════════════════════════════════════════════╗
   CALCULANDO TOTAL DE PONTOS DA ARVORE
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function calculaTotalDePontosArvore(arr) {    
    let totalDePontosP = 0;
    let qtdArvoresParaDividirTotal = 0;

    try {
        if (arr.length > 0) {
            arr.map((item, index) => {
                if (item.totalPontos != null && item.totalPontos != '') {
                    totalDePontosP = totalDePontosP + Number(item.totalPontos);

                    if (Number(item.totalPontos) != 0) {   // SE O TOTAL DE PONTOS DE UMA ARVORE FOR 0, ELA NAO ENTRA NA DIVISAO DO RESULTADO FINAL
                        qtdArvoresParaDividirTotal = qtdArvoresParaDividirTotal + 1;
                    }
                }
            });
            if (qtdArvoresParaDividirTotal != 0) {
                totalDePontosP = totalDePontosP / qtdArvoresParaDividirTotal;
            }
        }
    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo calculaTotalDePontosArvore', error);
    }
    return totalDePontosP;
}


const arrayArvo = [
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '1', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '2', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '3', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '4', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '5', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '6', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '7', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '8', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '9', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '10', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
]

/*
╔═══════════════════════════════════════════════════════════════════════════╗
   CARREGANDO DADOS DAS ARVORES 
   OLHA SO, EU TENHO QUE TER 10 ARVORES NA TELA CERTO, MAS NO BANCO POSSO
   TER UMA OU 10, ENTAO COM ESSE ARRAY ACIMA FACO UM LOOP NOS 10 E SE 
   TIVER REGISTRO NO CONCATENO SE NAO FICA SO OS DADOS BASICOS DESSE 
   ARRAY 
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function carregarArvoresParaUmaSangria(res, dadosSeringueiro) {    
    let result = 0;
    const idPes = dadosSeringueiro.idPessoa;
    const nomeSering = dadosSeringueiro.nome;
    const idQualidade = res.idQualidadeSangria;

    try {
        const arr = res.listArvore;
        if (arr.length > 0) {
            let temp = arrayArvo.map((item, index) => {
                let arvorePodeSerEditada = true;

                if (index > 0) {                          // A ARVORE SO PODE SER EDITADA SE A ANTERIOR ESTIVER CONCLUIDA
                    if (arr[index - 1] != undefined) {
                        arvorePodeSerEditada = true;
                    } else {
                        arvorePodeSerEditada = false;
                    }
                }

                if (arr[index] != undefined) {
                    const arvoreRequest = arr[index];
                    arvorePodeSerEditada = true
                    return {
                        idQualidadeSangria: idQualidade,
                        idPessoa: idPes,
                        nome: nomeSering,
                        arvoreChecada: arvoreRequest.arvoreChecada,
                        numeroArvore: item.numeroArvore,
                        editavel: arvorePodeSerEditada,
                        totalPontos: arvoreRequest.totalPontos,
                        objArvore: arr[index]
                    }
                }
                if (arr[index] == undefined) {
                    return {
                        idQualidadeSangria: idQualidade,
                        idPessoa: idPes,
                        nome: nomeSering,
                        arvoreChecada: false,
                        numeroArvore: item.numeroArvore,
                        editavel: arvorePodeSerEditada,
                        totalPontos: '0',
                        objArvore: null
                    }
                }
            })
            result = temp;
        } else {
            let temp = arrayArvo.map((item, index) => {
                let arvorePodeSerEditada = true;
                if (index == 0) {                          // A ARVORE SO PODE SER EDITADA SE A ANTERIOR ESTIVER CONCLUIDA
                    arvorePodeSerEditada = true;
                } else {
                    arvorePodeSerEditada = false;
                }
                return {
                    idQualidadeSangria: idQualidade,
                    idPessoa: idPes,
                    nome: nomeSering,
                    arvoreChecada: false,
                    numeroArvore: item.numeroArvore,
                    editavel: arvorePodeSerEditada,
                    objArvore: null
                }
            })
            result = temp;
        }
    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo calculaTotalDePontosArvore', error);
    }
    return result;
}


export async function calculoGeralRelatorio(pontosGeral, notaGeralP, qtdDivisao) {
    let mediaGeralDePontos = 0;
    let notaGeral = 0;
    let avaliacaoGeral = '';
    let corNotaGeral = 0;
    let json = null;
    try {
        if (pontosGeral != '') {
            const me = Number(pontosGeral) / Number(qtdDivisao);
            const me2 = parseFloat(me.toFixed(2));
            mediaGeralDePontos = me2;

            const no = Number(notaGeralP) / Number(qtdDivisao);
            const no2 = parseFloat(no.toFixed(2));
            notaGeral = no2;

            let val = no.toFixed(0);
            val = Number(val);

            let avalia = '';
            let corN = 'black';     // NAO COLOCAR NUMEROS NAS CORES TIPO green.500, POR CAUSA DO HTML
            if (val >= 95) {
                corN = 'green';
                avalia = 'Boa';
            } else if (val <= 94 && val >= 90) {
                corN = 'orange';
                avalia = 'Média';
            } else {
                corN = 'red';
                avalia = 'Ruim';
            }

            avaliacaoGeral = avalia;
            corNotaGeral = corN;
        } else {
            mediaGeralDePontos = 0;
            notaGeral = 0;
            avaliacaoGeral = '';
            corNotaGeral = 'black';
        }

        json = {
            mediaGeralDePontos,
            notaGeral,
            avaliacaoGeral,
            corNotaGeral
        }
    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo calculoGeral', error);
    }
    return json;
}
