import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Button,
    Wrap,
    WrapItem,
    SimpleGrid,
    Select,
    Icon,
    Textarea,
    Center,
    useDisclosure,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    IconButton,
    Input,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { FREQUENCIA, ARRAY_TAREFA, CASCA, SENTIDO, AVALIACAO_SANGRIA } from 'model/MockData';
import { calculaTotalDePontosArvoreSemDivisao, carregarArvoresParaUmaSangria, retornaNomeDoMesMaiusculo, ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO } from '../../../../util/FuncoesUteis';
import * as ActionTypes from '../../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import ArvoreIcon from 'assets/img/layout/arvore_icon2.png';
import ModalDialog from 'components/modal/ModalDialog';
import { IoArrowRedoSharp } from "react-icons/io5";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export default function QualidadeSangriaEsporadico() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [arrayArvores, setArrayArvores] = useState([]);
    const [totalPontos, setTotalPontos] = useState(0);
    const [notaSeringueiro, setNotaSeringueiro] = useState(0);
    const [observacao, setObservacao] = useState('');
    const [descMesP, setDescMesP] = useState('');
    const [numMesP, setNumMesP] = useState(0);

    const [dtPeriodoDisponivelP, setDtPeriodoDisponivelP] = useState('');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [idQualidadeSangriaP, setIdQualidadeSangriaP] = useState(null);
    const [idPessoaP, setIdPessoaP] = useState('');
    const [idUaP, setIdUaP] = useState(null);
    const [idSafraP, setIdSafraP] = useState(null);

    const [tarefaSelecionado, setTarefaSelecionado] = useState('');
    const [frequenciaSelecionado, setFrequenciaSelecionado] = useState('');
    const [cascaSelecionado, setCascaSelecionado] = useState('');
    const [sentidoSelecionado, setSentidoSelecionado] = useState('');
    const [avaliacaoSelecionado, setAvaliacaoSelecionado] = useState('');
    const [isValidTarefa, setIsValidTarefa] = useState(false);
    const [isValidFrequencia, setIsValidFrequencia] = useState(false);
    const [isValidCasca, setIsValidCasca] = useState(false);
    const [isValidSentido, setIsValidSentido] = useState(false);
    const [qtdImages, setQtdImages] = useState(0);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openDialogLimpaCampos, setOpenDialogLimpaCampos] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [maxCharacterObservacao, setMaxCharacterObservacao] = useState(2000);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [isValidNomeSeringueiro, setIsValidNomeSeringueiro] = useState(false);
    const [arrayFilesQualidade, setArrayFilesQualidade] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const bg = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.5)', 'unset');
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');
    const gradientGray = 'linear-gradient(gray.700, gray.600, gray.500)';

    useEffect(() => {
        async function loaderScreen() {
            //window.scrollTo(0, 0);
            await retirarImagensComDataLimiteExedido();

            const startDate = localStorage.getItem('@HE-dtInicioSafraAsync');
            const endDate = localStorage.getItem('@HE-dtFimSafraAsync');
            const arrayDatas = await ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(startDate, endDate);
            setArrayMesInicio(arrayDatas);

            const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
            setSangriaAtiva(sangriaAti == 'true' ? true : false);

            let currentDate = '';
            const param1 = localStorage.getItem('@HE-mesSelecionadoTelaQualidadeSangriaEsporadico');

            if (param1 != null) {
                currentDate = param1;
            } else {
                currentDate = moment().format('YYYY-MM-DD');
                localStorage.setItem('@HE-mesSelecionadoTelaQualidadeSangriaEsporadico', `${currentDate}`);
            }
            await carregarInformacoesDaTela(currentDate);
            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            setArrayArvores([]);
        }
    }, []);

    async function carregarInformacoesDaTela(data) {
        try {
            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            const numMes = moment(data).endOf('month').format("MM");
            const nm = moment().format('MMMM');
            const nomeMesMaiusculo = await retornaNomeDoMesMaiusculo(nm);
            const primeiroDiaDoMesCorrente = moment(data).startOf('month').format('YYYY-MM-DD');

            setNumMesP(numMes);
            setIdSafraP(idSafra);
            setIdUaP(idUa);
            setMesSelecionado(primeiroDiaDoMesCorrente);
            setDescMesP(nomeMesMaiusculo);
            setDtPeriodoDisponivelP(data);

            const idPessoaX = Math.floor(Math.random() * 100) + 1;
            const idQualidadeSangriaX = Math.floor(Math.random() * 100) + 1;

            const dadosSeringueiro = {
                idPessoa: idPessoaX,
                idQualidadeSangria: idQualidadeSangriaX,
            }
            const idPessoa = dadosSeringueiro.idPessoa;
            setIdPessoaP(idPessoa);

            const arv = localStorage.getItem('@HE-q_s_avaliacaoEsporadico');
            const arvores = arv != null ? JSON.parse(arv) : [];
            const obj = {
                listArvore: arvores,
                idQualidadeSangria: idQualidadeSangriaX,
            }
            await CARREGAR_PARAMETROS(obj, dadosSeringueiro);

            let totalDePontosP = 0;
            if (arvores.length > 0) {
                totalDePontosP = await calculaTotalDePontosArvoreSemDivisao(arvores);
            }
            totalDePontosP = totalDePontosP.toFixed(2);
            totalDePontosP = Number(totalDePontosP);

            const pontosSangria = 100;
            let notaSeringueiroP = pontosSangria - totalDePontosP;
            notaSeringueiroP = notaSeringueiroP.toFixed(2);
            notaSeringueiroP = Number(notaSeringueiroP);

            const ss = parseFloat(notaSeringueiroP.toFixed(2));
            setNotaSeringueiro(ss);
            const xx = parseFloat(totalDePontosP.toFixed(2));
            setTotalPontos(xx);

            const arrImg = localStorage.getItem('@HE-q-s-array-imagens-esporadico');
            const zzQ = arrImg != null ? JSON.parse(arrImg) : [];
            setQtdImages(zzQ.length);
            setArrayFilesQualidade(zzQ);

        } catch (error) {
            console.log('Error na classe QualidadeSangriaEsporadico metodo carregarInformacoesDaTela ', error);
        }
    }

    const nextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === arrayFilesQualidade.length - 1 ? 0 : prevIndex + 1
        );
    };
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? arrayFilesQualidade.length - 1 : prevIndex - 1
        );
    };

    const CARREGAR_PARAMETROS = async (res, dadosSeringueiro) => {

        let idTa = '';
        let idFr = '';
        let idCa = '';
        let idSe = '';
        let obse = '';
        let idAv = '';
        let nome = '';

        const itens = localStorage.getItem('@HE-objSangriaItensEsporadico');
        if (itens != null) {
            const xx = JSON.parse(itens);
            idTa = xx.idTarefa;
            idFr = xx.idQuantidadeTarefas;
            idCa = xx.idCasca;
            idSe = xx.idSentido;
            obse = xx.observacao != null ? xx.observacao : ''
            idAv = xx.idAvaliacao != null && xx.idAvaliacao != '' ? `${xx.idAvaliacao}` : '';
            nome = xx.nome;
        }
        setTarefaSelecionado(idTa);
        setFrequenciaSelecionado(idFr);
        setCascaSelecionado(idCa);
        setSentidoSelecionado(idSe);
        setObservacao(obse);
        setAvaliacaoSelecionado(idAv);
        setNomeSeringueiro(nome);
        setArrayArvores([]);

        const zz1 = obse != null && obse != '' ? obse.length : 0;
        const ttCaracter = 2000 - zz1;
        setMaxCharacterObservacao(ttCaracter);

        const arrayArvoresParaTela = await carregarArvoresParaUmaSangria(res, dadosSeringueiro);
        setArrayArvores(arrayArvoresParaTela);
    }

    const showAlert = async () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 9000);
    }

    const irParaTelaModal = async (item) => {
        await armazenarItensAntesDeSairDaTela();
        item.nome = nomeSeringueiro

        localStorage.setItem('@HE-objSeringueiroParaTelaAvaliacaoEsporadico', JSON.stringify(item));
        history.push("/admin/avaliacao-sangria-exporadico");
    }

    const acessarGaleriaImagemAndFotos = async () => {
        const json = {
            idPessoa: idPessoaP,
            nome: nomeSeringueiro,
            idQualidadeSangria: idQualidadeSangriaP,
            mesSelecionado: mesSelecionado,
        }
        await armazenarItensAntesDeSairDaTela();
        localStorage.setItem('@HE-objUrlImagensAndVideosTelaGaleriaEsporadico', JSON.stringify(json));
        history.push("/admin/gallery-image-esporadico");
    }

    const changeObservacao = (val) => {
        setObservacao(val);
        const xx = val.length;
        const num = 2000 - xx;
        setMaxCharacterObservacao(num);
    }

    const changeMes = (val) => {
        setMesSelecionado(val);
        localStorage.setItem('@HE-mesSelecionadoTelaQualidadeSangriaEsporadico', `${val}`);
    }

    /*
    ╔═══════════════════════════════════════════════════════════════╗
                ATENCAOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO

        O BUCKET QUE ESSAS IMAGENS USA E hevea_bucket_temporario
        ESSE CARA ARMAZENA AS IMAGENS POR 7 DIAS DEPOIS APAGA
        ENTAO SE O USUARIO NAO LIMPAR OS CAMPOS EU VERIFICO
        E RETIRO A URL COM MAIS DE 5 DIAS DO ARRAY: 
        @HE-q-s-array-imagens-esporadico
    ╚═══════════════════════════════════════════════════════════════╝*/
    async function retirarImagensComDataLimiteExedido() {

        const result = [];
        const dataLimite = moment().subtract(5, 'days');
        const ima = localStorage.getItem('@HE-q-s-array-imagens-esporadico');
        const imagens = ima != null ? JSON.parse(ima) : [];

        if (imagens.length > 0) {

            for (let i = 0; i < imagens.length; i++) {
                const item = imagens[i];
                const dt = item.dataUpload;

                if (dt != null && dt != '' && dt != undefined) {
                    if (moment(dt).isAfter(dataLimite)) {
                        result.push(item);
                    }
                }
            }
        }
        localStorage.setItem('@HE-q-s-array-imagens-esporadico', JSON.stringify(result));
        return result;
    }

    const acessarRelatorio = async () => {

        if (nomeSeringueiro.trim().length < 4) {
            setNumStatusResposta(ActionTypes.CAMPOS_OBRIGATORIOS_QUALIDADE_SANGRIA);
            setIsValidNomeSeringueiro(true);
            showAlert();
            return;
        }

        await armazenarItensAntesDeSairDaTela();

        const ima = localStorage.getItem('@HE-q-s-array-imagens-esporadico');
        const ite = localStorage.getItem('@HE-objSangriaItensEsporadico');
        const arv = localStorage.getItem('@HE-q_s_avaliacaoEsporadico');
        const imagens = ima != null ? JSON.parse(ima) : [];
        const itens = ite != null ? JSON.parse(ite) : [];
        const arvores = arv != null ? JSON.parse(arv) : [];

        const letraX = ARRAY_TAREFA.filter(x => x.value == itens.idTarefa);
        const nomeQuantidadeTarefasX = FREQUENCIA.filter(x => x.value == itens.idQuantidadeTarefas);
        const nomeCascaX = CASCA.filter(x => x.value == itens.idCasca);
        const nomeSentidoX = SENTIDO.filter(x => x.value == itens.idSentido);
        const nomeAvaliacaoX = AVALIACAO_SANGRIA.filter(x => x.value == itens.idAvaliacao);

        const letra = letraX.length > 0 ? letraX[0].label : '';
        const nomeQuantidadeTarefas = nomeQuantidadeTarefasX.length > 0 ? nomeQuantidadeTarefasX[0].label : '';
        const nomeCasca = nomeCascaX.length > 0 ? nomeCascaX[0].label : '';
        const nomeSentido = nomeSentidoX.length > 0 ? nomeSentidoX[0].label : '';
        const nomeAvaliacao = nomeAvaliacaoX.length > 0 ? nomeAvaliacaoX[0].label : '';

        const arrayParam = [{
            ...itens,
            letra: letra,
            nomeQuantidadeTarefas: nomeQuantidadeTarefas,
            nomeCasca: nomeCasca,
            nomeSentido: nomeSentido,
            nomeAvaliacao: nomeAvaliacao,
            listArvore: arvores,
            urlArquivo: imagens.length > 0 ? imagens[0] : null,
        }]

        const json = {
            listQualidadeSangria: arrayParam,
            listUrlImagens: imagens,
            nome: nomeSeringueiro,
            dtPeriodoDisponivel: mesSelecionado,
        }

        localStorage.setItem('@HE-dadosParaRelatorioQualidadeSangriaEsporadico', JSON.stringify(json));
        history.push("/admin/relatorio-qualidade-sangria-exporadico");
    }

    const armazenarItensAntesDeSairDaTela = async () => {
        const objItens = {
            idTarefa: tarefaSelecionado,
            idQuantidadeTarefas: frequenciaSelecionado,
            idCasca: cascaSelecionado,
            idSentido: sentidoSelecionado,
            observacao: observacao,
            idAvaliacao: avaliacaoSelecionado,
            nome: nomeSeringueiro,
            mesSelecionado: mesSelecionado,
            pontos: '100',
            numMes: numMesP,
            idQualidadeSangria: idQualidadeSangriaP,
            idUa: idUaP,
            idPessoa: idPessoaP,
            idSafra: idSafraP,
            dtPeriodoDisponivel: dtPeriodoDisponivelP,
            descMes: descMesP,
            avaliacaoFinalizada: false,
        }
        localStorage.setItem('@HE-objSangriaItensEsporadico', JSON.stringify(objItens));
    }

    const limparTodosCampos = async () => {
        setOpenDialogLimpaCampos(false);

        localStorage.removeItem('@HE-objSangriaItensEsporadico');
        localStorage.removeItem('@HE-q-s-array-imagens-esporadico');
        localStorage.removeItem('@HE-q_s_avaliacaoEsporadico');
        localStorage.removeItem('@HE-objUrlImagensAndVideosTelaGaleriaEsporadico');
        localStorage.removeItem('@HE-mesSelecionadoTelaQualidadeSangriaEsporadico');
        localStorage.removeItem('@HE-dadosParaRelatorioQualidadeSangriaEsporadico');
        localStorage.removeItem('@HE-objSeringueiroParaTelaAvaliacaoEsporadico');

        const primeiroDiaDoMesCorrente = moment().startOf('month').format('YYYY-MM-DD');
        const idUa = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const numMes = moment().endOf('month').format("MM");

        setNumMesP(numMes);
        setIdSafraP(idSafra);
        setIdUaP(idUa);
        setMesSelecionado(primeiroDiaDoMesCorrente);
        setArrayArvores([]);
        setArrayFilesQualidade([]);
        setAvaliacaoSelecionado('');
        setCascaSelecionado('');
        setCurrentIndex(0);
        setDescMesP('');
        setDtPeriodoDisponivelP('');
        setFrequenciaSelecionado('');
        setIdPessoaP(null);
        setIsValidCasca(false);
        setIsValidFrequencia(false);
        setIsValidNomeSeringueiro(false);
        setIsValidSentido(false);
        setIsValidTarefa(false);
        setIsVisibleAlert(false);
        setMaxCharacterObservacao(2000);
        setNotaSeringueiro(0);
        setNumStatusResposta('02');
        setObservacao('');
        setQtdImages(0);
        setSentidoSelecionado('');
        setTarefaSelecionado('');
        setTotalPontos(0);
        setNomeSeringueiro('');
    }

    const changeTarefa = async (val) => {
        setTarefaSelecionado(val);
        armazenarItensAntesDeSairDaTela();
    };
    const changeFrequencia = async (val) => {
        setFrequenciaSelecionado(val);
        armazenarItensAntesDeSairDaTela();
    };
    const changeCasca = async (val) => {
        setCascaSelecionado(val);
        armazenarItensAntesDeSairDaTela();
    };
    const changeSentido = async (val) => {
        setSentidoSelecionado(val);
        armazenarItensAntesDeSairDaTela();
    };
    const clicouForaDoCampoNome = () => {
        armazenarItensAntesDeSairDaTela();
    };
    const clicouForaDoCampoTextArea = () => {
        armazenarItensAntesDeSairDaTela();
    };
    const changeVoltar = () => {
        armazenarItensAntesDeSairDaTela();
        history.goBack();
    };

    function renderMesAndSeringueiro() {
        return (
            <Box>
                <Box py={'10px'} borderRadius={'20px'} mb={'20px'} bgGradient={gradientGray}>
                    <Flex px={'10px'} py={'10px'} justify={'center'} align="center">
                        <Box w={'100%'}>
                            <Text color={'white'} fontSize='22px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Qualidade de Sangria Esporádico'}</Text>
                            <Text color={'gray.200'} fontSize='16px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{'Aqui você pode criar qualidade de sangria para um seringueiro não cadastrado no sistema.'}</Text>
                            <Text color={'gray.200'} fontSize='16px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{'Informe o nome do seringueiro e o mês de referência da qualidade.'}</Text>
                        </Box>
                    </Flex>
                    <Wrap px={'10px'} spacing='10px' justify='space-around'>
                        <WrapItem>
                            <Box w='100%'>
                                <Text color={'white'} fontSize='15px' fontWeight='700' lineHeight='100%' align={'center'} mb={'5px'}>Meses</Text>
                                <Box bg={'white'} borderRadius={'10px'}>
                                    <Select
                                        value={mesSelecionado}
                                        errorBorderColor='crimson'
                                        placeholder=""
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        fontSize={'13px'}
                                        fontWeight={'bold'}
                                        bg={'white'}
                                        onChange={e => changeMes(e.target.value)}
                                        w={'250px'}
                                        h={'35px'}
                                    >
                                        {arrayMesInicio.map((item, index) => {
                                            return (
                                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                            )
                                        })}
                                    </Select>
                                </Box>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box w='100%'>
                                <Text color={'white'} fontSize='15px' fontWeight='700' lineHeight='100%' align={'center'} mb={'5px'}>Seringueiro</Text>
                                <Box bg={'white'} borderRadius={'10px'}>
                                    <Input
                                        value={nomeSeringueiro}
                                        onChange={(e) => setNomeSeringueiro(e.target.value)}
                                        onBlur={clicouForaDoCampoNome}
                                        isInvalid={isValidNomeSeringueiro}
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        placeholder="Nome Seringueiro"
                                        _placeholder={{ opacity: 0.5, color: 'inherit' }}
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        fontSize={'13px'}
                                        fontWeight={'bold'}
                                        id='name'
                                        name='name'
                                        type="text"
                                        autoComplete='name'
                                        h={'35px'}
                                        w={'250px'}
                                    />
                                </Box>
                            </Box>
                        </WrapItem>
                    </Wrap>
                </Box>
            </Box>
        )
    }

    function renderInformacoes() {
        return (
            <Box pointerEvents={sangriaAtiva ? 'auto' : 'none'} opacity={sangriaAtiva ? '1' : '0.5'}>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='5px'>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} ml={'10px'}>Tarefas *</Text>
                            <Select
                                value={tarefaSelecionado}
                                isInvalid={isValidTarefa}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => changeTarefa(e.target.value)}
                            >
                                {ARRAY_TAREFA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} ml={'10px'}>Frequência *</Text>
                            <Select
                                value={frequenciaSelecionado}
                                isInvalid={isValidFrequencia}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => changeFrequencia(e.target.value)}
                            >
                                {FREQUENCIA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} ml={'10px'}>Casca *</Text>
                            <Select
                                value={cascaSelecionado}
                                isInvalid={isValidCasca}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => changeCasca(e.target.value)}
                            >
                                {CASCA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} ml={'10px'}>Sentido *</Text>
                            <Select
                                value={sentidoSelecionado}
                                isInvalid={isValidSentido}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => changeSentido(e.target.value)}
                            >
                                {SENTIDO.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                </SimpleGrid>
            </Box>
        )
    }

    function remderArvores() {
        return (
            <Box mt={'30px'}>
                <Flex justify='center' align='center'>
                    <Text color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{`Numeração das árvores`}</Text>
                    <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                        <TooltipIcon
                            label={
                                <Box px={'0px'} py={'5px'} >
                                    <Text align={'center'}>{'Árvores'}</Text>
                                    <Text align={'start'} mt={'5px'}>{'Lançamento:'}</Text>
                                    <Text align={'start'} mt={'0px'} ml={'20px'} lineHeight='100%'>{'As Árvores são pontuadas de forma sequencial, ou seja, só é possível pontuar uma árvore se a anterior estiver pontuada'}</Text>
                                    <Text align={'start'} mt={'10px'}>{'Pontos:'}</Text>
                                    <Text align={'start'} mt={'0px'} ml={'20px'} lineHeight='100%'>{'Soma dos pontos cadastrados'}</Text>
                                </Box>
                            }
                            height={'20px'} />
                    </Center>
                </Flex>
                <Text color={textColor} fontSize={'15px'} fontWeight='light' align={'center'} lineHeight={'100%'}>{`Clique na árvore para editar`}</Text>
                <Wrap mt={'10px'} spacing='20px' justify={'space-between'} align={'center'}>
                    {arrayArvores.map((item, index) => {
                        const editavel = item.editavel == false ? false : true;
                        let corBackground = 'white';
                        if (item.arvoreChecada == true) {
                            corBackground = 'green';
                        } else if (item.editavel == true) {
                            corBackground = 'orange.200';
                        }
                        const totalPontosP = Number(item.totalPontos);
                        return (
                            <WrapItem key={index} w={'75px'} justifyContent={'center'} alignItems={'center'}>
                                <Box w={'100%'}>
                                    <Box pointerEvents={editavel ? 'auto' : 'none'} opacity={editavel ? '1' : '0.5'} w={'100%'}>
                                        <Box cursor={'pointer'} onClick={() => { irParaTelaModal(item) }} bg={corBackground} borderWidth={'3px'} borderColor={'blackAlpha.600'} borderRadius={'10px'}>
                                            <Flex py={'5px'} justify='space-around' align='center'>
                                                <Center h={'30px'} w={'30px'} bg={'white'} borderRadius={'15px'} justifyContent={'center'} alignItems={'center'}>
                                                    <Image h={'25px'} w={'25px'} src={ArvoreIcon} alt="img-r1" />
                                                </Center>
                                                <Text color={item.arvoreChecada ? 'white' : 'black'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'}>{item.numeroArvore}</Text>
                                            </Flex>
                                            <Flex mb={'2px'} justify='end' align='end'>
                                                <Icon as={IoArrowRedoSharp} width='25px' height='25px' color={item.arvoreChecada ? 'white' : 'orange.800'} />
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Flex justify='center' align='center'>
                                        {item.arvoreChecada == true ?
                                            <Flex flexDirection={'column'} justify='center' align='center'>
                                                <Text color={totalPontosP > 0 ? 'gray.900' : 'gray.300'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Pontos'}</Text>
                                                <Text color={totalPontosP > 0 ? 'gray.900' : 'gray.300'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{totalPontosP}</Text>
                                            </Flex>
                                            :
                                            <Flex flexDirection={'column'} justify='center' align='center'>
                                                <Text color={'transparent'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Pontos'}</Text>
                                                <Text color={'transparent'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'0'}</Text>
                                            </Flex>
                                        }
                                    </Flex>
                                </Box>
                            </WrapItem>
                        )
                    })}
                </Wrap>
            </Box>
        )
    }

    function renderCards() {
        let ttpont = `${totalPontos}`;
        ttpont = ttpont.replace('.', ',');
        let notSerin = `${notaSeringueiro}`;
        notSerin = notSerin.replace('.', ',');
        return (
            <Box mt={'30px'}>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                            <Flex align={'center'} justify={'center'}>
                                <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'}>{'Total de pontos'}</Text>
                                <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                    <TooltipIcon
                                        label={
                                            <Box px={'0px'} py={'5px'} >
                                                <Text align={'center'}>{'Total de Pontos'}</Text>
                                                <Text align={'start'} mt={'10px'} lineHeight='100%'>{'Soma da pontuação das árvores'}</Text>
                                            </Box>
                                        }
                                        height={'20px'} />
                                </Center>
                            </Flex>
                            <Text color={textColor} fontSize={'20px'} fontWeight='bold' align={'center'}>{ttpont}</Text>
                        </Flex>
                    </Card>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                            <Flex align={'center'} justify={'center'}>
                                <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'}>{'Nota do seringueiro'}</Text>
                                <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                    <TooltipIcon
                                        label={
                                            <Box px={'0px'} py={'5px'} >
                                                <Text align={'center'}>{'Nota do seringueiro'}</Text>
                                                <Text align={'start'} mt={'10px'} lineHeight='100%'>{'Pontuação do seringueiro menos a soma da pontuação das árvores'}</Text>
                                            </Box>
                                        }
                                        height={'20px'} />
                                </Center>
                            </Flex>
                            <Text color={textColor} fontSize={'20px'} fontWeight='bold' align={'center'}>{`${notSerin}%`}</Text>
                        </Flex>
                    </Card>
                    <Card py='10px' boxShadow={cardShadow} bg={bg}>
                        <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} align={'center'}>Avaliação</Text>
                            <Select
                                value={avaliacaoSelecionado}
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={'blackAlpha.300'}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => setAvaliacaoSelecionado(e.target.value)}
                            >
                                {AVALIACAO_SANGRIA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Flex>
                    </Card>
                </SimpleGrid>
            </Box>
        )
    }

    function remderObservacoes() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Observação'}</Text>
                    <Textarea
                        value={observacao}
                        onChange={(e) => { changeObservacao(e.target.value) }}
                        onBlur={clicouForaDoCampoTextArea}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={'blackAlpha.300'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={2000}
                    />
                </Flex>
                <Text mt={'5px'} color={'gray.500'} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterObservacao}`}</Text>
            </Box>
        )
    }

    function renderUploadFile() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex justify='space-between' align='start'>
                    <Flex flexDirection={'column'} w={'100%'} justify={'start'} align={'start'}>
                        <Flex mb={'10px'} align={'center'} justify={'center'}>
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Imagens temporárias'}</Text>
                            <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                <TooltipIcon
                                    label={
                                        <Box px={'0px'} py={'5px'} >
                                            <Text align={'center'}>{'Imagens temporárias'}</Text>
                                            <Text align={'start'} mt={'10px'} lineHeight='100%'>{'As imagens temporárias ficam disponíveis por 6 dias no sistema.'}</Text>
                                            <Text align={'start'} mt={'10px'} lineHeight='100%'>{'Mas podem ser apagadas também na galeria, ou no botão Limpar todos os campos.'}</Text>
                                        </Box>
                                    }
                                    height={'20px'} />
                            </Center>
                        </Flex>
                        {arrayFilesQualidade.length > 0 ?
                            <Box w={'100%'}>
                                <Flex w={'100%'} justify='start' align='start' bg={'blackAlpha.200'} borderRadius={'10px'}>
                                    <Box textAlign="center">
                                        <Wrap p={'5px'} spacing='15px' justify={'start'} align={'center'}>
                                            {arrayFilesQualidade.map((image, index) => (
                                                <WrapItem
                                                    key={index}
                                                    boxSize="50px"
                                                    flex="0 0 auto"
                                                    borderRadius={'10px'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    borderWidth={currentIndex === index ? "0px" : "0px"}
                                                    borderColor={'red'}>
                                                    <Box position={'relative'}>
                                                        <Image
                                                            src={image.urlStorage}
                                                            alt={`Thumbnail ${index + 1}`}
                                                            boxSize={'48px'}
                                                            objectFit="cover"
                                                            borderRadius={'8px'}
                                                        />
                                                    </Box>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                        <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                            <ModalOverlay />
                                            <ModalContent maxW="600px" position="relative">
                                                <ModalCloseButton bg={'whiteAlpha.800'} borderWidth={'1px'} borderColor={'blackAlpha.800'} />
                                                <IconButton
                                                    aria-label="Previous Image"
                                                    icon={<ChevronLeftIcon />}
                                                    position="absolute"
                                                    left="0"
                                                    top="50%"
                                                    transform="translateY(-50%)"
                                                    onClick={prevImage}
                                                    zIndex={1}
                                                />
                                                <ModalBody p={0}>
                                                    <Image
                                                        src={arrayFilesQualidade[currentIndex].urlStorage}
                                                        alt={`Image ${currentIndex + 1}`}
                                                        h={'400px'}
                                                    />
                                                </ModalBody>
                                                <IconButton
                                                    aria-label="Next Image"
                                                    icon={<ChevronRightIcon />}
                                                    position="absolute"
                                                    right="0"
                                                    top="50%"
                                                    transform="translateY(-50%)"
                                                    onClick={nextImage}
                                                    zIndex={1}
                                                />
                                            </ModalContent>
                                        </Modal>
                                    </Box>
                                </Flex>
                            </Box>
                            :
                            <Flex pl={'10px'} minH={'60px'} w={'100%'} overflowX="auto" justify='center' align='center' bg={'blackAlpha.200'} borderRadius={'10px'}>
                                <Text color={'gray.400'} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Sem Imagens'}</Text>
                            </Flex>
                        }
                    </Flex>
                </Flex>
                <Flex px={'10px'} mt={'10px'} justify='end' align='center'>
                    <Button variant="miniBrand" onClick={acessarGaleriaImagemAndFotos}>Acessar Galeria</Button>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Flex w={'350px'} px={'10px'} py={'8px'} borderWidth={'1px'} borderColor={'blackAlpha.300'} borderRadius={'10px'} justify='center' align='center'>
                    <Flex flexDirection={'column'} justify='center' align='center'>
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Limpar campos e gerar um novo relatório'}</Text>
                        <Flex mt={'10px'} justify='center' align='center'>
                            <Button isDisabled={!sangriaAtiva} variant="brand" onClick={() => { setOpenDialogLimpaCampos(true) }}>Novo Relatório</Button>
                            <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                <TooltipIcon
                                    label={
                                        <Box px={'0px'} py={'5px'} >
                                            <Text align={'center'}>{'Limpar todos os campos'}</Text>
                                            <Text align={'start'} mt={'10px'} ml={'5px'} lineHeight='100%'>{'As informações inseridas serão apagadas, como fotos e pontuações das árvores.'}</Text>
                                        </Box>
                                    }
                                    height={'20px'} />
                            </Center>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'350px'} px={'10px'} py={'8px'} justify='center' align='end'>
                    <Button isDisabled={!sangriaAtiva} variant="brand" onClick={() => { acessarRelatorio() }}>Gerar Relatório</Button>
                </Flex>

                {/* <Flex w={'350px'} px={'10px'} py={'8px'} borderWidth={'1px'} borderColor={'blackAlpha.300'} borderRadius={'10px'} justify='center' align='center'>
                    <Flex flexDirection={'column'} justify='center' align='center'>
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Salvar Rascunho e gerar relatório'}</Text>
                        <Flex mt={'10px'} justify='center' align='center'>
                            <Button isDisabled={!sangriaAtiva} variant="brand" onClick={() => { acessarRelatorio() }}>Gerar Relatório</Button>
                        </Flex>
                    </Flex>
                </Flex> */}
            </Wrap>
        )
    }

    function renderVoltar() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'20px'} justify='space-between'>
                <Button variant="brand" onClick={() => { changeVoltar() }}>Voltar</Button>
            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            {isVisibleAlert && renderAlert()}
            {renderMesAndSeringueiro()}
            <Card px={'10px'} py={'10px'} boxShadow={cardShadowObs}>
                <Box py={'10px'}>
                    {renderInformacoes()}
                    {remderArvores()}
                    {renderCards()}
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px' mt={'30px'}>
                        {remderObservacoes()}
                        {renderUploadFile()}
                    </SimpleGrid>
                </Box>
                {renderButton()}
            </Card>
            {openDialogLimpaCampos && <ModalDialog isOpen={openDialogLimpaCampos} onPressOk={() => { limparTodosCampos() }} onPressCancel={() => { setOpenDialogLimpaCampos(false) }} descricao={'As informações inseridas serão apagadas como fotos e pontuações das árvores, deseja continuar?'} />}
            {renderVoltar()}
        </Box>
    );
}
