import React, { useState, useEffect, useRef } from "react";
import {
    Flex,
    Box,
    Text,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
    useColorModeValue,
    Button,
    Wrap,
    WrapItem,
    Input,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import * as ActionTypes from '../../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import ImageModal from 'components/gallery/ImageModal';
import ModalDialog from 'components/modal/ModalDialog';
import { IoAddCircleSharp } from "react-icons/io5";
import { UPLOAD_FILE_TEMPORARIO } from '../../../../util/SalvarImagem';
import { DeleteIcon } from '@chakra-ui/icons';
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";

export default function GalleryImageAndVideoEsporadico() {

    const history = useHistory();
    const inputRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [numMesP, setNumMesP] = useState(0);
    const [nomeSeringueiro, setnomeSeringueiro] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalDialog, setOpenModalDialog] = useState(false);

    const [showImageModal, setShowImageModal] = useState(false);
    const [fileImageModal, setFileImageModal] = useState(null);
    const [arquivoImagem, setArquivoImagem] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [idUrldArquivoTemporario, setIdUrldArquivoTemporario] = useState(0);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [qtdImages, setQtdImages] = useState(0);
    const [arrayFilesQualidade, setArrayFilesQualidade] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            await carregarInformacoesDaTela();
            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            console.log('');
        }
    }, []);

    async function carregarInformacoesDaTela() {
        try {
            const ano = moment().format('YYYY');
            const numMes = moment().endOf('month').format("MM");
            setAnoCorrente(ano);
            setNumMesP(numMes);

            const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
            setSangriaAtiva(sangriaAti == 'true' ? true : false);

            const Param2 = localStorage.getItem('@HE-objUrlImagensAndVideosTelaGaleriaEsporadico');
            const dadosSeringueiro = Param2 != null ? JSON.parse(Param2) : '';
            setnomeSeringueiro(dadosSeringueiro.nome);

            const arrImg = localStorage.getItem('@HE-q-s-array-imagens-esporadico');
            const zzQ = arrImg != null ? JSON.parse(arrImg) : [];
            setQtdImages(zzQ.length);
            setArrayFilesQualidade(zzQ);

        } catch (error) {
            console.warn('Error na classe GalleryImageAndVideoEsporadico metodo carregarInformacoesDaTela ', error);
        }
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileSizeInMB = await file.size / (1024 * 1024); // CONVERT FILE SIZE TO MB, SE O RESULTADO DA CONTA FOR 1 E IGUAL A 1MB, 3 E IGUAL A 3MB...

            if (fileSizeInMB > 5) {
                setNumStatusResposta(ActionTypes.ARQUIVO_MUITO_GRANDE);
                showAlert();
            } else {
                const verifyExist = arrayFilesQualidade.filter(x => x.nomeFile == file.name);     // VERIFICAR SE O ARQUIVO JA EXISTE NAS IMAGENS SELECIONADAS

                if (verifyExist.length > 0) {
                    setNumStatusResposta(ActionTypes.ARQUIVO_JA_EXISTE);
                    showAlert();
                } else {
                    const imageUrl = URL.createObjectURL(file);
                    setArquivoImagem(file);
                    setFileImageModal(imageUrl);
                    setShowImageModal(true);
                }
            }
        } else {
            console.log('Arquivo nao existe');
        }
    }

    const anexarImageProducao = async () => {
        try {
            const dataAtual = moment().format('YYYY-MM-DD');
            setShowImageModal(false);
            const file = arquivoImagem;
            if (file) {
                const nomeFile = file.name;
                const jsonObj = {
                    idTipoAnexo: ActionTypes.ID_TIPO_ANEXO_IMAGEM_TEMPORARIO,
                    img: arquivoImagem,
                }
                const response = await UPLOAD_FILE_TEMPORARIO(jsonObj);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const urlStorage = response.respostaRequisicao.data;

                    const readerProduca = new FileReader();
                    readerProduca.onloadend = () => {
                        const objFile = {
                            nomeFile: nomeFile,
                            dataUpload: dataAtual,
                            urlStorage: urlStorage,
                            idTipoAnexo: ActionTypes.ID_TIPO_ANEXO_IMAGEM
                        }
                        if (arrayFilesQualidade.length > 0) {
                            const newArray = [objFile];
                            const arr = [...arrayFilesQualidade, ...newArray];
                            setArrayFilesQualidade(arr);
                            setQtdImages(arr.length);
                            localStorage.setItem('@HE-q-s-array-imagens-esporadico', JSON.stringify(arr));
                        } else {
                            const newArray = [objFile];
                            setArrayFilesQualidade(newArray);
                            setQtdImages(1);
                            localStorage.setItem('@HE-q-s-array-imagens-esporadico', JSON.stringify(newArray));
                        }
                    };
                    readerProduca.readAsDataURL(file);
                }
            }
        } catch (error) {
            console.log('Erro na classe GalleryImageAndVideoEsporadico metodo anexarImageProducao')
        }
    };

    const deleteImagemProducao = () => {
        setOpenModalDialog(false)
        const array = arrayFilesQualidade.filter(x => x.nomeFile != idUrldArquivoTemporario.nomeFile);
        setArrayFilesQualidade(array);
        setQtdImages(array.length);
        localStorage.setItem('@HE-q-s-array-imagens-esporadico', JSON.stringify(array));
    }

    const closeModalImagem = () => {
        setShowImageModal(false);
        setFileImageModal(null);
        setArquivoImagem(null);
    }
    const handleClick = () => {
        inputRef.current.click();
    };
    const nextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === arrayFilesQualidade.length - 1 ? 0 : prevIndex + 1
        );
    };
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? arrayFilesQualidade.length - 1 : prevIndex - 1
        );
    };
    const handleImageClickCarrossel = (index) => {
        setCurrentIndex(index);
        onOpen();
    };
    const organizarExclusaoDeArquivo = (item) => {
        setIdUrldArquivoTemporario(item);
        setOpenModalDialog(true);
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 9000);
    }

    function renderTitulo() {
        return (
            <Box>
                <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%' align={'center'}>{'Qualidade de Sangria Esporádico'}</Text>
                <Flex px='25px' mb={'10px'} justify='start' align='center'>
                    <Text color={textColor} fontSize='16px' fontWeight='700' lineHeight='100%'>Seringueiro:</Text>
                    <Text ml={'2'} color={'orange.600'} fontSize='16px' fontWeight='700' lineHeight='100%'>{nomeSeringueiro}</Text>
                </Flex>
                <Flex px='25px' justify='start' align='center'>
                    <Text color={textColor} fontSize='16px' fontWeight='700' lineHeight='100%'>Mensal - </Text>
                    <Text ml={'2'} color={'orange.600'} fontSize='16px' fontWeight='700' lineHeight='100%'>{`${numMesP} de ${anoCorrente}`}</Text>
                </Flex>
            </Box>
        )
    }

    function renderUploadFile() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex px={'10px'} pb={'15px'} justify='space-between' align='center'>
                    <Box>
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{`imagens ${qtdImages}`}</Text>
                    </Box>
                    <Box>
                        <Input type="file" ref={inputRef} display="none" onChange={handleFileChange} accept="image/*" />
                        <Button isDisabled={!sangriaAtiva} variant="miniBrand" rightIcon={<IoAddCircleSharp fontSize={'20px'} />} onClick={handleClick}>Adicionar Imagem</Button>
                        <Text color={'gray.500'} fontSize='13px' fontWeight='700' ml={'10px'} lineHeight={'100%'} align={'center'}>{'Max 5 MB'}</Text>
                    </Box>
                </Flex>
                {arrayFilesQualidade.length > 0 ?
                    <Flex pl={'0px'} w={'100%'} justify='start' align='start' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Box textAlign="center">
                            <Wrap p={'5px'} spacing='15px' justify={'start'} align={'center'}>
                                {arrayFilesQualidade.map((image, index) => (
                                    <WrapItem
                                        key={index}
                                        boxSize="150px"
                                        flex="0 0 auto"
                                        borderRadius={'10px'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        borderWidth={currentIndex === index ? "0px" : "0px"}
                                        borderColor={'red'}>
                                        <Box position={'relative'}>
                                            <Box mt={'20px'} cursor={'pointer'} onClick={() => handleImageClickCarrossel(index)}>
                                                <Image
                                                    src={image.urlStorage}
                                                    alt={`Thumbnail ${index + 1}`}
                                                    boxSize={'110px'}
                                                    objectFit="cover"
                                                    borderRadius={'8px'}
                                                />
                                            </Box>
                                            <Box mt={'10px'}>
                                                <IconButton
                                                    isRound={true}
                                                    aria-label="Previous Image"
                                                    icon={<DeleteIcon />}
                                                    color={'black'}
                                                    fontSize={'15px'}
                                                    size='xs'
                                                    borderWidth={'1px'}
                                                    borderColor={'black'}
                                                    onClick={() => { organizarExclusaoDeArquivo(image) }}
                                                />
                                            </Box>
                                        </Box>
                                    </WrapItem>
                                ))}
                            </Wrap>
                            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                <ModalOverlay />
                                <ModalContent maxW="600px" position="relative" bg={'whiteAlpha.800'}>
                                    <ModalCloseButton borderWidth={'1px'} borderColor={'blackAlpha.700'} bg={'whiteAlpha.700'} />
                                    <IconButton
                                        aria-label="Previous Image"
                                        icon={<MdArrowBackIosNew />}
                                        position="absolute"
                                        left="0"
                                        top="50%"
                                        size="lg"
                                        bg={'gray.300'}
                                        onClick={prevImage}
                                        zIndex={1}
                                    />
                                    <ModalBody p={0}>
                                        <Box px={'10px'} pb={'10px'}>
                                            <Text my={'10px'} color={'black'} fontSize={'18px'} fontWeight={'bold'} align={'center'}>{'Imagem'}</Text>
                                            <Flex flexDirection={'column'} justify={'center'} align={'center'} bg={'gray.100'} borderWidth={'1px'} borderColor={'blackAlpha.400'} borderRadius={'10px'}>
                                                <Box px={'10px'} py={'10px'} maxH={'500px'}>
                                                    <Image
                                                        src={arrayFilesQualidade[currentIndex].urlStorage}
                                                        alt={`Image ${currentIndex + 1}`}
                                                        w="100%"
                                                        h={'400px'}
                                                        objectFit="contain"
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </ModalBody>
                                    <IconButton
                                        aria-label="Next Image"
                                        icon={<MdArrowForwardIos />}
                                        position="absolute"
                                        right="0"
                                        top="50%"
                                        size="lg"
                                        bg={'gray.300'}
                                        onClick={nextImage}
                                        zIndex={1}
                                    />
                                </ModalContent>
                            </Modal>
                        </Box>
                    </Flex>
                    :
                    <Flex pl={'10px'} minH={'100px'} w={'100%'} overflowX="auto" justify='center' align='center' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Text color={'gray.400'} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Sem Imagens'}</Text>
                    </Flex>
                }
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'10px'} py={'10px'} >
                {isVisibleAlert && renderAlert()}
                {renderTitulo()}
                <Box >
                    {renderUploadFile()}
                </Box>
            </Card>
            {renderButton()}
            {showImageModal && <ImageModal isOpen={showImageModal} onPressOk={() => { anexarImageProducao() }} onPressCancel={() => { closeModalImagem() }} image={fileImageModal} />}
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { deleteImagemProducao() }} onPressCancel={() => { setOpenModalDialog(false) }} titulo={'Excluir'} descricao={'Tem certeza que deseja excluir?'} />}
        </Box>
    );
}
