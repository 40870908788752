import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Flex,
    FormLabel,
    Grid,
    GridItem,
    SimpleGrid,
    useColorModeValue,
    Avatar,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import MiniStatisticsLeft from "components/card/MiniStatisticsLeft";
//import AcompanhamentosSangriasDiarias from "views/admin/default/components/AcompanhamentosSangriasDiarias";
//import AcompanhamentoProducao from "views/admin/default/components/AcompanhamentoProducao";
import AcompanhamentoSangriaCustom from "views/admin/default/components/AcompanhamentoSangriaCustom";
import AcompanhamentoProducaoCustom from "views/admin/default/components/AcompanhamentoProducaoCustom";
import IndicadorDeProducao from "views/admin/default/components/IndicadorDeProducao";
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { VALIDAR_AUTENTICACAO_USUARIO } from '../../../util/ValidarAutenticacao';
import { useHistory } from "react-router-dom";
import { UtilContext } from 'contexts/UtilContext';
import ArrowOrange from "assets/img/layout/arrow_orange.png";
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function MainDashboard() {

    const history = useHistory();

    const { isAuthenticator } = useContext(UtilContext);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [percentualSangrias, setPercentualSangrias] = useState('0');
    const [percentualIndiceEficiencia, setPercentualIndiceEficiencia] = useState('0');
    const [tarefasPendentes, setTarefasPendentes] = useState('0');
    const [diasUteis, setDiasUteis] = useState('0');
    const [chuvaFeriado, setChuvaFeriado] = useState('0');
    const [atrasadas, setAtrasadas] = useState('0');
    const [diasPossiveisPeriodo, setDiasPossiveisPeriodo] = useState('0');
    const [faltas, setFaltas] = useState('0');
    const [realizadas, setRealizadas] = useState('0');
    const [openSangria, setOpenSangria] = useState(false);
    const [openProducao, setOpenProducao] = useState(false);
    const [openIndicadores, setOpenIndicadores] = useState(false);
    const [objPermissoes, setObjPermissoes] = useState({});

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandedProd, setIsExpandedProd] = useState(false);
    const [isExpandeIndicadores, setIsExpandedIndicadores] = useState(false);
    const [corIndeiceDeEficiencia, setCorIndeiceDeEficiencia] = useState('green.500');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);

    const boxBg = useColorModeValue("white", "whiteAlpha.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");

    useEffect(() => {
        async function loaderScreen() {
            if (isAuthenticator) {
                window.scrollTo(0, 0);
                await VALIDAR_AUTENTICACAO_USUARIO();

                localStorage.removeItem('@HE-retornoTelaSeringueiroDisponivel');        // REMOVER ALGUNS STORAGE AQUI, E IMPORTANTE

                const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
                const obj = JSON.parse(val);
                setObjPermissoes(obj);

                const nomeUa = localStorage.getItem('@HE-nomeUa');
                setNomeFazenda(nomeUa);

                const idSafra = localStorage.getItem('@HE-idSafra');
                const idUa = localStorage.getItem('@HE-idUa');
                const dtI = localStorage.getItem('@HE-dtInicioSafraAsync');
                const dtInicioSafra = moment(dtI).format('YYYY-MM-DD HH:mm:ss');
                const dtAtual = moment().format('YYYY-MM-DD HH:mm:ss');

                const url = `dashboardWeb/dadosBasicosParaDashboardWeb?idUa=${idUa}&idSafra=${idSafra}&idTipoPessoa=3&dtProgramadoDe=${dtInicioSafra}&dtProgramadoAte=${dtAtual}`;
                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    const so = res.somaDosDadosGeraisDaSafra;

                    await validarVersao(res.numeroVersao);

                    const percetSangrias = so.percentualSangriasPossiveisRealizadas;
                    const percentualIndiceEf = so.percentualIndiceEficiencia;
                    const tarefasPendentesP = so.tarefasPendentes;
                    const diasUteisPeriodoP = so.diasUteisPeriodo;
                    const chuvaFeriadoP = so.chuvaFeriado;
                    const diasPossiveisPeriodoP = so.diasPossiveisPeriodo;
                    const atrasadasP = so.atrasadas;
                    const faltasP = so.faltas != null ? so.faltas : '0';
                    const realizadasP = so.realizadas != null ? so.realizadas : '0';

                    setPercentualSangrias(percetSangrias);
                    setPercentualIndiceEficiencia(percentualIndiceEf);
                    setTarefasPendentes(tarefasPendentesP);
                    setDiasUteis(diasUteisPeriodoP);
                    setChuvaFeriado(chuvaFeriadoP);
                    setDiasPossiveisPeriodo(diasPossiveisPeriodoP);
                    setAtrasadas(atrasadasP);
                    setFaltas(faltasP);
                    setRealizadas(realizadasP);

                    let corIn = '';
                    if (res.percentualIndiceEf > 94) {
                        corIn = 'green.600';
                    } else if (res.percentualIndiceEf > 89) {
                        corIn = 'orange.600';
                    } else {
                        corIn = 'red.600';
                    }
                    setCorIndeiceDeEficiencia(corIn);
                }

            } else {
                history.push("/auth/sign-in");
            }
        }
        loaderScreen();
    }, [])

    const validarVersao = async (vrs) => {
        try {
            const versao = localStorage.getItem('@HE-currentVersaoHeveaWeb');
            localStorage.setItem('@HE-currentVersaoHeveaWeb', `${vrs}`);
            if (versao != null) {
                const vs = Number(versao);

                if(Number(vrs) > vs){
                    setOpenModalConfimacao(true);
                }
            }
        } catch (error) {
            console.log('Erro na classe metodo validarVersao ', error);
        }
    }

    const atualizarAplicacao = () => {
        setOpenModalConfimacao(false);
        window.location.reload(true);
    }

    const objectSwap = () => {
        history.push("/admin/escolherUa");
    }

    const handleExpand = () => {
        setOpenSangria(true);
        setIsExpanded(true);
    };
    const handleCollapse = () => {
        setOpenSangria(false);
        setIsExpanded(false);
    };

    const handleExpandProd = () => {
        setOpenProducao(true);
        setIsExpandedProd(true);
    };
    const handleCollapseProd = () => {
        setOpenProducao(false);
        setIsExpandedProd(false);
    };

    const handleExpandIndicadores = () => {
        setOpenIndicadores(true);
        setIsExpandedIndicadores(true);
    };
    const handleCollapseIndicadores = () => {
        setOpenIndicadores(false);
        setIsExpandedIndicadores(false);
    };

    function renderProducao() {
        return (
            <Box>
                {openProducao &&
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }}>
                        {/* <AcompanhamentoProducao /> */}
                        <AcompanhamentoProducaoCustom />
                    </SimpleGrid>
                }
            </Box>
        )
    }
    function renderSangria() {
        return (
            <Box>
                {openSangria &&
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} >
                        {/* <AcompanhamentosSangriasDiarias /> */}
                        <AcompanhamentoSangriaCustom />
                    </SimpleGrid>
                }
            </Box>
        )
    }
    function renderIndicadores() {
        return (
            <Box>
                {openIndicadores &&
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }}>
                        <IndicadorDeProducao />
                    </SimpleGrid>
                }
            </Box>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "55px" }} >

            <Grid h='80px' templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} mb={'2px'} >
                <GridItem colSpan={3} alignSelf={'center'} >
                    <MiniStatisticsLeft
                        endContent={
                            <Flex me='-16px' mt='20px' w={'100%'} h={'50px'}>
                                <FormLabel htmlFor='balance'>
                                </FormLabel>
                            </Flex>
                        }
                        name='Propriedade'
                        value={nomeFazenda}
                    />
                </GridItem>
                <GridItem alignSelf={'center'} >
                    <MiniStatisticsLeft
                        endContent={
                            <Flex me='-16px' mt='10px'>
                                {objPermissoes.gerente ?
                                    <FormLabel htmlFor='balance' _hover={{ cursor: "pointer" }} >
                                        <Avatar src={ArrowOrange} bg={boxBg} resize={'initial'} onClick={() => { objectSwap() }} />
                                    </FormLabel>
                                    :
                                    <FormLabel htmlFor='balance' opacity={'0.2'}>
                                        <Avatar src={ArrowOrange} bg={boxBg} resize={'initial'} onClick={() => { }} />
                                    </FormLabel>
                                }
                            </Flex>
                        }
                        name='Trocar'
                        value='Propriedade'
                    />
                </GridItem>
            </Grid>

            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 9 }} gap='15px' mb='20px' mt={'20px'}>
                <MiniStatistics name='Dias Úteis' value={diasUteis} />
                <MiniStatistics name='Tarefas Possíveis de Sangria' value={diasPossiveisPeriodo} />
                <MiniStatistics textColorValue={'green.500'} name='Total de Tarefas Realizadas' value={realizadas} />
                <MiniStatistics name='Sangrias Afetadas por Chuvas' value={chuvaFeriado} />
                <MiniStatistics name='Faltas em Sangrias' value={faltas} />
                <MiniStatistics textColorValue={'red.600'} name='Atrasadas' value={atrasadas} />
                <MiniStatistics name='Possíveis Realizadas' value={`${percentualSangrias}%`} />
                <MiniStatistics textColorValue={corIndeiceDeEficiencia} name='Índice de Eficiência' value={`${percentualIndiceEficiencia}%`} />
                <MiniStatistics textColorValue={'orange.500'} name='Tarefas Pendentes' value={tarefasPendentes} />
            </SimpleGrid>

            <Accordion allowMultiple mt={'40px'} borderColor={'transparent'} >
                <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapse : handleExpand}  >
                                <Box as="span" flex='1' textAlign='left'>
                                    <Text ml={'5px'} color={textColor} fontSize={'20px'} fontWeight={'bold'}>Sangrias</Text>
                                </Box>
                                <AccordionIcon boxSize={'10'} />
                            </AccordionButton>

                            <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                {renderSangria()}
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
                <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseProd : handleExpandProd}>
                                <Box as="span" flex='1' textAlign='left'>
                                    <Text ml={'5px'} color={textColor} fontSize={'20px'} fontWeight={'bold'}>Produção</Text>
                                </Box>
                                <AccordionIcon boxSize={'10'} />
                            </AccordionButton>

                            <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                {renderProducao()}
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
                <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseIndicadores : handleExpandIndicadores}>
                                <Box as="span" flex='1' textAlign='left'>
                                    <Text ml={'5px'} color={textColor} fontSize={'20px'} fontWeight={'bold'}>Indicador de Produção</Text>
                                </Box>
                                <AccordionIcon boxSize={'10'} />
                            </AccordionButton>

                            <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                {renderIndicadores()}
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { atualizarAplicacao() }} titulo={'OBS'} descricao={'Uma nova versão está disponível. Clique para atualizar'} />
        </Box>
    );
}
