import React, { useState, useEffect } from 'react';
import {
    Box, Flex,
    SimpleGrid,
    useColorModeValue,
    Wrap,
    WrapItem,
    Input,
    Text,
    Button,
    Center,
    Icon,
    Stat,
    StatLabel,
    StatNumber,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { IoArrowUpCircleSharp, IoCloseCircle } from "react-icons/io5";

export default function IndicadorSangria() {

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [corIndeiceDeEficiencia, setCorIndeiceDeEficiencia] = useState('green.500');

    const [minimumDate, setMinimumDate] = useState('');
    const [maximumDate, setMaximumDate] = useState('');
    const [borderSelecionado, setborderSelecionado] = useState(0);
    const [listaIndicadores, setListaIndicadores] = useState([]);
    const [GEcorIndeiceDeEficiencia, setGECorIndeiceDeEficiencia] = useState("green.500");
    const [GEpossiveisRealizadas, setGEPossiveisRealizadas] = useState(0);
    const [GEindiceEficiencia, setGEIndiceEficiencia] = useState(0);
    const [GEtarefasPendetes, setGETarefasPendetes] = useState(0);
    const [GEdiasUteis, setGEDiasUteis] = useState(0);
    const [GEchuvasFeriados, setGEChuvasFeriados] = useState(0);
    const [GEdiasPssiveisNoPeriodo, setGEDiasPssiveisNoPeriodo] = useState(0);
    const [GEAtrasadas, setGEAtrasadas] = useState(0);
    const [GERealizadasMenosTotalDeDias, setGERealizadasMenosTotalDeDias] = useState(0);
    const [GEFaltas, setGEFaltas] = useState(0);
    const [GERealizadas, setGERealizadas] = useState(0);

    const [possiveisRealizadas, setPossiveisRealizadas] = useState(0);
    const [indiceEficiencia, setIndiceEficiencia] = useState(0);
    const [diasUteis, setDiasUteis] = useState(0);
    const [chuvasFeriados, setChuvasFeriados] = useState(0);
    const [diasPssiveisNoPeriodo, setDiasPssiveisNoPeriodo] = useState(0);
    const [tarefasPendetes, setTarefasPendetes] = useState(0);
    const [atrasadas, setAtrasadas] = useState(0);
    const [realizadasMenosTotalDeDias, setRealizadasMenosTotalDeDias] = useState(0);
    const [faltas, setFaltas] = useState(0);
    const [realizadas, setRealizadas] = useState(0);
    const [idUsuarioSelecionado, setIdUsuarioSelecionado] = useState(0);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const inputBg = useColorModeValue("white", "navy.700");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const colorRealizada = useColorModeValue("green.500", "green.500");
    const colorPendente = useColorModeValue("orange.500", "orange.500");
    const colorMaoRelizada = useColorModeValue("red.500", "red.500");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');
    const bg = useColorModeValue("white", "navy.700");
    const borderColorP = useColorModeValue("orange.600", "orange.600");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    const gradientGreen = 'linear-gradient(green.300, green.400, green.500)';
    const gradientYellow = 'linear-gradient(yellow.300, yellow.400, yellow.500)';
    const gradientred = 'linear-gradient(red.300, red.400, red.500)';
    const gradientWhite = 'linear-gradient(gray.400, gray.300, gray.200)';
    const gradientDiaNaoUtil = 'linear-gradient(#fbefcc, #f7e1a1, #f3d372)';

    useEffect(() => {
        async function loaderScreen() {

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');

            setDataInicio(ini);
            setDataFim(fim);
            setMinimumDate(ini);
            setMaximumDate(fim);

            await pesquisar(ini, fim);
        }
        loaderScreen();
    }, [])

    const pesquisar = async (dtInicioP, dtFimP) => {
        try {
            setTelaCarregada(false);

            const dtI = moment(dtInicioP).format('YYYY-MM-DD HH:mm:ss');
            const dtF = moment(dtFimP).format('YYYY-MM-DD HH:mm:ss');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            const idTipoPessoa = ActionTypes.ID_TIPO_PESSOA_SANGRADOR;
            const url = `relatorioSangria/indicadorSangria?idTipoPessoa=${idTipoPessoa}&idUa=${ua}&idSafra=${idSafra}&dtInicioPesquisa=${dtI}&dtFimPesquisa=${dtF}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data[0];

                if (response.respostaRequisicao.data.length > 0) {
                    const possiveisRealizadasPP = res.percentualSangriasPossiveisRealizadas;

                    let corIn = '';
                    if (res.percentualIndiceEficiencia > 94) {
                        corIn = colorRealizada;
                    } else if (res.percentualIndiceEficiencia > 89) {
                        corIn = colorPendente;
                    } else {
                        corIn = colorMaoRelizada;
                    }
                    setCorIndeiceDeEficiencia(corIn);

                    setGEPossiveisRealizadas(possiveisRealizadasPP);
                    setGEIndiceEficiencia(res.percentualIndiceEficiencia);
                    setGETarefasPendetes(res.tarefasPendentes);
                    setGEDiasUteis(res.diasUteisPeriodo);
                    setGEChuvasFeriados(res.chuvaFeriado);
                    setGEDiasPssiveisNoPeriodo(res.diasPossiveisPeriodo);
                    setGECorIndeiceDeEficiencia(corIn);
                    setGEAtrasadas(res.atrasadas);
                    setGERealizadasMenosTotalDeDias(res.realizadasMenosTotalDeDias);
                    setGEFaltas(res.faltas);
                    setGERealizadas(res.tarefasRealizadas);

                    setPossiveisRealizadas(possiveisRealizadasPP);
                    setIndiceEficiencia(res.percentualIndiceEficiencia);
                    setTarefasPendetes(res.tarefasPendentes);
                    setDiasUteis(res.diasUteisPeriodo);
                    setChuvasFeriados(res.chuvaFeriado);
                    setDiasPssiveisNoPeriodo(res.diasPossiveisPeriodo);
                    setAtrasadas(res.atrasadas);
                    setRealizadasMenosTotalDeDias(res.realizadasMenosTotalDeDias);
                    setFaltas(res.faltas);
                    setRealizadas(res.tarefasRealizadas);

                    if (res.listIndicadorPorSeringueiro.length > 0) {

                        const arr = await adicionandoCampoNoArray(res.listIndicadorPorSeringueiro);
                        setListaIndicadores(arr);
                    }
                }
                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
            }
        } catch (error) {
            setTelaCarregada(true);
            console.log('Erro na classe IndicadorSangria metodo pesquisar', error);
        }
    }

    async function adicionandoCampoNoArray(array) {
        let ss = null;
        await new Promise(resolve => {
            resolve(
                ss = array.map(x => {

                    const per = x.percentualSangriasPossiveisRealizadas;
                    const ind = x.percentualIndiceEficiencia;
                    let corPo = '';
                    let corIn = '';

                    if (per > 94) {
                        corPo = colorRealizada;
                    } else if (per > 89) {
                        corPo = colorPendente;
                    } else {
                        corPo = colorMaoRelizada;
                    }

                    if (ind > 94) {
                        corIn = colorRealizada;
                    } else if (ind > 89) {
                        corIn = colorPendente;
                    } else {
                        corIn = colorMaoRelizada;
                    }

                    x['corIndeiceDeEficiencia'] = corIn;
                    x['corPossiveisRealizadas'] = corPo;
                    return x;
                })
            )
        });
        return ss;
    }

    const checkUsuario = async (item) => {
        try {
            if (item.idPessoa == idUsuarioSelecionado) {
                setborderSelecionado(0);
                setIdUsuarioSelecionado(0);

                setPossiveisRealizadas(GEpossiveisRealizadas);
                setIndiceEficiencia(GEindiceEficiencia);
                setTarefasPendetes(GEtarefasPendetes);
                setDiasUteis(GEdiasUteis);
                setChuvasFeriados(GEchuvasFeriados);
                setDiasPssiveisNoPeriodo(GEdiasPssiveisNoPeriodo);
                setAtrasadas(GEAtrasadas);
                setRealizadasMenosTotalDeDias(GERealizadasMenosTotalDeDias);
                setFaltas(GEFaltas);
                setRealizadas(GERealizadas);
                setCorIndeiceDeEficiencia(GEcorIndeiceDeEficiencia);

            } else {
                setborderSelecionado(2);
                setIdUsuarioSelecionado(item.idPessoa);

                setPossiveisRealizadas(item.percentualSangriasPossiveisRealizadas);
                setIndiceEficiencia(item.percentualIndiceEficiencia);
                setTarefasPendetes(item.tarefasPendentes);
                setDiasUteis(item.diasUteisPeriodo);
                setChuvasFeriados(item.chuvaFeriado);
                setDiasPssiveisNoPeriodo(item.diasPossiveisPeriodo);
                setCorIndeiceDeEficiencia(item.corIndeiceDeEficiencia);
                setAtrasadas(item.atrasadas);
                setRealizadasMenosTotalDeDias(item.realizadasMenosTotalDeDias);
                setFaltas(item.faltas);
                setRealizadas(item.realizadas);
            }
        } catch (error) {
            console.log('Erro na classe IndicadorSangria metodo checkUsuario', error);
        }
    }

    const changeInicioSangria = (e) => {
        const val = e.target.value;
        setDataInicio(val);
    }

    const changeFimSangria = (e) => {
        const val = e.target.value;
        setDataFim(val);
    }

    function renderDatas() {
        return (
            <Wrap spacing='10px' mb={'5px'} justify='flex-start' p={'1'} mx={'10px'}>
                <WrapItem >
                    <Box w='100%'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'}>Início</Text>
                        <Input
                            value={dataInicio}
                            variant='filled'
                            onChange={changeInicioSangria}
                            placeholder=""
                            bg={inputBg}
                            color={inputText}
                            borderColor={textColor}
                            borderRadius="10px"
                            size="md"
                            type="date"
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            onKeyDown={(e) => { e.preventDefault() }}
                            max={maximumDate}
                            min={minimumDate}
                        />
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' >
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'}>Fim</Text>
                        <Input
                            value={dataFim}
                            variant='filled'
                            onChange={changeFimSangria}
                            placeholder=""
                            bg={inputBg}
                            color={inputText}
                            borderColor={textColor}
                            borderRadius="10px"
                            size="md"
                            type="date"
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            onKeyDown={(e) => { e.preventDefault() }}
                            max={maximumDate}
                            min={minimumDate}
                        />
                    </Box>
                </WrapItem>
                <WrapItem >
                    <Box w='100%' h={'100%'}>
                        <Center alignItems={'flex-end'} h={'100%'}>
                            <Button variant="brand" onClick={() => { pesquisar(dataInicio, dataFim) }}>Pesquisar</Button>
                        </Center>
                    </Box>
                </WrapItem>
            </Wrap>
        )
    }

    function renderCards() {
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 8 }} gap='20px' mb='20px' mt={'10px'}>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Dias úteis'}</StatLabel>
                            <StatNumber color={textColor} fontSize={{ base: "xl", }}>{diasUteis}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Tarefas possíveis de sangria'}</StatLabel>
                            <StatNumber color={textColor} fontSize={{ base: "xl", }}>{diasPssiveisNoPeriodo}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Total de tarefas realizadas'}</StatLabel>
                            <StatNumber color={'green'} fontSize={{ base: "xl", }}>{realizadas}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Possíveis realizadas'}</StatLabel>
                            <StatNumber color={textColor} fontSize={{ base: "xl", }}>{`${possiveisRealizadas}%`}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Atrasadas no período'}</StatLabel>
                            <StatNumber color={'red'} fontSize={{ base: "xl", }}>{realizadasMenosTotalDeDias}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Tarefas Afetadas por Chuva'}</StatLabel>
                            <StatNumber color={textColor} fontSize={{ base: "xl", }}>{chuvasFeriados}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Tarefas Afetadas por Faltas'}</StatLabel>
                            <StatNumber color={'red'} fontSize={{ base: "xl", }}>{faltas}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg} borderWidth={borderSelecionado} borderColor={borderColorP}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel h={'30px'} lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Índice de eficiência'}</StatLabel>
                            <StatNumber color={corIndeiceDeEficiencia} fontSize={{ base: "xl", }}>{`${indiceEficiencia}%`}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
            </SimpleGrid>
        )
    }

    function renderSeringueiros() {
        return (
            <Card direction='column' w='100%' px='10px' pb='10px' bg={'rgba(242, 242, 242, 0.9)'} borderWidth={'3px'} borderRadius={'10px'} borderColor={'blackAlpha.300'}>
                {listaIndicadores.map((item, index) => {

                    let isDiaUtil = false;
                    let corQtdDiasTarefaAtrasada = 'transparent';
                    let descQtdDiasTarefaAtrasada = '';

                    if (item.frequenciaRealizadaDto != null) {
                        const obj = item.frequenciaRealizadaDto;
                        isDiaUtil = obj.diaUtil == true ? true : false;
                        descQtdDiasTarefaAtrasada = obj.descQtdDiasTarefaAtrasada;

                        if (!isDiaUtil || obj.dtDeletado != null) {
                            corQtdDiasTarefaAtrasada = gradientDiaNaoUtil;
                            descQtdDiasTarefaAtrasada = 'Sem program.';
                        } else if (obj.idQtdDiasTarefaAtrasada == 1) {
                            corQtdDiasTarefaAtrasada = gradientGreen;
                        } else if (obj.idQtdDiasTarefaAtrasada == 2) {
                            corQtdDiasTarefaAtrasada = gradientYellow;
                        } else if (obj.idQtdDiasTarefaAtrasada == 3) {
                            corQtdDiasTarefaAtrasada = gradientred;
                        } else if (obj.idQtdDiasTarefaAtrasada == 0) {
                            corQtdDiasTarefaAtrasada = gradientWhite;
                        }
                    }

                    const deletado = item.deletado != null ? item.deletado : false;
                    const dtDeletado = item.dtDeletado != null ? moment(item.dtDeletado).format('DD/MM/YYYY') : '';
                    const dtInicioProgramacao = item.dtInicioProgramacao != null ? moment(item.dtInicioProgramacao).format('DD/MM/YYYY') : '';
                    const margTop = deletado ? '0px' : '0px';
                    let opacityLevel = 1;
                    if (idUsuarioSelecionado == 0) {
                        opacityLevel = 1;
                    } else if (idUsuarioSelecionado != 0) {
                        if (item.idPessoa == idUsuarioSelecionado) {
                            opacityLevel = 1;
                        } else {
                            opacityLevel = 0.5;
                        }
                    }
                    return (
                        <Box
                            key={index}
                            position={'relative'}
                            borderRadius='10px'
                            p={'1px'}
                            bg={'white'}
                            mt={'10px'}
                            boxShadow={cardShadow}
                            opacity={opacityLevel}
                            borderWidth={item.idPessoa == idUsuarioSelecionado ? borderSelecionado : 0}
                            borderColor={borderColorP}>
                            <Box borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'} bg={'gray.600'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'}>
                                <Flex px={'20px'} py={'3px'} justify='space-between' align='center'>
                                    <Flex px={'0px'} py={'0px'} justify='start' align='center'>
                                        <Text color={'white'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Iníciou a programação em:</Text>
                                        <Text color={'gray.300'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} ml={'10px'}>{dtInicioProgramacao}</Text>
                                    </Flex>
                                    <Box >
                                        {deletado &&
                                            <Flex px={'8px'} py={'3px'} justify='start' align='center' bg={'red.600'} borderRadius={'10px'}>
                                                <Text color={'white'} fontSize='13px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Desativado</Text>
                                                <Text color={'white'} fontSize='13px' fontWeight={'bold'} lineHeight='100%' align={'center'} ml={'10px'}>{dtDeletado}</Text>
                                            </Flex>
                                        }
                                    </Box>
                                </Flex>
                            </Box>
                            <Flex px='0px' justify='space-between' py={'1px'} align='center' mt={margTop}>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'10px'}>Nome</Text>
                                    <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.seringueiro}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'10px'}>Realizadas</Text>
                                    <Text color={colorRealizada} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.realizadas}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'10px'}>Faltas</Text>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.faltas}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'10px'}>Atrasadas no período</Text>
                                    <Text color={colorMaoRelizada} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.realizadasMenosTotalDeDias}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'0px'}>Frequência</Text>
                                    <Box px={'10px'} py={'4px'} minW={'100px'} mt={'10px'} bgGradient={corQtdDiasTarefaAtrasada} borderRadius={'5px'}>
                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} align={'center'} fontFamily={'initial'}>{descQtdDiasTarefaAtrasada}</Text>
                                    </Box>
                                </Box>
                                <Box w={'100%'}>
                                    <Center alignItems={'center'} h={'100%'}>
                                        <Button h="70px" bg="transparent" _hover={{ cursor: "pointer" }} onClick={() => { checkUsuario(item) }}>
                                            <Icon as={item.idPessoa == idUsuarioSelecionado ? IoCloseCircle : IoArrowUpCircleSharp} width='30px' height='30px' color='orange.600' />
                                        </Button>
                                    </Center>
                                </Box>
                            </Flex>
                        </Box>
                    )
                })}
            </Card>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' mt={'100px'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Card direction='column' w={'100%'} px='10px' py='1px' >
                    {renderDatas()}
                    <Box pt={{ base: "130px", md: "80px", xl: "0px" }} >
                        {renderCards()}
                    </Box>
                    {renderSeringueiros()}
                </Card>
            </Card>
        </Box>
    );
}