import axios from 'axios';
import * as ActionTypes from '../constants/ActionTypes';

//import { getVerificaConexaoInternet } from '../util/VerificaConexaoInternet';
//import { VALIDAR_AUTENTICACAO_USUARIO } from '../util/ValidarAutenticacao';


const TIME_OUT_BACK_END = 1000 * 35;   // tempo de espera do back ex: 1000 * 10 referente a 10 segundos  --- pesquisar por canceltoken

const getAccessToken = async () => {
    
    const xx0021 = localStorage.getItem('@HE-access_token');
    return xx0021;
};

const getTesteConnection = async () => {
    //const testConnection = await getVerificaConexaoInternet();
    //return testConnection;
    const ss = {
        isConnected: true
    }
    return ss;
};

/*
╔═══════════════════════════════════════════════════╗
    AUTETICACAO NO BACK-END
╚═══════════════════════════════════════════════════╝*/
export async function postAutenticacaoApp(endpoint) {
    const teste001Conection = await getTesteConnection();
    var res1 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'POST',
                url: `${ActionTypes.LINK_API_SPRING}/oauth/token`,
                timeout: TIME_OUT_BACK_END,
                headers: {
                    Authorization: 'Basic YW5ndWxhcjpAbmd1bEByMA==',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: endpoint
            }).then(response => {
                res1 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res1 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res1 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res1 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo postAutenticacaoApp ', error);
    }
    return res1;
}

export async function getRequest(endpoint) {
    try {
        const teste001Conection = await getTesteConnection();
        const token = await getAccessToken();
        var res10 = null;

        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'GET',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                timeout: TIME_OUT_BACK_END,
                headers: { Authorization: `Bearer ${token}` },
                data: null
            }).then(response => {
                res10 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res10 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res10 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res10 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo getRequest ', error);
    }
    return res10;
}


export async function getRequestBody(endpoint, json) {
    try {
        const teste001Conection = await getTesteConnection();
        const token = await getAccessToken();
        var res10 = null;

        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'GET',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                timeout: TIME_OUT_BACK_END,
                headers: { Authorization: `Bearer ${token}` },
                data: json
            }).then(response => {
                res10 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res10 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res10 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res10 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo getRequestBody ', error);
    }
    return res10;
}


export async function postRequest(endpoint, json) {
    const teste001Conection = await getTesteConnection();
    const token = await getAccessToken();
    var res11 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'POST',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                //timeout: TIME_OUT_BACK_END,
                headers: { Authorization: `Bearer ${token}` },
                data: json
            }).then(response => {
                res11 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res11 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res11 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res11 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo postRequest ', error);
    }
    return res11;
}


export async function putRequest(endpoint, json) {
    const teste001Conection = await getTesteConnection();
    const token = await getAccessToken();
    var res7 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'PUT',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                timeout: TIME_OUT_BACK_END,
                headers: { Authorization: `Bearer ${token}` },
                data: json
            }).then(response => {
                res7 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res7 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res7 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res7 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo putRequest ', error);
    }
    return res7;
}

export async function deleteRequest(endpoint, json) {
    const teste001Conection = await getTesteConnection();
    const token = await getAccessToken();
    var res11 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'DELETE',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                //timeout: TIME_OUT_BACK_END,
                headers: { Authorization: `Bearer ${token}` },
                data: json
            }).then(response => {
                res11 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res11 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res11 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res11 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo postRequest ', error);
    }
    return res11;
}


/*
╔═══════════════════════════════════════════════════╗
    REQUISICOES PERMITIDAS NO BACK-END
    OU SEJA, NAO PRECISA PASSAR O ACCESS-TOKEN
╚═══════════════════════════════════════════════════╝*/
export async function getAllSemAccessToken(endpoint) {
    const teste001Conection = await getTesteConnection();
    var res4 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'GET',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,                
                timeout: TIME_OUT_BACK_END,
                data: null
            }).then(response => {
                res4 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res4 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res4 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res4 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo getAllSemAccessToken ', error);
    }
    return res4;
}


export async function postRequestSemAccessToken(endpoint, json) {
    const teste001Conection = await getTesteConnection();
    var res2 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'POST',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,                
                timeout: TIME_OUT_BACK_END,
                data: json
            }).then(response => {
                res2 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res2 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res2 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res2 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo postRequestSemAccessToken ', error);
    }
    return res2;
}

//════════════════════════ UPDATE SEM AUTENTICAÇÃO═══════════════════════════════
export async function putRequestSemAccessToken(endpoint, json) {
    const teste001Conection = await getTesteConnection();
    var res3 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'PUT',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,                
                timeout: TIME_OUT_BACK_END,
                data: json
            }).then(response => {
                res3 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res3 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res3 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res3 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo putRequestSemAccessToken ', error);
    }
    return res3;
}

/*
╔═══════════════════════════════════════════════════╗
    UPLOAD DE ARQUIVOS PARA O BACK-END    
╚═══════════════════════════════════════════════════╝*/
export async function uploadImagem(endpoint, formData) {
    const teste001Conection = await getTesteConnection();   
    const token = await getAccessToken();
    var res6 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'POST',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                //timeout: TIME_OUT_BACK_END,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'cache-control': 'no-cache',
                    'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
                },
                data: formData,                
            }).then(response => {
                res6 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res6 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res6 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res6 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo uploadImagem ', error);
    }
    return res6;
}




export async function uploadImagemSemAccessToken(endpoint, formData) {
    const teste001Conection = await getTesteConnection();   
    const token = await getAccessToken();
    var res60 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'POST',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                timeout: TIME_OUT_BACK_END,
                headers: {
                    'cache-control': 'no-cache',
                    'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
                },
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    console.log(`Progresso: ${progress}%`);
                }
            }).then(response => {
                res60 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res60 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res60 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res60 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo uploadImagemSemAccessToken ', error);
    }
    return res60;
}


export async function uploadImagemOFFLINE(endpoint, formData, configP) {
    const teste001Conection = await getTesteConnection();   
    const token = await getAccessToken();
    var res6 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'POST',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                //timeout: TIME_OUT_BACK_END,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'cache-control': 'no-cache',
                    'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
                },
                data: formData,
                config: configP,
            }).then(response => {
                res6 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res6 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res6 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res6 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo uploadImagemOFFLINE ', error);
    }
    return res6;
}


export async function UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(endpoint, formData) {
    const teste001Conection = await getTesteConnection();   
    const token = await getAccessToken();
    var res60 = null;
    try {
        if (teste001Conection.isConnected === true) {
            await axios({
                method: 'POST',
                url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
                timeout: TIME_OUT_BACK_END,
                // headers: {
                //     'cache-control': 'no-cache',
                //     'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
                // },
                data: formData,
            }).then(response => {
                res60 = {
                    respostaRequisicao: response,
                    numeroStatusResposta: ActionTypes.SUCESSO_NA_REQUISICAO,
                }
            }).catch((err) => {
                if (err.response) {
                    res60 = {
                        respostaRequisicao: err.response,  // AQUI SE APLICA EM CASO DE ERRO NA REQUISICAO
                        numeroStatusResposta: ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS,
                    }
                } else {
                    res60 = {
                        respostaRequisicao: err,  // AQUI SE APLICA SE DER TIMEOUT NA RESPOSTA DO BACK
                        numeroStatusResposta: ActionTypes.TIME_OUT_BACK_END,
                    }
                }
            });
        } else {
            res60 = {
                respostaRequisicao: {},
                numeroStatusResposta: ActionTypes.SEM_CONEXAO_COM_INTERNET,
            }
        }
    }
    catch (error) {
        console.log('Erro na classe ApiSpring metodo UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM ', error);
    }
    return res60;
}


export async function postRequestDownloadExcel(endpoint, json, filename = 'Relatorio') {
    try {
        await axios({
            url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
            method: 'POST',
            responseType: 'blob',
            data: json
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
    } catch (error) {
        console.log('Erro na classe ApiSpring metodo postRequestDownloadExcel', error);
    }
}

export async function postRequestDownloadPdf(endpoint, json, filename = 'Relatorio') {
    try {
        await axios({
            url: `${ActionTypes.LINK_API_SPRING}/${endpoint}`,
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
            },
            data: json
        }).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${filename}.pdf`;
            link.click();    
        });
    } catch (error) {
        console.log('Erro na classe ApiSpring metodo postRequestDownloadPdf', error);
    }
}