import moment from 'moment';
import * as Api from '../store/ApiSpring';
import * as ActionTypes from '../constants/ActionTypes';

export async function SALVAR_RELATORIO_PDF_ESPORADICO_SERVIDOR(objParam) {
    let result = '0';
    try {
        const idSafra = localStorage.getItem('@HE-idSafra');
        const idUsuario = localStorage.getItem('@HE-idUsuarioLogado');
        const min = moment().format('mm');
        const sec = moment().format('ss');
        const nomeImagem = `evidencia-relatorio-personalizado-${idSafra}-${idUsuario}-${min}${sec}.pdf`;

        const file = new File([objParam.blob], nomeImagem, { type: 'application/pdf' });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('bucketName', 'hevea_bucket');
        formData.append('folderName', 'user_uploads');

        const response = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadDiversosTiposDeArquivo`, formData);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const responseUrl = await salvaUrlDaImagemRelatorioEsporadico(response, objParam);
            result = responseUrl.numeroStatusResposta;
        }
    } catch (error) {
        console.log("Erro na classe SalvarImagem metodo SALVAR_RELATORIO_PDF_SERVIDOR ", error);
    }
    return result;
}

async function salvaUrlDaImagemRelatorioEsporadico(resUpload, objParam) {
    let result = null;
    try {
        const urlImagemSemIp = resUpload.respostaRequisicao.data;;

        const jsonUrlImagem = {
            idUa: objParam.idUa,
            idSafra: objParam.idSafra,
            idUsuario: objParam.idUsuario,
            nomePessoa: objParam.nomePessoa,
            idTipoAnexo: objParam.idTipoAnexo,
            idAvaliacao: objParam.idAvaliacao,
            idQualidadeSangriaEsporadico: objParam.idQualidadeSangriaEsporadico,
            totalPontos: objParam.totalPontos,
            notaPessoa: objParam.notaPessoa,
            urlStorage: urlImagemSemIp,
            dhCriacao: null,
            dtExclusao: null,
            statusExclusao: false,
            tamanho: resUpload.size,
        }
        const response = await Api.postRequest(`api/urlArquivo/criarUrlArquivoRelatorio`, jsonUrlImagem);
        result = response;

    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo salvaUrlDaImagem ', error);
    }
    return result;
}




export async function SALVAR_RELATORIO_PDF_SERVIDOR(blob, idTipoAnexoP) {
    let result = '0';
    try {
        const numeroParaImagem = '9';
        const idSafra = localStorage.getItem('@HE-idSafra');
        const idUsuario = localStorage.getItem('@HE-idUsuarioLogado');
        const min = moment().format('mm');
        const sec = moment().format('ss');
        const nomeImagem = `evidencia-relatorio-personalizado-${idSafra}-${idUsuario}-${min}${sec}.pdf`;

        const file = new File([blob], nomeImagem, { type: 'application/pdf' });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('bucketName', 'hevea_bucket');
        formData.append('folderName', 'user_uploads');

        const response = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadDiversosTiposDeArquivo`, formData);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const responseUrl = await salvaUrlDaImagemRelatorio(idUsuario, response, numeroParaImagem, idTipoAnexoP);
            result = responseUrl.numeroStatusResposta;
        }
    } catch (error) {
        console.log("Erro na classe SalvarImagem metodo SALVAR_RELATORIO_PDF_SERVIDOR ", error);
    }
    return result;
}

async function salvaUrlDaImagemRelatorio(idUsuarioP, resUpload, numeroParaImagem, idTipoAnexo) {
    let result = null;
    try {
        const idSafraP = localStorage.getItem('@HE-idSafra');
        const urlImagemSemIp = resUpload.respostaRequisicao.data;;

        const jsonUrlImagem = {
            idUsuario: idUsuarioP,
            idQualidadeSangria: null,
            idTipoAnexo: idTipoAnexo,
            url: urlImagemSemIp,
            urlStorage: urlImagemSemIp,
            dhCriacao: null,
            dtExclusao: null,
            statusExclusao: false,
            size: resUpload.size,
            observacao: 'Relatorio Personalizado',
            numeroImage: numeroParaImagem,
            idSafra: idSafraP
        }
        const response = await Api.postRequest(`api/urlArquivo/criaUrlArquivoParaRelatorioPersonalizado`, jsonUrlImagem);
        result = response;

    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo salvaUrlDaImagem ', error);
    }
    return result;
}



/*
╔═══════════════════════════════════════════════════╗
    UPLOAD DE ARQUIVOS PARA GOOGLE STORAGE
╚═══════════════════════════════════════════════════╝*/
export async function UPLOAD_FILE_GOOGLE_STORAGE(fileP) {
    let result = '';
    try {
        const fileName = fileP.name;
        const extensao = fileName.substring(fileName.lastIndexOf('.') + 1);     // PEGANDO A EXTENSAO DO ARQUIVO

        const idSafra = localStorage.getItem('@HE-idSafra');
        const idUsuario = localStorage.getItem('@HE-idUsuarioLogado');
        const min = moment().format('mm');
        const sec = moment().format('ss');
        const nomeImagem = `evidencia-relatorio-personalizado-${idSafra}-${idUsuario}-${min}${sec}.${extensao}`;

        const arquivo = new File([fileP], nomeImagem, {
            type: fileP.type,
        });

        const formData = new FormData();
        formData.append('file', arquivo);
        formData.append('bucketName', 'hevea_bucket');
        formData.append('folderName', 'user_uploads');

        const response = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadsFile`, formData);
        result = response;

    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo UPLOAD_FILE_GOOGLE_STORAGE ', error);
    }
    return result;
};




export async function UPLOAD_FILE_EXTRATO_SERINGUEIRO(arrayFiles, idPessoa) {

    let arr = [];
    await Promise.all(
        arrayFiles.map(async (val) => {
            const response = await UPLOAD_FILE_AND_URL(val, idPessoa);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                arr.push(res);
            }
        })
    )
    return arr.length;
}

async function UPLOAD_FILE_AND_URL(img, idPessoa) {
    let result = null;
    try {
        const numeroParaImagem = ActionTypes.ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO;
        const tipoArquivo = 'image/png';
        const response = await UPLOAD_FILE(img);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const responseUrl = await SALVAR_URL(idPessoa, response, numeroParaImagem, tipoArquivo);
            result = responseUrl
        }
    } catch (error) {
        console.log('Erro na classe UploadFile metodo UPLOAD_FILE_AND_URL ', error);
    }
    return result;
}

export async function UPLOAD_FILE(img) {
    let result = null;
    try {
        const formData = new FormData()
        formData.append('file', img);
        formData.append('bucketName', 'hevea_bucket');
        formData.append('folderName', 'user_uploads');

        const response = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadsFile`, formData);
        result = response;

    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo UPLOAD_FILE ', error);
        return null;
    }
    return result;
}

export async function SALVAR_URL(idPessoaP, resUpload, numeroParaImagem, tipoArquivo) {
    let result = null;
    try {
        const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
        const idSafra = localStorage.getItem('@HE-idSafra');

        const observacaoP = await retornaObservacao(ActionTypes.ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO);
        const urlImagemSemIp = resUpload.respostaRequisicao.data;

        const jsonUrlImagem = {
            idUsuario: idUsuarioLogado,
            idPessoa: idPessoaP,
            idQualidadeSangria: null,
            idTipoAnexo: ActionTypes.ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO,
            url: urlImagemSemIp,
            urlStorage: urlImagemSemIp,
            dhCriacao: null,
            dtExclusao: null,
            statusExclusao: false,
            size: null,
            observacao: observacaoP,
            numeroImage: numeroParaImagem,
            idSafra: idSafra
        }

        const response = await Api.postRequest(`api/urlArquivo/criaUrlArquivoParaRelatorioPersonalizado`, jsonUrlImagem);
        result = response;

    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo SALVAR_URL ', error);
    }
    return result;
}





/*
╔═════════════════════════════════════════════════════════════════════════╗
    ====================================================================
    ================= UPLOAD VERSAO FINAL PARA TODOS ===================
    VVERSAOOOOOOOOOOOOOOOOOOO FINALLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL

    FORMATO DOS CAMPOS DO ARRAY DEVE SER ENVIADO PARA UPLOAD DE IMAGENS,
    OS DADOS VAO DEPENDER DO TIPO DE IMAGEM

    PARA QUALIDADE DE SANGRIA:
    
        idUsuario:              ID USUARIO
        idPessoa:               ID PESSOA
        idQualidadeSangria:     SE FOR IMAGEM DA QUALIDADE, SE NAO MANDA null
        idTipoAnexo:            TEM QUE PASSAR UM VALOR DO ActionTypes TIPO: ActionTypes.ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO ---- OBRIGATORIO
        numeroImage:            PODE SER null CASO NAO SEJA O CASO
        idSafra:                PODE SER null CASO NAO SEJA O CASO

        url:                    NAO PRECISA POR QUE SE MONTADO NO METODO NA SEGUENCIA
        urlStorage:             NAO PRECISA POR QUE SE MONTADO NO METODO NA SEGUENCIA
        dhCriacao:              SERA INFORMADO NO BACK
        dtExclusao:             NESSE MOMENTO DA CRIACAO TEM QUE SER null
        statusExclusao:         NESSE MOMENTO DA CRIACAO TEM QUE SER false,
        size:                   null,
        observacao:             NAO PRECISA POR QUE SE MONTADO NO METODO NA SEGUENCIA

╚═════════════════════════════════════════════════════════════════════════╝*/
export async function UPLOAD_FILE_VESAO_FINAL(arrayFiles) {

    let arr = [];
    await Promise.all(
        arrayFiles.map(async (obj) => {
            const response = await UPLOAD_FILE_AND_URL_VERSAO_FINAL(obj);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                arr.push(res);
            }
        })
    )
    return arr.length;
}

export async function UPLOAD_FILE_AND_URL_VERSAO_FINAL(obj) {
    let result = null;
    try {
        const response = await UPLOAD_FILE_VERSAO_FINAL(obj);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const responseUrl = await SALVAR_URL_VERSAO_FINAL(obj, response);
            result = responseUrl
        }
    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo UPLOAD_FILE_AND_URL_VERSAO_FINAL ', error);
    }
    return result;
}

export async function UPLOAD_FILE_VERSAO_FINAL(obj) {
    let result = null;
    try {
        const nomeImagem = await retornaNomeImagem(obj);                
        const fileP = obj.img;

        const arquivo = new File([fileP], nomeImagem, {
            type: fileP.type,
        });

        const formData = new FormData()
        formData.append('file', arquivo);
        formData.append('bucketName', 'hevea_bucket');
        formData.append('folderName', 'user_uploads');

        if (obj.idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_VIDEO) {
            result = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadsVideo`, formData);
        }else{
            result = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadsFile`, formData);
        }

    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo UPLOAD_FILE_VERSAO_FINAL ', error);
        return null;
    }
    return result;
}

export async function SALVAR_URL_VERSAO_FINAL(obj, resUpload) {
    let result = null;
    try {
        const urlStorage = resUpload.respostaRequisicao.data;
        const observacaoP = await retornaObservacao(obj.idTipoAnexo);

        const jsonUrlImagem = {
            idUsuario: obj.idUsuario,
            idPessoa: obj.idPessoa,
            idQualidadeSangria: obj.idQualidadeSangria,
            idTipoAnexo: obj.idTipoAnexo,
            url: urlStorage,
            urlStorage: urlStorage,
            dhCriacao: null,        // ESSE CARA EU PEGO NO BACK
            dtExclusao: null,
            statusExclusao: false,
            size: null,
            observacao: observacaoP,
            numeroImage: obj.numeroImage,
            idSafra: obj.idSafra
        }

        const response = await Api.postRequest(`api/urlArquivo/criarUrlArquivo`, jsonUrlImagem);
        result = response;
    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo SALVAR_URL_VERSAO_FINAL ', error);
    }
    return result;
}


async function retornaObservacao(idTipoAnexo) {
    let observa = '';

    if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_SISTEMA) {
        observa = 'Anexo tipo sangria';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_IMAGEM) {
        observa = 'Evidencia tipo Imagem';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_VIDEO) {
        observa = 'Evidencia tipo Video';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_TECNICO_SANGRIA) {
        observa = 'Relatorio Tecnico Sangria';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_QUALIDADE_SANGRIA) {
        observa = 'Relatorio Anexo Qualidade Sangria';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_PRODUCAO) {
        observa = 'Relatorio Anexo Producao';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_PERSONALIZADO_PDF) {
        observa = 'Relatorio Personalizado PDF';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO) {
        observa = 'Historico Historico Seringueiro';
    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_ESPORADICO_PDF) {
        observa = 'Relatorio Esporadico PDF';
    } else {
        observa = 'Tipo anexo não informado';
    }
    return observa;
}

async function retornaNomeImagem(obj) {
    let nmImage = '';

    const idTipoAnexo = obj.idTipoAnexo;
    const fileP = obj.img;
    const fileName = fileP.name;
    const extensaoArquivo = fileName.substring(fileName.lastIndexOf('.') + 1);     // PEGANDO A EXTENSAO DO ARQUIVO
    const idQualidadeSangria = obj.idQualidadeSangria != null && obj.idQualidadeSangria != '' ? obj.idQualidadeSangria : 0;
    const idPessoa = obj.idPessoa != null && obj.idPessoa != '' ? obj.idPessoa : 0;
    const numeroImage = obj.numeroImage != null && obj.numeroImage != '' ? obj.numeroImage : 0;

    if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_SISTEMA) {
        nmImage = 'sistema';

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_IMAGEM) {           //  ESSE CARA AQUI TEM QUE CORRIGIR POR QUE ESTA SENDO USADO NA QUALIDADE SANGRIA
        nmImage = `qualidade-sangria-${idQualidadeSangria}-${idPessoa}-${numeroImage}.${extensaoArquivo}`;

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_VIDEO) {
        nmImage = `video-${idQualidadeSangria}-${idPessoa}-${numeroImage}.${extensaoArquivo}`;

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_TECNICO_SANGRIA) {
        nmImage = 'tecnico-sangria';

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_QUALIDADE_SANGRIA) {
        nmImage = `qualidade-sangria-${idQualidadeSangria}-${idPessoa}-${numeroImage}.${extensaoArquivo}`;

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_PRODUCAO) {
        nmImage = 'producao';

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_PERSONALIZADO_PDF) {
        nmImage = 'personalizado-pdf';

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO) {
        nmImage = 'historico-seringueiro';

    } else if (idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_ESPORADICO_PDF) {
        nmImage = 'esporadico-pdf';
        
    } else {
        nmImage = 'nome-nao-informado';
    }
    return nmImage;
}














/*
╔═══════════════════════════════════════════════════╗
    OS ARQUIVOS SERAO ARMAZENADOS POR 10 DIAS
╚═══════════════════════════════════════════════════╝*/
export async function UPLOAD_FILE_TEMPORARIO(obj) {
    let result = null;
    try {
        const fileP = obj.img;
        const nomeImagem = fileP.name;

        const arquivo = new File([fileP], nomeImagem, {
            type: fileP.type,
        });

        const formData = new FormData()
        formData.append('file', arquivo);
        formData.append('bucketName', 'hevea_bucket_temporario');
        formData.append('folderName', 'temp');

        result = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadsFile`, formData);

    } catch (error) {
        console.log('Erro na classe SalvarImagem metodo UPLOAD_FILE_TEMPORARIO ', error);
        return null;
    }
    return result;
}
