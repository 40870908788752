import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Icon,
    Input,
    Checkbox,
    Wrap,
    WrapItem,
    Select,
    RadioGroup,
    Radio,
    Stack,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { RiEdit2Fill, RiArrowRightSLine, RiArrowLeftSLine, RiAccountCircleFill } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import iconExcel from "assets/img/layout/excel_icon_download.png";
import LoaderBlack from 'components/loading/LoaderBlack';
import moment from 'moment';
import { mascaraTelefone } from '../../../../util/Masck';
import { QTD_PAGE } from 'model/MockData';

export default function ListaTodosSeringueiros() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listaSeringueiros, setListaSeringueiros] = useState([]);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [checkEmProgramacao, setCheckEmProgramacao] = useState(false);
    const [checkDisponivel, setCheckDisponivel] = useState(false);
    const [checkListaTodos, setCheckListaTodos] = useState(true);
    const [checkListaTodosAdmin, setCheckListaTodosAdmin] = useState(false);
    const [isDisabledRadio, setIsDisabledRadio] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [objPermissoes, setObjPermissoes] = useState({});
    const [radioValue, setRadioValue] = useState('1');
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [idSafraP, setIdSafraP] = useState('');  // idUaP, idSafraP
    const [idUaP, setIdUaP] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');

    const textColorHeader = useColorModeValue("white", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientGray = 'linear-gradient(gray.700, gray.600, gray.500)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const nomeUa = localStorage.getItem('@HE-nomeUa');
            const idUaX = localStorage.getItem('@HE-idUa');
            const idSafraX = localStorage.getItem('@HE-idSafra');

            setIdUaP(idUaX);
            setIdSafraP(idSafraX);
            setNomeFazenda(nomeUa);

            const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
            const isAtiva = sangriaAtivaP == 'false' ? true : false;
            setSangriaAtiva(isAtiva);

            const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
            const obj = JSON.parse(val);
            setObjPermissoes(obj);

            await pesquisar(idUaX, idSafraX);

        }
        loaderScreen();
    }, [])

    const pesquisar = async (idUaPa, idSafraPa) => {
        try {
            setListaSeringueiros([]);
            setCurrentPage(1);

            const url = `api/pessoa/listaTodasPessoasDeumaUa?idUa=${idUaPa}&idSafra=${idSafraPa}&disponivel=false&nome=${impuNomeSeringueiro}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                await carregaLista(res);
                setTelaCarregada(true);
            }
            setTelaCarregada(true);
        } catch (error) {
            setTelaCarregada(true);
            console.log('');
        }
    };

    const totalPages = Math.ceil(listaSeringueiros.length / itemsPerPage);
    const paginatedData = listaSeringueiros.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    async function carregaLista(array) {
        if (array.length > 0) {
            let arrayPess = [];

            for (let i = 0; i < array.length; i++) {
                const item = array[i];

                const dtCadastro = item.dtCadastro != '' && item.dtCadastro != null ? moment(item.dtCadastro).format('DD/MM/YY') : '';
                const celular = item.celular != '' && item.celular != null ? await mascaraTelefone(item.celular) : '';

                const obj = {
                    ...item,
                    celular: celular,
                    dtCadastro: dtCadastro,
                }
                arrayPess.push(obj);
            }
            setListaSeringueiros(arrayPess);
        }
    }

    const novorSeringueiro = () => {
        const json = {
            idPessoa: null,
            idUa: idUaP,
            idSafra: idSafraP,
            nomeFazenda: nomeFazenda,
            operacao: ActionTypes.OPERACAO_INSERT,
        }
        localStorage.setItem('@HE-datosParaEdicaoSeringueiroCadastro', JSON.stringify(json));
        history.push("/admin/form-seringueiro-cadastro");
    }

    const editarSeringueiro = (item) => {
        const json = {
            idPessoa: item.idPessoa,
            idUa: item.idUa,
            idSafra: item.idSafra,
            nomeFazenda: nomeFazenda,
            operacao: ActionTypes.OPERACAO_UPDATE,
        }
        localStorage.setItem('@HE-datosParaEdicaoSeringueiroCadastro', JSON.stringify(json));
        history.push("/admin/form-seringueiro-cadastro");
    }

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    const changeCheckListaTodos = () => {
        const check = !checkListaTodosAdmin;
        setCheckListaTodosAdmin(check);
        if (check) {
            setIsDisabledRadio(true);

            setRadioValue('1');
            setCheckListaTodos(true);
            setCheckEmProgramacao(false);
            setCheckDisponivel(false);
        } else {
            setIsDisabledRadio(false);
        }
    }

    const changeRadio = (val) => {
        setRadioValue(val);

        if (val == '1') {
            setCheckListaTodos(true);
            setCheckEmProgramacao(false);
            setCheckDisponivel(false);
        } else if (val == '2') {
            setCheckListaTodos(false);
            setCheckEmProgramacao(true);
            setCheckDisponivel(false);
        } else {
            setCheckListaTodos(false);
            setCheckEmProgramacao(false);
            setCheckDisponivel(true);
        }
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await gerarExcel();
            setLoading(false);
        }, 1000);
    }

    const gerarExcel = async () => {
        const idSafra = localStorage.getItem('@HE-idSafra');
        const json = {
            nome: impuNomeSeringueiro,
            emProgramacao: checkEmProgramacao,
            disponivel: checkDisponivel,
            listaTodos: checkListaTodos,
            listaTodosAdmin: checkListaTodosAdmin,
            idSafra: idSafra,
        }
        const url = `api/pessoa/downloadRelatorioExcelSeringueiros`;
        await Api.postRequestDownloadExcel(url, json, 'Lista-Seringueiros');
    }

    function renderInfo() {
        return (
            <Box>
                <Box mx={'20px'} borderRadius={'20px'} mb={'20px'} bgGradient={gradientGray}>
                    <Flex px={'10px'} py={'10px'} justify={'center'} align="center">
                        <Box w={'100%'}>
                            <Text mb={'10px'} color={'white'} fontSize='22px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Seringueiros Cadastrados'}</Text>
                            <Text color={'gray.200'} fontSize='16px' fontWeight={'light'} align={'center'} lineHeight='100%'>{'Caso não encontre um nome específico na listagem, você pode cadastra-lo.'}</Text>
                            <Flex px={'10px'} py={'5px'} justify={'center'} align="center">
                                <Box px={'10px'} py={'5px'} borderRadius={'8px'} bg={'gray.300'}>
                                    <Text color={'black'} fontSize='16px' fontWeight={'light'} align={'center'} lineHeight='100%'>{'Se os seringueiros não estiverem na listagem, verifique se o sistema está logado na mesma propriedade.'}</Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderListaTodosPerfilAdmin() {
        return (
            <Box px={'30px'} mb={'10px'}>
                <Flex justify='space-between' mb='5px' align='center'>
                    <Flex justify='start' mb='5px' align='center'>
                        <Checkbox mr={'15px'} borderWidth={'1px'} borderColor={'blackAlpha.500'} size='lg' colorScheme='green' isChecked={checkListaTodosAdmin} onChange={() => { changeCheckListaTodos() }} />
                        <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' >{'Listar Todos os Seringueiros (ADMIN)'}</Text>
                    </Flex>
                    <Flex justify='end' mb='5px' align='center'>
                        <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={iconExcel} alt='Dan Abramov' onClick={() => { gerarRelatorioExcel() }} />
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderRadioButton() {
        return (
            <Box px={'30px'} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between" >
                <RadioGroup onChange={changeRadio} value={radioValue} defaultValue='3' isDisabled={isDisabledRadio}>
                    <Stack spacing={5} direction='row'>
                        <Radio
                            size='lg'
                            name='1'
                            value='1'
                            borderWidth={'2px'}
                            borderColor={'blue.300'}
                            _checked={{ bg: 'blue.300' }}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' >Todos</Text>
                        </Radio>
                        <Radio
                            size='lg'
                            name='1'
                            value='2'
                            borderWidth={'2px'}
                            borderColor={'green.300'}
                            _checked={{ bg: 'green.300' }}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>Com Programação</Text>
                        </Radio>
                        <Radio
                            size='lg'
                            name='1'
                            value='3'
                            borderWidth={'2px'}
                            borderColor={'orange.300'}
                            _checked={{ bg: 'orange.300' }}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>Disponível</Text>
                        </Radio>
                    </Stack>
                </RadioGroup>
            </Box>
        )
    }

    function renderFiltro() {
        return (
            <Box w={'100%'} mb={'20px'} >
                <Wrap spacing='30px' mx={'20px'} mt={'15px'} align={'center'} justify='space-between'>
                    <WrapItem>
                        <Box>
                            <Input
                                value={impuNomeSeringueiro}
                                onChange={(e) => setImpuNomeSeringueiro(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder="Pesquisar por nome"
                                _placeholder={{ opacity: 0.5, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'400px'}
                            />
                        </Box>
                        <Button w={'100px'} ml={'10px'} variant="miniBrand" onClick={() => { pesquisar(idUaP, idSafraP) }}>pesquisar</Button>

                    </WrapItem>
                    <WrapItem>
                        <Button w={'90px'} variant="miniBrand" onClick={() => { novorSeringueiro() }}>Novo</Button>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderSeringueiros() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        const espacoW = 0;
        return (
            <Box pb={'10px'}>
                <Flex px='25px' mb={'10px'} justify='start' align='center'>
                    <Text color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>Propriedade:</Text>
                    <Text ml={'2'} color={'orange.700'} fontSize='17px' fontWeight='700' lineHeight='100%'>{nomeFazenda}</Text>
                </Flex>
                <Flex px={'10px'} direction="column" align="center">
                    <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'}>
                        <Table variant="striped" colorScheme='blackAlpha' size="sm">
                            <Thead>
                                <Tr h={'35px'} bgGradient={gradientHeader}>
                                    <Th w={'40%'} sx={{ paddingX: espacoW }}>
                                        <Text pl={'10px'} color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'start'} textTransform={'none'}>{'Nome'}</Text>
                                    </Th>
                                    <Th w={'25%'} sx={{ paddingX: espacoW }}>
                                        <Text color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} textTransform={'none'}>{'Celular'}</Text>
                                    </Th>
                                    <Th w={'25%'} sx={{ paddingX: espacoW }}>
                                        <Text color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} textTransform={'none'}>{'Dt Cadastro'}</Text>
                                    </Th>
                                    <Th w={'10%'} sx={{ paddingX: espacoW }}>
                                        <Text color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{''}</Text>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {paginatedData.map((item, index) => {
                                    let textStatus = 'Sem programação';
                                    let colorStatus = 'orange.500';
                                    let colorIndicator = item.associadoEmSafraVigente == true ? 'green.500' : 'orange.500';
                                    const isExisteProgramacao = item.isExisteProgramacao == true ? true : false;
                                    if (isExisteProgramacao) {
                                        textStatus = 'Com programação';
                                        colorStatus = 'green.500';
                                    }
                                    return (
                                        <Tr key={index}>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex pl={'10px'} justify='start' mb='5px' align='center'>
                                                    <Icon as={RiAccountCircleFill} color={colorIndicator} w={'25px'} h={'25px'} mr={'10px'} />
                                                    <Text color={textColor} fontSize='15px' fontWeight={'normal'} lineHeight='100%'>{`${item.nome}`}</Text>
                                                    {/* <Text color={textColor} fontSize='15px' fontWeight={'normal'} lineHeight='100%'>{`${item.nome} - ${item.idPessoa}`}</Text> */}
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify={'center'} align='center'>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'normal'} lineHeight='100%'>{item.celular}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify={'center'} align='center'>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'normal'} lineHeight='100%'>{item.dtCadastro}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify={'center'} align='center'>
                                                    <Button
                                                        //disabled={sangriaAtiva}
                                                        rightIcon={<RiEdit2Fill />}
                                                        variant="action"
                                                        key={index}
                                                        onClick={() => { editarSeringueiro(item) }}>Editar</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                        <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                            <Flex justify='center' align='center'>
                                <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                                <Box ml={'10px'}>
                                    <Select
                                        value={itemsPerPage}
                                        placeholder=""
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={'gray.200'}
                                        fontSize={'13px'}
                                        fontWeight={'bold'}
                                        textColor={textColor}
                                        size="sm"
                                        h={'35px'}
                                        onChange={changePage}>
                                        {QTD_PAGE.map((item, index) => {
                                            return (
                                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                            )
                                        })}
                                    </Select>
                                </Box>
                            </Flex>
                            <Flex justify='center' align='center'>
                                <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaSeringueiros.length}`}</Text>
                                <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                            </Flex>
                        </Flex>
                    </TableContainer>
                </Flex>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card px='0px' py='0px'>
            <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
                {renderInfo()}
                {/* {objPermissoes.admin && renderListaTodosPerfilAdmin()} */}
                {/* {renderRadioButton()} */}
                {renderFiltro()}
                {renderSeringueiros()}
            </Box>
            <LoaderBlack isOpen={loading} />
        </Card>
    );
};
