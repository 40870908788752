import moment from 'moment';

/*
╔═══════════════════════════════════════════════════════════════════════════╗
   SOMA DO TOTAL DE PONTOS DAS ARVORES
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function calculaTotalDePontosArvoreSemDivisao(arr) {
    let totalDePontosP = 0;
    try {
        if (arr.length > 0) {
            arr.map(item => {
                if (item.totalPontos != null && item.totalPontos != '') {
                    totalDePontosP = totalDePontosP + Number(item.totalPontos);
                }
            });
        }
    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo calculaTotalDePontosArvoreSemDivisao', error);
    }
    return totalDePontosP;
}



export async function retornaNomeDoMesMaiusculo(nomeMes = '') {
    let result = '';
    try {
        switch (nomeMes) {
            case 'January':
                result = 'JANEIRO';
                break;
            case 'February':
                result = 'FEVEREIRO';
                break;
            case 'March':
                result = 'MARCO';
                break;
            case 'April':
                result = 'ABRIL';
                break;
            case 'May':
                result = 'MAIO';
                break;
            case 'June':
                result = 'JUNHO';
                break;
            case 'July':
                result = 'JULHO';
                break;
            case 'August':
                result = 'AGOSTO';
                break;
            case 'September':
                result = 'SETEMBRO';
                break;
            case 'October':
                result = 'OUTUBRO';
                break;
            case 'November':
                result = 'NOVEMBRO';
                break;
            case 'December':
                result = 'DEZEMBRO';
                break;
            default:
                break;
        }
    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo retornaNomeDoMesMaiusculo', error);
    }
    return result;
}

/*
╔═══════════════════════════════════════════════════════════════════════════╗
   RETORNA O MOME DO MES TRADUZIDO
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function retornaNomeDoMes(dataCorrente) {
    let result = '';
    try {
        const mes = moment(dataCorrente).format('YYYY-MM-DD');
        const mesCorrente = moment(mes).format('MMMM')
        switch (mesCorrente) {
            case 'January':
                result = 'Janeiro';
                break;
            case 'February':
                result = 'Fevereiro';
                break;
            case 'March':
                result = 'Março';
                break;
            case 'April':
                result = 'Abril';
                break;
            case 'May':
                result = 'Maio';
                break;
            case 'June':
                result = 'Junho';
                break;
            case 'July':
                result = 'Julho';
                break;
            case 'August':
                result = 'Agosto';
                break;
            case 'September':
                result = 'Setembro';
                break;
            case 'October':
                result = 'Outubro';
                break;
            case 'November':
                result = 'Novembro';
                break;
            case 'December':
                result = 'Dezembro';
                break;
            default:
                break;
        }

    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo retornaNomeDoMes', error);
    }

    return result;
}
export async function retornaNomeAbreviado(dataCorrente) {
    let result = '';
    try {
        const mes = moment(dataCorrente).format('YYYY-MM-DD');
        const mesCorrente = moment(mes).format('MMMM')
        switch (mesCorrente) {
            case 'January':
                result = 'JAN';
                break;
            case 'February':
                result = 'FEV';
                break;
            case 'March':
                result = 'MAR';
                break;
            case 'April':
                result = 'ABR';
                break;
            case 'May':
                result = 'MAI';
                break;
            case 'June':
                result = 'JUN';
                break;
            case 'July':
                result = 'JUL';
                break;
            case 'August':
                result = 'AGO';
                break;
            case 'September':
                result = 'SET';
                break;
            case 'October':
                result = 'OUT';
                break;
            case 'November':
                result = 'NOV';
                break;
            case 'December':
                result = 'DEZ';
                break;
            default:
                break;
        }

    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo retornaNomeDoMes', error);
    }

    return result;
}

export async function RETORNA_DATA_INICIO_FIM_PARA_CALENDARIO_ESTIMULACAO(dtInitSafra = '2023-09-01', dtFimSafra = '2024-08-31') {
    let result = {
        dtInit: '',
        dtFim: '',
    }
    try {
        let anoInicio = moment(dtInitSafra).format('YYYY');
        let anoFim = moment(dtFimSafra).format('YYYY');

        anoInicio = Number(anoInicio);
        anoFim = Number(anoFim);

        if (anoInicio == anoFim) anoFim = anoFim + 1;

        const rr0 = moment(`${anoInicio}-09-01`).format('YYYY-MM-DD');
        const rr1 = moment(rr0).startOf('week').add(1, 'day').format('YYYY-MM-DD');    // PRIMEIRO DIA DA SEMANA COMECANDO NA SEGUNDA
        const rr2 = moment(`${anoFim}-08-31`).endOf('week').format('YYYY-MM-DD');      // ULTIMO DIA DA SEMANA TERMINA NO SABADO

        const DT_INIT_MAIS_UM_ANO = moment(rr0).add(1, 'year').format('YYYY-MM-DD');
        const compare = moment(DT_INIT_MAIS_UM_ANO).isAfter(rr2);

        let resDataFim = rr2;
        if (!compare) resDataFim = moment(rr2).subtract(1, 'week').format('YYYY-MM-DD');

        result.dtInit = rr1;
        result.dtFim = resDataFim;

    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo NAVEGACAO_RELATORIO_ESPORADICO', error);
    }

    return result;
}


/*
╔═══════════════════════════════════════════════════════════════════════════╗
   SOMA DO TOTAL DE PONTOS DAS ARVORES
╚═══════════════════════════════════════════════════════════════════════════╝*/

const arrayArvo = [
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '1', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '2', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '3', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '4', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '5', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '6', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '7', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '8', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '9', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
    { idQualidadeSangria: null, idPessoa: null, nome: '', numeroArvore: '10', arvoreChecada: false, editavel: false, objArvore: null, totalPontos: '0' },
]

/*
╔═══════════════════════════════════════════════════════════════════════════╗
   CARREGANDO DADOS DAS ARVORES 
   OLHA SO, EU TENHO QUE TER 10 ARVORES NA TELA CERTO, MAS NO BANCO POSSO
   TER UMA OU 10, ENTAO COM ESSE ARRAY ACIMA FACO UM LOOP NOS 10 E SE 
   TIVER REGISTRO NO CONCATENO SE NAO FICA SO OS DADOS BASICOS DESSE 
   ARRAY 
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function carregarArvoresParaUmaSangria(res, dadosSeringueiro) {
    let result = 0;
    const idPes = dadosSeringueiro.idPessoa;
    const nomeSering = dadosSeringueiro.nome;
    const idQualidade = res.idQualidadeSangria;

    try {
        const arr = res.listArvore;
        if (arr.length > 0) {
            let temp = arrayArvo.map((item, index) => {
                let arvorePodeSerEditada = true;

                if (index > 0) {                          // A ARVORE SO PODE SER EDITADA SE A ANTERIOR ESTIVER CONCLUIDA
                    if (arr[index - 1] != undefined) {
                        arvorePodeSerEditada = true;
                    } else {
                        arvorePodeSerEditada = false;
                    }
                }

                if (arr[index] != undefined) {
                    const arvoreRequest = arr[index];
                    arvorePodeSerEditada = true
                    return {
                        idQualidadeSangria: idQualidade,
                        idPessoa: idPes,
                        nome: nomeSering,
                        arvoreChecada: arvoreRequest.arvoreChecada,
                        numeroArvore: item.numeroArvore,
                        editavel: arvorePodeSerEditada,
                        totalPontos: arvoreRequest.totalPontos,
                        objArvore: arr[index]
                    }
                }
                if (arr[index] == undefined) {
                    return {
                        idQualidadeSangria: idQualidade,
                        idPessoa: idPes,
                        nome: nomeSering,
                        arvoreChecada: false,
                        numeroArvore: item.numeroArvore,
                        editavel: arvorePodeSerEditada,
                        totalPontos: '0',
                        objArvore: null
                    }
                }
            })
            result = temp;
        } else {
            let temp = arrayArvo.map((item, index) => {
                let arvorePodeSerEditada = true;
                if (index == 0) {                          // A ARVORE SO PODE SER EDITADA SE A ANTERIOR ESTIVER CONCLUIDA
                    arvorePodeSerEditada = true;
                } else {
                    arvorePodeSerEditada = false;
                }
                return {
                    idQualidadeSangria: idQualidade,
                    idPessoa: idPes,
                    nome: nomeSering,
                    arvoreChecada: false,
                    numeroArvore: item.numeroArvore,
                    editavel: arvorePodeSerEditada,
                    objArvore: null
                }
            })
            result = temp;
        }

    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo calculaTotalDePontosArvore', error);
    }
    return result;
}

export async function LISTA_DE_DATAS_ENTRE_UM_PERIODO(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    const dates = [];

    while (start.isBefore(end) || start.isSame(end, 'month')) {
        dates.push(start.clone().startOf('month').format('YYYY-MM-DD'));
        start.add(1, 'month');
    }
    return dates;
};

export async function ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(dataInicio, dataFim) {
    const listaMeses = [];
    let dataAtual = moment(dataInicio);

    while (dataAtual.isSameOrBefore(dataFim, "month")) {

        const mes = await retornaNomeAbreviado(dataAtual);
        const ano = dataAtual.format("YYYY");
        const label = `${mes} ${ano}`;

        listaMeses.push({
            //label: dataAtual.format("MMM YYYY").toUpperCase(),
            label: label,
            value: dataAtual.startOf("month").format("YYYY-MM-DD"),
        });
        dataAtual.add(1, "month");
    }
    return listaMeses;
};

export async function truncarAndFormatarNomeSeringueiro(fileName, maxLength = 30) {
    let result = '';
    try {
        const nmTruncate = fileName.length > maxLength ? fileName.substring(0, maxLength) : fileName;
        const sanitized = nmTruncate.replace(/[^a-zA-Z0-9\s]/g, '');	// REMOVE CARACTERES ESPECIAIS E MANTEM LETRAS, NUMEROS E ESPACOS
        result = sanitized.replace(/\s+/g, '_');				        // SUBSTITUI ESPACOS POR "_"                  
    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo truncarAndFormatarNomeSeringueiro', error);
    }
    return result;
}

export async function logMarcadorDeTempoEmSegundos(dataHora1, dataHora2) {
    let result = '';
    try {
        const hora1 = moment(dataHora1);
        const hora2 = moment(dataHora2);

        const duration = moment.duration(hora2.diff(hora1));
        const resultado = duration.asSeconds();
        const tempo = parseFloat(resultado.toFixed(0));

        const inicio = moment(hora1).format('HH:mm:ss');
        const fim = moment(hora2).format('HH:mm:ss');

        console.log(`----------- INICIO  ${inicio}   FIM  ${fim}   SEGUNDOS ${tempo}`);
    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo truncarAndFormatarNomeSeringueiro', error);
    }
    return result;
}


/*
╔═══════════════════════════════════════════════════════════════════════════╗
   EXEMPLO PARA VERIFICAR SE UMA DATA E ANTE OU DEPOIS DA DATA ATUAL
╚═══════════════════════════════════════════════════════════════════════════╝*/
const validarDataTeste = () => {

    const data = "2024-11-25"; // Data a ser verificada
    const dataAtual = moment();

    if (moment(data).isBefore(dataAtual)) {
        console.log(`${data} está antes da data atual.`);
    } else if (moment(data).isAfter(dataAtual)) {
        console.log(`${data} está depois da data atual.`);
    } else {
        console.log(`${data} é a data atual.`);
    }

}

